import React from "react";
import styles from "./index.module.scss";

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <h1>Privacy Policy</h1>
      <p>
        Welcome to www.adhigyanam.com ("website"), operated by Sauntesthyam LLP. This Privacy Policy outlines how we safeguard the
        privacy of users of the website, including astrologers, buyers/customers (registered or not registered). Please carefully
        review this policy to understand how your supplied information is used by the website. This policy complies with Rule 3(1)
        of the Information Technology (Intermediaries Guidelines) Rules, 2011 and Information Technology (Reasonable Security
        Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, requiring the publication of a privacy
        policy for the collection, use, storage, and transfer of sensitive personal data or information.
      </p>
      <p>
        <b>User's Consent:</b> By accessing and using the website, you acknowledge understanding and consent to this Privacy
        Policy. The policy may be updated/amended over time. If you disagree with the terms, please refrain from using the
        website. Your continued use signifies unconditional consent and agreement to the terms outlined herein.
      </p>
      <p>
        <b>Collection of Personal Information:</b> Creating a user profile with Adhigyanam involves providing specific
        information. Mandatory details include your phone number for OTP verification. Additionally, you may provide your first
        name, last name, and date of birth (DOB) optionally. DOB is not mandatory for registration but aids in creating a
        personalised user profile.
      </p>
      <p>
        <b>Purpose and Use of Data/Information Collection:</b> The collected information helps Adhigyanam create personalized user
        profiles for tailored experiences. However, not providing DOB won't hinder registration or access to services.
      </p>
      <p>
        <b>Data Deletion:</b> If you wish to delete your Adhigyanam profile, including personal information, an option to "Delete
        your account" may be available. Follow the provided instructions to complete the account deletion process.
      </p>
      <p>
        <b>Voice Recording and Microphone Permission:</b> Recording voice interactions with the app's microphone helps streamline
        communication. Access to your device's microphone is requested to record and process your voice as audio.
      </p>
      <p>
        <b>Commitment:</b> The website protects user privacy. It guarantees not to directly or indirectly misuse user information
        revealed in predictions. The website does not engage in selling or renting user information. The website disclaims
        liability for users with weak mental health and reserves the right to share their information with law enforcement. The
        website does not guarantee predictions' accuracy or reliability of products sold.
      </p>
      <p>
        <b>Information Collected by Website:</b> Personal Identifiable Information: Information collected for account creation
        includes name, address, telephone number, email, DOB, gender, location, photograph, and other sensitive data as defined by
        relevant rules.
      </p>
      <p>
        <b>Non-Personal Identifiable Information:</b> Collected during website visits, includes URLs, IP addresses, browser type,
        and location data.
      </p>
      <p>
        <b>Security Measures:</b> The website employs security measures to protect user information. Users are advised to take
        precaution in sharing login details and the website is not responsible for internet communications' security.
      </p>
      <p>
        <b>Usage of Information:</b> The collected information is used to personalise browsing, improve content, analyse usage,
        and provide tailored experiences. Certain third-party analytics tools help measure traffic and usage trends.
      </p>
      <p>
        <b>Confidentiality:</b> Confidential information is excluded from personal information and is not shared except in
        specified circumstances.
      </p>
      <p>
        <b>Children Privacy Policy:</b> The website is not intended for users under 13. Users under 13 should not use the
        website's services. No personal identifiable information is knowingly collected from children under 13.
      </p>
      <p>
        <b>Safety and Security:</b> Adhigyanam.com ensures users' privacy and security through encryption and safe transaction
        methods.
      </p>
      <p>
        <b>Disclaimer:</b> The website is not responsible for communication between users and third-party websites. Users should
        review third-party privacy policies independently.
      </p>
      <p>
        <b>Grievance Officer:</b> A designated Grievance Officer can be contacted regarding any discrepancies, breaches,
        complaints, or concerns.
      </p>
      <p>
        <b>Contact Information:</b>
        <p>Grievance Officer: Shubham Srivastava</p>
        <p>Email: support@adhigyanam.com</p>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
