import React from "react";
import ConsultationList from "./ConsultationList";
import Styles from "./index.module.scss";

const CommanConsultationList = ({ lists, image, width, containerWidth, onClick, items, allselect, allChecked }) => {
  return (
    <div style={{ width: `${containerWidth}` }} className={Styles.consultaintItem}>
      <span onClick={allselect} className={Styles.consultaintItem__all}>
        All
        <input type="checkbox" checked={allChecked} />
      </span>
      <div>
        {lists.map((list, index) => {
          return (
            index <= 11 && (
              <ConsultationList
                checked={items?.includes(list.name)}
                key={list.id}
                id={list.id}
                lable={list.name}
                onClick={() => onClick(`${list.name}`)}
              />
            )
          );
        })}
      </div>
      <div>
        {lists.map(
          (list, index) =>
            index >= 12 && (
              <ConsultationList
                checked={items?.includes(list.name)}
                key={list.id}
                id={list.id}
                lable={list.name}
                onClick={() => onClick(`${list.name}`)}
              />
            )
        )}
      </div>

      <div className={Styles.consultaintItem__bigImg} style={{ width: `${width}` }}>
        <img src={image} alt="error" />
      </div>
    </div>
  );
};

export default CommanConsultationList;
