import React from "react";
import styles from "./index.module.scss";
import crosIcon from "../../assets/cross.webp";
import { FaLink } from "react-icons/fa6";

const AstroBlogModal = ({ detail, onClick }) => {
  return (
    <>
      <div className={styles.astrodetail}>Astrologer Details</div>
      <div className={styles.profileArea}>
        <div className={styles.profileArea__left}>
          <span className={styles.profileArea__left__close} onClick={onClick}>
            <img src={crosIcon} alt="error" />
          </span>
          <span className={styles.profileArea__left__profilePic}>
            <img src={detail.profile_pic} alt="error" />
          </span>
          <h2>{detail.name}</h2>
          {/* <p className={styles.profileArea__left__email}>{detail.email}</p> */}
        </div>
        <div className={styles.profileArea__right}>
          {/* <p className={styles.profileArea__right__experience}>
            Age : <span>{detail.age} Year</span>
          </p> */}
          <p className={styles.profileArea__right__experience}>
            Gender : <span> {detail.gender}</span>{" "}
          </p>
          <p className={styles.profileArea__right__experience}>
            Experience : <span>{detail.experience} Year</span>
          </p>
          <p className={styles.profileArea__right__experience}>
            Speciality : <span>{detail.speacility}</span>
          </p>
          {detail.external_link && (
            <p className={styles.profileArea__right__experience}>
              Social Link :
              <a href={detail.external_link} target="blank">
                <button>
                  Link <FaLink />
                </button>
              </a>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default AstroBlogModal;
