import React from "react";
import style from "./index.module.scss";
import clock from "../../assets/clock.svg";

const TimeRange = (props) => {
  return (
    <div className={style.timeSlot}>
      <div className={style.timeSlot__child}>
        <input type="time" placeholder="From" value={props.value1} onChange={props.startTime} />
        <img src={clock} alt="clock" />
      </div>
      <p>To</p>

      <div className={style.timeSlot__child}>
        <input type="time" placeholder="To" value={props.value2} onChange={props.endTime} />
        <img src={clock} alt="clock" />
      </div>
    </div>
  );
};

export default TimeRange;
