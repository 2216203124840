import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const Graph = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(ctx, {
      type: "line",
      data: {
        datasets: data.map((dataset) => ({
          label: "",
          data: dataset.points,
          borderColor: dataset.color,
          fill: dataset.color,
          pointRadius: 6,
          pointBackgroundColor: dataset.color,
          pointBorderColor: dataset.color,
          showLine: true,
          borderCapStyle: "round",
        })),
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: "category",
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          line: {
            tension: 1,
            cubicInterpolationMode: "monotone",
          },
        },
      },
    });
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default Graph;
