import React from "react";
import styles from "./index.module.scss";

const TermsAndConditions = () => {
  return (
    <div className={styles.terms}>
      <h1 className={styles.terms__title}>Terms & Conditions</h1>
      <p className={styles.terms__para}>
        These Terms of Use (referred to as the "Terms") outline and govern your usage of the content and services provided by SAUNTESHTHYAM
        LLP through the website www.adhigyanam.com (referred to as "We," "Adhigyanam," "us," "our," "Adhigyanam application," or "Website").
        Updates: The Website may periodically update, amend, or modify these Terms of Use. It is your responsibility to regularly review
        these Terms to ensure your compliance with them.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Updates:</b> The Website may periodically update, amend, or modify these Terms of Use. It
        is your responsibility to regularly review these Terms to ensure your compliance with them.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>User Consent:</b> By accessing and using the Website, you (referred to as "Member," "You,"
        or "Your") indicate your understanding and unconditional consent to these Terms of Use. If you disagree with these Terms, please
        refrain from clicking the "I AGREE" button. It is advisable to carefully read these Terms before using the Website, registering on
        it, or accessing any content, information, or services provided through it. Your ongoing use of the Website, regardless of any
        amendments, signifies your acceptance and legal binding agreement to these Terms of Use.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>General Description:</b> The Website operates as an internet-based portal across the World
        Wide Web, applications, and other electronic media. It offers astrological content, reports, data, telephone, video, and email
        consultations (collectively referred to as "Content"). The Website provides both "Free Services" and "Paid Services" (collectively
        referred to as "Services"). While Free Services are accessible without membership, personalised astrological services, additional
        Content, and access to Paid Services require member registration.
        <b className={styles.terms__para__title}>By registering for Paid Services, a Member agrees to:</b>
        <ul className={styles.terms__para__ul}>
          <li>Provide accurate and up-to-date information as prompted by the Website.</li>
          <li>Maintain and update provided information to ensure its accuracy.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Registration and Eligibility:</b>
        <ul className={styles.terms__para__ul}>
          <li>
            By using this website, you affirm that you are at least 18 years old and capable of entering into legally binding contracts
            under the Indian Contract Act, 1872. The Website is not liable for any misuse resulting from the use of its services by
            individuals, including minors. However, you are permitted to inquire about minors in your family in accordance with the terms
            outlined in this policy.
          </li>
          <li>
            To access the services, users are required to register as a Member on the Website. By doing so, you agree to provide accurate
            and current information during the registration process. The information you provide and any subsequent updates are collectively
            referred to as "Registration Data" within these Terms of Use.
          </li>
          <li>
            <b className={styles.terms__para__title}>Account Creation and Usage:</b> You can establish an account on the Website using your
            Phone Number as your Website ID and an OTP (One-Time Password) as your password. Alternatively, you can use other login
            credentials such as Facebook, Gmail, or any valid email ID. When setting up an account, you represent and warrant that all
            information provided is accurate, current, and complete. You commit to maintaining the accuracy of this information and updating
            it as necessary. Utilising another user's account information to access services is strictly prohibited. In the event that
            provided information is found to be inaccurate, incomplete, false, or outdated, the Website reserves the right to suspend,
            terminate, or restrict the account and future access for the respective user.
          </li>
          <li>
            <b className={styles.terms__para__title}>Account Responsibility:</b>
            The privilege to use this Website is solely for the user and is non-transferable to any other individual or entity. Safeguarding
            the confidentiality of passwords and other registration information falls under the user's responsibility. The user assumes full
            responsibility for any activities conducted under their account on the Website. The Website shall not be liable for any losses
            or damages resulting from the user's failure to maintain confidentiality. If any unauthorised account activity or security
            breach is noticed, the user must promptly inform the Website and log out at the end of each session.
          </li>
          <li>
            <b className={styles.terms__para__title}>Service Information and Third Parties:</b> When availing a service, users will be
            informed whether the service is solely provided by the Website or involves a third party. The Website has no control over the
            information transmitted to third parties through its platform.
          </li>
          <li>
            <b className={styles.terms__para__title}>Personal Data and Security:</b>
            Users acknowledge that personal data, including debit/credit card details, transmitted over the Internet could be susceptible to
            misuse, hacking, theft, or fraud. The Website and Payment Service Provider(s) hold no jurisdiction over such occurrences.
          </li>
          <li>
            <b className={styles.terms__para__title}>Usage Restrictions:</b>
            The Services provided by the Website are not permitted under the following circumstances:
            <ul className={styles.terms__para__ul__nested}>
              <li>If the user resides in a jurisdiction where the Services are prohibited.</li>
              <li>
                If the user's residency is subject to legal, regulatory, treaty, or administrative limitations that prohibit trade relations
                or other activities, including religious practices.
              </li>
              <li>
                If the user maintains multiple accounts using different mobile numbers. Users are limited to one active account with the
                Website.
              </li>
            </ul>
          </li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>"Talk To Astrologer" Feature:</b> The Website presents a specific service accessible
        through telecommunication with Astrologers listed and associated with the Website. Upon accepting these Terms of Usage, you also
        grant unconditional consent for the Website to arrange calls to your mobile number, even if your number is registered under the DND
        (Do Not Disturb) service provided by your mobile service provider.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Website Content: </b>
        <ul className={styles.terms__para__ul}>
          <li>
            The Website, along with any linked external sites, is considered private property. All interactions on this Website, including
            guidance and advice received directly from Licensed Providers, must adhere to these Terms of Usage.
          </li>
          <li>
            Users are prohibited from posting or transmitting any content on this Website that infringes upon others' rights, violates laws,
            is abusive, defamatory, invasive of privacy, obscene, profane, or objectionable. This also includes content that encourages
            criminal conduct or violates any law. The Website reserves the right to suspend or terminate access for users engaging in such
            behavior.
          </li>
          <li>
            Additionally, the Website holds the right to terminate access, modify, or discontinue any aspect or feature of the Website,
            including but not limited to content, graphics, deals, offers, and settings.
          </li>
          <li>
            The information provided on the Site, except for direct guidance and advice from Third-Party Service Providers, educational
            materials, graphics, research sources, and other related content, should not be considered medical advice. The Website does not
            guarantee the accuracy of medical advice, if provided, by third-party service providers, including registered astrologers. Users
            are advised to consult qualified healthcare professionals for medical diagnoses, treatment, and medication information.
          </li>
          <li>
            The Website does not assure any specific outcomes or results for users seeking services and cannot be held liable for any
            incidents occurring after service usage. Neither the Website nor the service providers guarantee specific results as expected by
            the user, and any risk associated with accessing the Website's content lies with the user.
          </li>
          <li>
            By utilising the Site, Application, or Services, users agree that any legal claims or liability related to the actions or
            omissions of other Members, including service providers registered with the Website or third parties linked to the Website,
            shall be limited to the particular party responsible for causing harm. Users agree not to hold the Website liable or seek legal
            remedies against the Website for such actions or omissions.
          </li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>User Account Access:</b>
        The Website is granted access to user accounts and the associated information to uphold and enhance the quality of services provided
        by the Website and to effectively address customer needs. By agreeing to these Terms of Usage, you give unconditional consent for
        the Website, its employees, agents, and designated personnel to access your account. Such access aims to facilitate the
        investigation of complaints and suspected abuse cases. For specifics, users are advised to review the terms detailed in the Privacy
        Policy regarding records.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}> Privacy Policy:</b> By using the Website, you express understanding, consent, and
        agreement to the Privacy Policy. This includes accepting the terms and contents of the Privacy Policy, including any updates,
        alterations, or changes that may be displayed on the Website.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Breach and Termination:</b>
        <ul className={styles.terms__para__ul}>
          <li>
            The Website reserves the right, without prior notice to the user, to modify, discontinue, change, or alter the ordered services
            or user accounts registered with the Website. The Website may or may not issue notice or provide reasons for such actions.
          </li>
          <li>
            Any violation of the conditions outlined in these Terms of Usage may lead to the immediate cancellation of the user's
            registration. The Website holds the authority to terminate access and initiate action immediately if:
          </li>
          <li>The Website is unable to verify or authenticate registration data or other relevant user information.</li>
          <li>
            The actions of the user might incur legal liability for the Website, other users, or service providers associated with the
            Website.
          </li>
          <li>The user has provided false and misleading registration data or interfered with other users or service providers.</li>
          <li>
            For Service Providers, including Astrologers, it is understood that your relationship with the Website is limited to being a
            member acting on your own behalf. The Website retains the right to terminate and deactivate the profile of such service
            providers for violations of these terms of usage and Service Terms and Conditions agreed upon during registration.
          </li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Delivery, Cancellation, and Refund:</b>
        <li>
          Refunds will not be processed for reports once they reach the "processing" stage (assigned to an Astrologer). The user bears the
          risk and liability of hurried or careless orders once processing has begun. No refund will be given for executed orders.
        </li>
        <li>
          For cancellations before execution, the user should contact customer care within 1 (one) hour of payment, but refunds will be at
          the discretion of the Website.
        </li>
        <li>Technical delays or glitches during processing, including generating reports, are not eligible for refunds.</li>
        <li>
          Refunds will not be processed for incorrect information provided by the user. Any change requests must be made to customer care
          within 1 (one hour) of service execution.
        </li>
        <li>No refund will be processed for damaged products.</li>
        <li>Refund on a pro-rata basis may be considered for subscription service delays.</li>
        <li>The Website's product display pictures are for reference only, and no refund will be issued on this basis.</li>
        <li>Products and services are not meant to replace medical treatment; the Website holds no responsibility for their effects.</li>
        <li>No refund will be processed for incorrect contact numbers for the "Call with Astrologer" feature.</li>
        <li>Refunds will be processed after deducting relevant charges.</li>
        <li>The Website may refuse or cancel orders for various reasons.</li>
        <li>Users requesting refunds give permission for access to consultation records for eligibility determination.</li>
        <li>
          <b className={styles.terms__para__title}>Adhigyanam Quality Audit and Refunds:</b> Adhigyanam's quality audit team, to the best of
          their efforts, provides partial or full refunds to users in their accounts, when consultant quality parameters are not met. The
          process may take up to 72 hours to analyse and refund the amount to the Adhigyanam wallet. Note that all refunds will be credited
          to the user's Accounts.
        </li>
        <li>
          <b className={styles.terms__para__title}>Refunds will be considered in the following cases: </b>
          <ul className={styles.terms__para__ul__nested}>
            <li>
              Network issues affecting chat or call quality, weak signals, background noise, or inaudible consultants during video/voice
              call sessions.
            </li>
            <li>Consultants unable to respond fluently in the specified language in their profile.</li>
            <li>Consultants take an unusually long time to respond to users.</li>
            <li>Consultants providing irrelevant or inappropriate responses to user queries.</li>
          </ul>
        </li>
      </p>
      <p className={styles.terms__headlineCont}>
        <b className={styles.terms__headlineCont__headline}>
          <i>
            Please note that refunds will not be granted for the lack of accuracy in consultations. Adhigyanam is not responsible for
            factual accuracy in consultations.
          </i>
        </b>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>User Obligations:</b> Users, including astrologers and member customers, are obligated not
        to violate the privacy policy, terms and conditions, and any other terms defined on the Website. Users confirm their individual
        status and not being a corporation or legal business entity. The right to use the Website's services is personal to the user.
      </p>
      <p className={styles.terms__para}>
        While engaging in communication, including products, on the Website, users must not violate terms and conditions that include:
        <ul className={styles.terms__para__ul}>
          <li>
            Avoid posting false, misleading, harmful, defamatory, abusive, threatening, or offensive content that invades privacy, promotes
            racism, hatred, or infringes upon rights.
          </li>
          <li>Do not upload content without the right to do so, violating laws or fiduciary relationships.</li>
          <li>Do not infringe upon patents, trademarks, copyrights, or proprietary rights of others.</li>
          <li>Do not collect member contact information for spam or solicitation.</li>
          <li>Avoid sending unsolicited emails, junk mail, spam, or chain letters.</li>
          <li>Refrain from uploading files containing viruses or harmful software.</li>
          <li>Do not disrupt Website access or interfere with its features.</li>
          <li>Do not attempt unauthorized access to the Website or its systems.</li>
          <li>Do not violate any applicable laws and use the services personally.</li>
          <li>Do not resell or commercially use services without consent.</li>
          <li>Do not violate these Terms of Usage or other applicable terms.</li>
          <li>Do not reverse engineer, modify, or distribute information obtained from the Website.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        By becoming a registered member, users agree to:
        <ul className={styles.terms__para__ul}>
          <li>Receive communication via the app/website, email, SMS, or other mediums.</li>
          <li>Avoid transmitting objectionable or criminal material.</li>
          <li>Not interfere with others' Website use.</li>
          <li>Report misuse or abuse to the Website.</li>
          <li>Avoid making false complaints.</li>
          <li>
            <b className={styles.terms__para__title}>Website Actions:</b> The Website reserves the right to withdraw services from
            unreasonable or abusive customers, regardless of the reason. Violations of obligations may result in a written warning or, in
            uncontrollable situations, a ban from transacting on the platform. The user's wallet balance may be refunded after applicable
            charges for violations.
          </li>
        </ul>
      </p>
      <p className={styles.terms__headlineCont}>
        <b className={styles.terms__headlineCont__headline}>
          Please note that this list is not exhaustive and may include additional services incidental to the above.
        </b>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Bank Account Information:</b> Users are required to provide their banking information as
        needed. In fulfilling this obligation, users agree to:
        <ul className={styles.terms__para__ul}>
          <li>Ensure correctness and accuracy of debit/credit card details provided for service use.</li>
          <li>Not use a debit/credit card not lawfully owned or authorised for use.</li>
          <li>Provide valid and accurate debit/credit card details.</li>
          <li>Pay fees using debit/credit cards or online banking accounts.</li>
          <li>Confirm entitlement to use credit/debit cards for transactions.</li>
          <li>Ensure accuracy of provided card/bank account details.</li>
          <li>Ensure sufficient credit for payment at the time of transaction.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        Should any part of these Terms of Usage be invalidated or unenforceable according to applicable law, including warranty disclaimers
        and liability limitations, the invalid or unenforceable provision shall be replaced with a valid, enforceable provision that closely
        aligns with the original intent. The remaining Terms of Usage will remain effective.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Disclaimer / Limitation of Liability / Warranty: </b>
        <ul className={styles.terms__para__ul}>
          <li>
            Users understand and agree that, to the maximum extent allowed by applicable law, the Website does not provide warranties for
            its services. Astrological counselling provided through the Website is based on astrologers' knowledge, experience, and
            interpretations, which may differ.
          </li>
          <li>
            The Website offers services through verified astrologers who may recommend remedies like mantras, gemstones, or other
            astrological solutions. These recommendations are made in good faith, and the Website, its subsidiaries, affiliates, officers,
            employees, agents, partners, and licensors offer no warranty that:
            <ul className={styles.terms__para__ul__nested}>
              <li>The service will meet user requirements.</li>
              <li>The service will be uninterrupted, timely, secure, or error-free.</li>
              <li>The results from using the service will be accurate.</li>
              <li>Products, services, or information purchased will meet expectations.</li>
              <li>Software errors will be corrected.</li>
              <li>Users must fully disclose the emotional, mental, and physical state of the person seeking advice.</li>
            </ul>
          </li>
          <li>
            The Website provides services "as is" without warranties, express, implied, statutory, or otherwise, including implied
            warranties of title, non-infringement, merchantability, or fitness for a specific purpose. The Website makes no warranty:
            <ul className={styles.terms__para__ul__nested}>
              <li>About meeting user requirements.</li>
              <li>That use of the Website will be uninterrupted, timely, secure, or error-free.</li>
              <li>That results obtained from using the Website will be effective or accurate.</li>
              <li>About the quality of the Website or its services.</li>
              <li>No advice or information obtained from the Website creates a warranty not expressly stated in the Terms of Use.</li>
            </ul>
          </li>
          <li>
            The Website has no liability for intellectual property rights, libel, privacy, publicity, or obscenity related to user content.
            It disclaims liability for misuse, loss, modification, or unavailability of user content.
          </li>
          <li>
            The Website is not liable for unauthorized use of user accounts or account information. It aims to provide accurate information
            but does not guarantee its quality, accuracy, or completeness. The Website is not responsible for Website unavailability due to
            maintenance or technical issues beyond its control.
          </li>
          <li>
            Users downloading or obtaining data through the Website assume all risk and responsibility for damage to their systems. The
            Website is not responsible for typographical errors or misinformation. It accepts no liability for errors, omissions, or
            information from itself or third parties.
          </li>
          <li>
            The services and products provided are for entertainment purposes only. The Website disclaims warranties, including
            merchantability and fitness for a purpose. It takes no responsibility for astrological effects on human physiology, products, or
            services sold. Users are advised to exercise discretion. The Website's advisors are members and not employees. The Website
            verifies their credentials but does not endorse advice or guarantee advice validity, accuracy, completeness, or safety.
          </li>
          <li>
            <b className={styles.terms__para__title}>Suicide and Emergency Contact:</b> The Website is not a suicide helpline platform. If
            you are contemplating suicide or believe you're a danger to yourself or others, you should immediately discontinue using the
            services and contact appropriate police or emergency medical personnel. If you're considering suicide, reach out to a suicide
            prevention helpline such as AASRA (91-22-27546669).
          </li>
          <li>
            The Website holds no liability for inaccuracies, errors, delays, or omissions in data, information, messages, or their
            transmission or delivery. The Website, its employees, directors, and third-party agents are not liable for direct, indirect,
            incidental, special, or consequential damages, including punitive or exemplary damages, arising from service provision or
            deficiencies, unauthorised data access, alteration, or service suspension/termination.
          </li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        However, Adhigyanam's liability to you, regardless of the cause and form of action, will be limited to the amount paid by you to the
        Website for the service during your membership.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Indemnification:</b> Users agree to indemnify, defend, and hold harmless the Website, its
        parent, subsidiaries, affiliates, officers, directors, employees, suppliers, consultants, and agents from third-party claims,
        liabilities, damages, and costs (including attorney’s fees) arising from use of the services, violation of Privacy Policy or Terms
        of Service, or violation of third-party rights, including infringement.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Proprietary Rights to Content:</b> Users acknowledge that Content, including text,
        software, music, sound, photographs, video, graphics, and other material in sponsor advertisements or distributed via email, is
        protected by copyrights, trademarks, patents, and other proprietary rights. Users may not copy, use, reproduce, distribute, perform,
        display, or create derivative works from Content unless authorised by the Website. Moreover, content (such as images, text, designs)
        from various online portals like Google Images is used across the Website's portals. Adhigyanam or Codeyeti Software Solutions Pvt
        Ltd. is not liable for any copyrights related to this content or data.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Notices:</b> Except as stated in the Terms of Service, notices shall be in writing and
        delivered via email or snail mail. Notice is deemed given 24 hours after email or 3 days after deposit in snail mail. For member
        notices, the address provided during Registration Data is used. The Website's address is:
        <p className={styles.terms__headlineCont}>
          <b className={styles.terms__headlineCont__headline}>
            "F-11, 3rd Floor, Aarohi Arcade, Munshi Pulia, Indira Nagar, Lucknow, India"
          </b>
        </p>
      </p>
      <b className={styles.terms__para__title}>RESTRICTED CONTENT</b>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Child Endangerment:</b> The App prohibits content that facilitates child exploitation or
        abuse, including child sexual abuse materials. To report such content, users can follow a designated link. Child endangerment, such
        as predatory behavior towards children, is strictly prohibited. This includes inappropriate interaction, child grooming,
        sexualization of minors, sextortion, and child trafficking. The App takes appropriate action upon awareness of child sexual abuse
        materials.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Inappropriate Content:</b> The Website/App upholds standards against harmful or
        inappropriate content:
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Sexual Content and Profanity: </b>
        <ul className={styles.terms__para__ul}>
          <li>
            Accounts or content containing or promoting sexual content, profanity, pornography, or sexually gratifying material are
            prohibited. Content soliciting sexual acts in exchange for compensation is disallowed, as is content associated with sexually
            predatory behavior. Accounts violating this policy may be deleted immediately by their website.
          </li>
          <li>Content Violations</li>
          <li>Sexual and Inappropriate Content</li>
          <li>
            Certain content violations involve sexual and inappropriate material:
            <ul className={styles.terms__para__ul__nested}>
              <li>Images, animations, or illustrations featuring sexual nudity, suggestive poses, or explicit clothing.</li>
              <li>Content depicting sex acts, sexualized body parts, or explicit sexual themes.</li>
              <li>Apps related to illegal sexual themes, fetishes, or sexual aids.</li>
              <li>Lewd or profane content with explicit text, slurs, or adult/sexual keywords.</li>
              <li>Content promoting bestiality or sexual exploitation.</li>
              <li>Services associated with compensated dating or solicitation of sexual acts in exchange for compensation.</li>
              <li>Apps attempting to undress people, see through clothing, or objectify individuals.</li>
            </ul>
          </li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Hate Speech:</b> We prohibit content that incites hatred or violence against individuals or
        groups based on protected characteristics:
        <ul className={styles.terms__para__ul}>
          <li>Statements degrading or demonising a protected group.</li>
          <li>Hateful slurs, stereotypes, or claims about negative characteristics of a protected group.</li>
          <li>Content encouraging hatred or discrimination against a protected group.</li>
          <li>Promotion of hate symbols or insignias linked to hate groups.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Violence:</b> We disallow apps promoting gratuitous violence or dangerous activities.
        <ul className={styles.terms__para__ul}>
          <li>Graphic depictions of realistic violence towards people or animals.</li>
          <li>Content endorsing self-harm, suicide, eating disorders, or dangerous games.</li>
          <li>Apps promoting violence against civilians or acts resulting in serious injury.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Terrorist Content:</b> Content related to terrorism, including promoting terrorist acts,
        inciting violence, or celebrating attacks, is prohibited:
        <ul className={styles.terms__para__ul}>
          <li>No support for terrorist organisations or content that facilitates terrorism.</li>
          <li>Content promoting terrorism, violence, or celebrating terrorist attacks.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Dangerous Organisations and Movements:</b> We forbid content from movements or
        organisations involved in violence against civilians:
        <ul className={styles.terms__para__ul}>
          <li>Content related to movements or organisations engaged in violence.</li>
          <li>Content glorifying, planning, or encouraging violence against civilians.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Sensitive Events:</b> Sensitive event violations involve insensitivity towards significant
        events with social, cultural, or political impact:
        <ul className={styles.terms__para__ul}>
          <li>Insensitivity towards deaths from suicide, overdose, or natural causes.</li>
          <li>Denying documented tragic events.</li>
          <li>Attempting to profit from a sensitive event without benefiting victims.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Bullying and Harassment:</b> Content facilitating threats, bullying, or harassment is not
        allowed:
        <ul className={styles.terms__para__ul}>
          <li>Bullying victims of conflicts or religious disputes.</li>
          <li>Content exploiting others through extortion or blackmail.</li>
          <li>Public humiliation through posted content.</li>
          <li>Harassing victims, their friends, or families of tragic events.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Dangerous Products:</b>
        <ul className={styles.terms__para__ul}>
          <li>
            We prohibit content facilitating the sale or creation of explosives, firearms, ammunition, or restricted firearm accessories:
          </li>
          <li>Sale of explosives, firearms, ammunition, or certain firearm accessories.</li>
          <li>Instructions for manufacturing explosives, firearms, ammunition, or weapons.</li>
          <li>Conversion instructions for automatic firing capabilities.</li>
        </ul>
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Psychotropic Drugs:</b> We do not permit users to facilitate the sale of marijuana or
        marijuana-related products, irrespective of their legality. We also prohibit users from facilitating the sale of tobacco products,
        including e-cigarettes and vape pens, and from encouraging the illegal or improper use of alcohol or tobacco. Users are not allowed
        to facilitate the sale of psychotropic drugs or drugs categorized under the restricted section of the Drugs and Cosmetics Act and
        Rules 1945.
      </p>
      <p className={styles.terms__para}>
        <b className={styles.terms__para__title}>Black Magic, Witchcraft, Voodoo, and Tantrism:</b> Our organization strictly prohibits
        users from engaging in any form of black magic, witchcraft, voodoo, and tantrism. If we become aware of any user intentionally or
        unintentionally involved in such activities, we reserve the right to delete their account.
      </p>
      <div className={styles.terms__div}>
        <b className={styles.terms__para__title}>SAUNTESTHYAM SERVICES:</b>
        <p className={styles.terms__div__para}>
          These Terms of Service are applicable to all users of <b>SAUNTESHTHYAM LLP (Adhigyanam)</b>. User-provided information within the
          SAUNTESTHYAM SERVICES may include links to third-party websites not owned or controlled by SAUNTESTHYAM. We do not have authority
          over, and assume no responsibility for, the content, privacy policies, or practices of third-party websites.{" "}
          <b>SAUNTESHTHYAM LLP</b> does not assume liability for any mistakes, misinterpretations of law, defamation, omissions, falsehood,
          obscenity, pornography, or profanity present in statements, opinions, representations, or other forms of third-party content on
          the Site, as well as that generated by its users. Moreover, SAUNTESHTHYAM LLP does not censor or edit the content of users or
          third-party sites. By utilizing the Service, you expressly acknowledge that SAUNTESHTHYAM LLP is not responsible for damages,
          claims, or other liability arising from or related to the use of any content and third-party websites. You understand that the
          content represents the author's views and is not necessarily endorsed by or reflective of <b>SAUNTESHTHYAM LLP</b>
          's beliefs.
        </p>
        <p className={styles.terms__div__para}>
          <b className={styles.terms__para__title}>Errors, Corrections, and Modifications:</b> We cannot guarantee that the{" "}
          <b>SAUNTESHTHYAM LLP</b> Site, namely adhigyanam, will be free from errors, viruses, or other harmful components, nor that defects
          will be rectified. We cannot assure the accuracy, timeliness, or reliability of information available on or through adhigyanam.
          Changes to adhigyanam's features, functionality, or content may be made at any time. We retain the right to edit or remove
          documents, information, or other content from the Site. Additionally, we reserve the right to modify or discontinue the Services
          and/or Sites, in whole or part, temporarily or permanently, with or without notice. Any such modifications, suspensions, or
          discontinuations shall not incur liability to you or any third party.
        </p>
        <p className={styles.terms__div__para}>
          <b className={styles.terms__para__title}>Governing Law and Jurisdiction:</b> Any disputes arising from or related to these Terms
          of Usage, including matters of scope and applicability, shall be resolved through arbitration in India, before a mutually
          agreed-upon sole arbitrator appointed by Members and Website. Arbitration shall adhere to the Arbitration and Conciliation Act,
          1996, with the seat of arbitration in New Delhi. Proceedings, including awards, shall be conducted in English. The award shall be
          binding on the parties to the dispute.
        </p>
        <p className={styles.terms__div__para}>
          However, either party may seek interim relief from a court of competent jurisdiction in New Delhi to protect their rights during
          arbitration. Both parties agree to exclusive jurisdiction of Indian courts and venue in Lucknow for such proceedings. In case of a
          contrary action, the filing party may be liable for attorney fees and costs up to One Lakh Rupees INR. These Terms of Usage shall
          be governed by the laws of India, and any unenforceable provision shall be interpreted to fulfil its objectives within the bounds
          of applicable law. Headings are for reference only and do not define scope. These Terms of Usage constitute the complete agreement
          between the parties, replacing prior understandings. The parties submit any disputes to New Delhi's jurisdiction and retain the
          right to seek injunctive relief for intellectual property rights infringement.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
