import React from "react";
import style from "./index.module.scss";
import loder2 from "../../assets/loading-51.gif.webp";

const Loading = (props) => {
  return (
    <div style={{ height: `${props.height}`, width: `${props.width}` }} className={style.loading}>
      <img src={loder2} alt="loading" />
    </div>
  );
};

export default Loading;
