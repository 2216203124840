import React from "react";
import style from "./index.module.scss";
import TestimonialUser from "../TestimonialUser";

const TestimonialArea = (props) => {
  return (
    <div className={style.testimonialArea} style={{ height: `${props.height}` }}>
      <h2>{props.title}</h2>
      <p>
        {props.testimonial}{" "}
        <span onClick={() => props.onClick(props.testimonial)} style={{ color: "blue", display: `${props.display}`, cursor: "pointer" }}>
          Read More..
        </span>
      </p>

      <TestimonialUser ratting={props.ratting} designation={props.designation} user={props.user} src={props.src} />
    </div>
  );
};

export default TestimonialArea;
