import React from "react";
import TestimonialArea from "../TestimonialArea";
import style from "./index.module.scss";

const Card = (props) => {
  return (
    <div className={style.card}>
      <TestimonialArea
        display={props.display}
        height={props.height}
        title={props.title}
        testimonial={props.testimonial}
        designation={props.designation}
        user={props.user}
        src={props.src}
        ratting={props.ratting}
        onClick={props.onClick}
      />
    </div>
  );
};

export default Card;
