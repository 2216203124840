import React, { useState } from "react";
import ModalLayout from "../../components/ModalLayout";
import style from "./index.module.scss";
import Select from "../../widgets/Select";
import Button from "../../widgets/Button";
import InputFieldWithIcon from "../../widgets/InputFieldWithIcon";
import plus from "../../assets/plus.svg";
import minus from "../../assets/minus.svg";
import DragAndDrop from "../../components/DragAndDrop";
import axios from "axios";
import banner from "../../assets/banner.webp";
import email from "../../assets/email.png";
import { useEffect } from "react";
import Loading from "../../components/Loading";
import PhoneNumber from "../../components/PhoneInput";
import CommonSelect from "../../components/CommonSelect";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import pdfImg from "../../assets/pdfimg.svg";
import ReactCropper from "../../widgets/imgModal/cropper";
import { addYears } from "date-fns";
const languageList = [
  { id: 1, name: "Select" },
  { id: 2, name: "English" },
  { id: 3, name: "Hindi" },
];

const genderList = [
  { id: 1, name: "Select" },
  { id: 2, name: "Male" },
  { id: 3, name: "Female" },
  { id: 4, name: "Other" },
];

const loginLists = [
  { id: 2, name: "User" },
  { id: 1, name: "Astrologer" },
];

const SignUp = (props) => {
  const [onShowCroper, setOnShowCroper] = useState(false);
  const [croppedImg, setCroppedImg] = useState("");
  const [croppedImgUrl, setCroppedImgUrl] = useState("");
  const initialDate = addYears(new Date(), -18);
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState("User");
  const [startDate, setStartDate] = useState("");

  const [selectedDate, setSelectedDate] = useState();
  const [profile, setProfile] = useState();
  const [servicesLists, setServicesLists] = useState();
  const [documentFile, setDocumentFile] = useState();
  const [profilePath, setProfilePath] = useState();
  const [documentPath, setDocumentPath] = useState();
  const [counter, setCounter] = useState(1);
  const [services, setServices] = useState();
  const [servicesId, setServicesId] = useState();
  const [showProfile, setShowProfile] = useState();
  const [showDoc, setShowDoc] = useState();
  const [loading, setLoading] = useState(false);
  const [signupForm, setSignupForm] = useState({
    name: "",
    Gender: genderList[0].name,
    Language: languageList[0].name,
    timeOfBirth: "",
    password: "",
    email: "",
    PlaceOfBirth: "",
    confirmPassword: "",
    // externalLink: "",
  });

  const onExperienceHandler = (e) => {
    if (e.target.value < 0) {
      swal("Experience Not Less Than 0", "error");
      return;
    }
    setCounter(parseInt(e.target.value));
  };

  const onDecrement = () => {
    setCounter(counter - 1);
  };

  const onIncrement = () => {
    setCounter(counter + 1);
  };

  const selectedServicesList = (list) => {
    setServicesId(list.id);
    setServices(list.name);
  };
  const selectedUserList = (list) => {
    setUserType(list.name);
  };

  const onAddDocument = (e) => {
    let path = e.target.files[0];
    setDocumentPath(path);
    let image = URL.createObjectURL(path);

    if (path?.name?.includes(".pdf")) {
      setDocumentFile(pdfImg);
      setShowDoc(true);
    } else {
      setShowDoc(true);
      setDocumentFile(image);
    }
  };

  const onAddProfile = (e) => {
    let path = e.target.files[0];
    setProfilePath(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setProfile(image);
      setShowProfile(true);
      setOnShowCroper(true);
    }
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };
  const onSignupFormHandler = (e) => {
    let fieldName = e.target.id;
    let fieldValue = e.target.value;

    let newData = { ...signupForm };

    newData[fieldName] = fieldValue;
    setSignupForm(newData);
  };

  useEffect(() => {
    const url = "https://admin.adhigyanam.com/api/predection-services";

    axios
      .get(url)
      .then((res) => {
        if (res.data.data) {
          let val = res.data.data;
          setServicesLists(val);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);

  // function isValidUrl(url) {
  //   try {
  //     new URL(url);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  const onSubmit = () => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let url = "https://admin.adhigyanam.com/api/register";
    let user_type = userType === "Astrologer" ? 1 : 2;

    const formdata = new FormData();

    if (user_type === 1) {
      if (signupForm.name.trim() === "") {
        swal("Failed!", "Name Should Not Be Empty", "error");
        return;
      } else if (specialChars.test(signupForm.name)) {
        swal("Failed!", "Name should not contain special characters", "error");
        return;
      } else if (signupForm.name.length < 3) {
        swal("Failed!", "Name Length >= 3", "error");
        return;
      } else if (selectedDate === "") {
        swal("Failed!", "Date Of Birht Not Be Empty", "error");
        return;
      } else if (signupForm.Gender === "Select") {
        swal("Failed!", "Select A Gender", "error");
        return;
      } else if (phone === "" || phone === undefined) {
        swal("Failed!", "Phone is required", "error");
        return;
      } else if (!servicesId) {
        swal("Failed!", "Select A Service", "error");
        return;
      } else if (signupForm.Language === "Select") {
        swal("Failed!", "Select A Language", "error");
        return;
      } else if (!documentPath) {
        swal("Failed!", "Upload A Document", "error");
        return;
      } else if (!profilePath) {
        swal("Failed!", "Upload A Profile Pic", "error");
        return;
      } else if (signupForm.email.trim() === "") {
        swal("Failed!", "Email Not Be Empty", "error");
        return;
      } else if (!signupForm.email.includes("@") || !signupForm.email.includes(".")) {
        swal("Failed!", "Invalid Email", "error");
        return;
      } else if (signupForm.password.trim() === "") {
        swal("Failed!", "Password Not Be Empty", "error");
        return;
      } else if (signupForm.password.length < 6) {
        swal("Failed!", "Password Length >= 6", "error");
        return;
      } else if (signupForm.confirmPassword.trim() !== signupForm.password.trim() || signupForm.confirmPassword.trim() === "") {
        swal("Failed!", "Password Not Match", "error");
        return;
      }
      //  else if (!isValidUrl(signupForm.externalLink)) {
      //   swal("Failed!", "Enter A Valid URL", "error");
      //   return;
      // } 
      else {
        formdata.append("name", signupForm.name);
        formdata.append("time_of_birth", signupForm.timeOfBirth);
        formdata.append("gender", signupForm.Gender);
        formdata.append("place_of_birth", signupForm.PlaceOfBirth);
        formdata.append("date_of_birth", selectedDate);
        formdata.append("expereience", counter);
        formdata.append("document", documentPath);
        formdata.append("profile_pic", profilePath);
        formdata.append("language", signupForm.Language);
        formdata.append("user_type", user_type);
        formdata.append("speacility", servicesId);
        formdata.append("password", signupForm.password);
        formdata.append("email", signupForm.email);
        formdata.append("contact_no", phone);
        // formdata.append("external_link", signupForm.externalLink);
      }
    } else {
      if (signupForm.name.trim() === "") {
        swal("Failed!", "Name Should Not Be Empty", "error");
        return;
      } else if (specialChars.test(signupForm.name)) {
        swal("Failed!", "Name should not contain special characters", "error");
        return;
      } else if (selectedDate === "") {
        swal("Failed!", "Date Of Birht Not Be Empty", "error");
        return;
      } else if (signupForm.name.length < 3) {
        swal("Failed!", "Name Length >= 3", "error");
        return;
      } else if (signupForm.Gender === "Select") {
        swal("Failed!", "Select A Gender", "error");
        return;
      } else if (phone === "" || phone === undefined) {
        swal("Failed!", "Phone is required", "error");
        return;
      } else if (signupForm.Language === "Select") {
        swal("Failed!", "Select A Language", "error");
        return;
      } else if (signupForm.PlaceOfBirth === "") {
        swal("Failed!", "Place Of Birth Not Be Empty", "error");
        return;
      } else if (signupForm.timeOfBirth === "") {
        swal("Failed!", "time Of Birth Not Be Empty", "error");
        return;
      } else if (signupForm.email.trim() === "") {
        swal("Failed!", "Email Not Be Empty", "error");
        return;
      } else if (!signupForm.email.includes("@") || !signupForm.email.includes(".")) {
        swal("Failed!", "Invalid Email", "error");
        return;
      } else if (signupForm.password.trim() === "") {
        swal("Failed!", "Password Not Be Empty", "error");
        return;
      } else if (signupForm.password.length < 6) {
        swal("Failed!", "Password Length >= 6", "error");
        return;
      } else if (signupForm.confirmPassword.trim() !== signupForm.password.trim() || signupForm.confirmPassword.trim() === "") {
        swal("Failed!", "Password Not Match", "error");
        return;
      } else {
        formdata.append("name", signupForm.name);
        formdata.append("time_of_birth", signupForm.timeOfBirth);
        formdata.append("gender", signupForm.Gender);
        formdata.append("place_of_birth", signupForm.PlaceOfBirth);
        formdata.append("date_of_birth", selectedDate);
        formdata.append("profile_pic", profilePath);
        formdata.append("language", signupForm.Language);
        formdata.append("user_type", user_type);
        formdata.append("password", signupForm.password);
        formdata.append("email", signupForm.email);
        formdata.append("contact_no", phone);
      }
    }
    setLoading(true);
    const getConfig = {
      url: url,
      method: "post",
      "Content-Type": "application/json",
      data: formdata,
    };

    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          props.setSignup(false);
          swal("Successful!", "Registered Successfully!", "success");
          setLoading(false);
        } else {
          swal("Failed!", "Email Already Registered!", "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err)
        setLoading(false);
        swal("Failed!", "Registration Failed!", "error");
      });
  };
  // ********************************************************
  const onSelectDateHandler = (date) => {
    setStartDate(date);
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    // eslint-disable-next-line
  }, []);

  const onSaveHandler = () => {
    setProfilePath(croppedImg);
    setProfile(croppedImgUrl);
    setOnShowCroper(false);
  };

  const onModalClose = () => {
    setOnShowCroper(false);
  };
  return (
    <>
      {onShowCroper && (
        <ReactCropper
          imgURL={profile}
          setCroppedImg={setCroppedImg}
          onSaveHandler={onSaveHandler}
          onModalClose={onModalClose}
          setCroppedImgUrl={setCroppedImgUrl}
        />
      )}
      <ModalLayout backgroundImage={banner} onCloseHandler={props.onCloseHandler}>
        <div style={{ alignItems: userType === "User" ? "flex-end" : "" }} className={style.signup}>
          {loading ? (
            <div className={style.signup__loder}>
              <Loading width="100%" />
            </div>
          ) : (
            <>
              <div className={style.signup__left}>
                <h2 className={style.mobileTittle}>Adhigyanam</h2>
                <div className={style.signup__left__signupAs}>
                  <div className={style.signup__left__signupAs__text}>
                    <p>Signup As : </p>
                  </div>
                  <Select select={userType} selectedList={selectedUserList} initial="User" lists={loginLists} color="#950606" />
                </div>
                <div className={style.signup__left__name}>
                  <InputFieldWithIcon
                    borderColor="#34baaf"
                    color="#000"
                    textTransform="uppercase"
                    borderRadius="10px"
                    id="name"
                    label="Name"
                    placeholder="Name"
                    display="none"
                    padding="0 0 0 10px"
                    onChange={onSignupFormHandler}
                  />
                </div>
                <div className={style.signup__left__genAge}>
                  <div className={style.signup__left__genAge__child}>
                    <p>Date of Birth</p>
                    {userType !== "User" ? (
                      <DatePicker
                        maxDate={initialDate}
                        className={style.datePicker}
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={onSelectDateHandler}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    ) : (
                      <DatePicker
                        maxDate={new Date()}
                        className={style.datePicker}
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={onSelectDateHandler}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    )}
                  </div>

                  <CommonSelect
                    marginBottom="5px"
                    marginTop="5px"
                    value={signupForm.Gender}
                    label="Gender"
                    list={genderList}
                    onChange={onSignupFormHandler}
                  />
                </div>

                <div className={style.signup__left__genAge}>
                  <div className={style.signup__left__genAge__child}>
                    <p>Enter Phone</p>
                    <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                  </div>

                  {userType !== "User" && (
                    <div style={{ width: "100%" }} className={style.signup__left__mid__bottom}>
                      <label>choose services</label>
                      <Select
                        top="-80px"
                        color2="#950606"
                        margin="10px 0 20px"
                        color="#7e7e7e"
                        initial="Select Service"
                        lists={servicesLists}
                        select={services}
                        selectedList={selectedServicesList}
                      />
                    </div>
                  )}
                </div>
                {/* /////////////////////////////////////////////////// */}

                <div className={style.signup__left__mid}>
                  <CommonSelect
                    marginBottom="16px"
                    marginTop="5px"
                    value={signupForm.Language}
                    label="Language"
                    list={languageList}
                    onChange={onSignupFormHandler}
                  />
                </div>
              </div>
              <div className={style.signup__right}>
                <h1 style={{ textAlign: userType === "User" ? "right" : "" }}>Adhigyanam</h1>
                <div className={style.signup__right__imageArea}>
                  {userType === "User" && (
                    <div className={style.signup__right__imageArea__dragandDrop}>
                      <div className={style.signup__right__imageArea__dragandDrop__phone}>
                        <InputFieldWithIcon
                          borderColor="#34baaf"
                          color="#000"
                          textTransform="uppercase"
                          borderRadius="10px"
                          id="PlaceOfBirth"
                          label="Place Of Birth"
                          placeholder="Place Of Birth"
                          display="none"
                          display2="none"
                          padding="0 0 0 10px"
                          type="text"
                          onChange={onSignupFormHandler}
                        />
                      </div>
                      <div className={style.signup__right__imageArea__dragandDrop__phone}>
                        <InputFieldWithIcon
                          borderColor="#34baaf"
                          color="#000"
                          textTransform="uppercase"
                          borderRadius="10px"
                          id="timeOfBirth"
                          label="Time Of Birth"
                          placeholder="Time Of Birth"
                          display="none"
                          display2="none"
                          padding="0 0 0 10px"
                          type="time"
                          onChange={onSignupFormHandler}
                        />
                      </div>
                    </div>
                  )}
                  <div className={style.signup__right__imageArea__drag}>
                    {userType !== "User" && (
                      <>
                        <DragAndDrop
                          image={documentFile}
                          showFile={showDoc}
                          filesHandler={onAddDocument}
                          id="documentImage"
                          type="(Aadhar/Pan/Passport/Driving Lisence)"
                          pdf=".pdf"
                          screen="regis"
                        />

                        <DragAndDrop
                          showFile={showProfile}
                          id="profileImage"
                          image={profile}
                          filesHandler={onAddProfile}
                          fileType="UPLOAD PROFILE PHOTO"
                          type="(Photograph)"
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className={style.signup__right__password}>
                  <InputFieldWithIcon
                    borderColor="#34baaf"
                    color="#000"
                    textTransform="uppercase"
                    borderRadius="10px"
                    src2={email}
                    id="email"
                    label="Email"
                    placeholder="Email"
                    display="none"
                    padding="0 0 0 10px"
                    type="email"
                    onChange={onSignupFormHandler}
                  />
                </div>

                <div className={style.signup__right__cpassword}>
                  <div>
                    <InputFieldWithIcon
                      borderColor="#34baaf"
                      color="#000"
                      textTransform="uppercase"
                      borderRadius="10px"
                      id="password"
                      label="Password"
                      placeholder="Password"
                      display="none"
                      padding="0 0 0 10px"
                      type="password"
                      onChange={onSignupFormHandler}
                      guide="Password not less than 6 digits"
                    />
                  </div>
                  <div>
                    <InputFieldWithIcon
                      borderColor="#34baaf"
                      color="#000"
                      textTransform="uppercase"
                      borderRadius="10px"
                      id="confirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      display="none"
                      padding="0 0 0 10px"
                      type="password"
                      onChange={onSignupFormHandler}
                    />
                  </div>
                </div>
                {userType !== "User" && (
                  <div className={style.signup__left__mid__left}>
                    <label>experience</label>
                    <InputFieldWithIcon
                      borderColor="#34baaf"
                      id="experience"
                      height="25px"
                      width="25px"
                      src1={minus}
                      src2={plus}
                      textAlign="center"
                      onChange={onExperienceHandler}
                      onDecrement={onDecrement}
                      onIncrement={onIncrement}
                      value={counter}
                      type="number"
                      display={counter < 1 ? "none" : ""}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {/* {!loading && (
          <>
            {userType !== "User" && (
              <div className={style.link}>
                <p>SOCIAL LINK</p>
                <InputFieldWithIcon
                  borderColor="#34baaf"
                  color="#000"
                  textTransform="uppercase"
                  borderRadius="10px"
                  id="externalLink"
                  placeholder="Facebook, Instagram, etc."
                  display="none"
                  padding="0 0 0 10px"
                  type="url"
                  onChange={onSignupFormHandler}
                />
              </div>
            )}
          </>
        )} */}

        <div className={style.signup__btn}>
          {!loading && (
            <>
              <Button
                btnName="Cancel"
                fontSize="20px"
                backgroundColor="#c4c4c4"
                border="1px solid #c4c4c4"
                color="#333"
                textTransform="capitalize"
                width="200px"
                onClick={props.onCancelSignUp}
              />
              <Button btnName="Submit" onClick={onSubmit} fontSize="20px" textTransform="capitalize" width="200px" />
            </>
          )}
        </div>
      </ModalLayout>
    </>
  );
};

export default SignUp;
