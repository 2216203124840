import React from "react";
import Styles from "./index.module.scss";
import success from "../../assets/newassets2/Astrology assets/success.gif";
import Button from "../../widgets/Button";
import { useNavigate, useParams } from "react-router-dom";

const CourseThankYou = () => {
  const naviget = useNavigate();
  const params = useParams();
  const onRedirectHandler = () => {
    naviget("/");
  };
  //   let path = ("/courseThankYou",{state:{transactionId:"",message:""}});
  return (
    <div className={Styles.container}>
      <div className={Styles.container__popupArea}></div>
      <div className={Styles.container__messageArea}>
        <div className={Styles.container__messageArea__img}>
          <img src={success} alt="success" />
        </div>
        <h2>Thank You</h2>
        <p>Your Payment Is Succesfully Done.</p>
        <p>Please Note Your Transaction Id: {params.transactionId}</p>
        <p>Now You Can Access Your Courses.</p>
        <Button onClick={onRedirectHandler} btnName=" Home" width="150px" fontSize="18px" marginBottom="5px" marginTop="5px" />
      </div>
    </div>
  );
};

export default CourseThankYou;
