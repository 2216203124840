import React from "react";
import Styles from "./index.module.scss";
import { Rating } from "@mui/material";
import Button from "../../../widgets/Button";
const CoursePakage = (props) => {
  return (
    <div className={Styles.cardArea}>
      <div className={Styles.cardArea__imgArea}>
        <img src={props.src} alt="err" />
        <span>
          <i className="fa-solid fa-circle-play"></i>
        </span>
      </div>
      <div className={Styles.cardArea__detailArea}>
        <h2>{props.title}</h2>
        <span>
          <Rating name="half-rating-read" defaultValue={props.rating} precision={0.5} readOnly />
        </span>
        <h5 className={Styles.cardArea__detailArea__detail}>
          {" "}
          <span>
            <i className="fa-solid fa-book"></i>
          </span>
          {props.sub_title}
        </h5>
        <h5 className={Styles.cardArea__detailArea__detail}>
          <span>
            <i className="fa-solid fa-language"></i>
          </span>
          ({props.language})
        </h5>

        <h5 className={Styles.cardArea__detailArea__detail}>
          {" "}
          <span>
            <i className="fa-solid fa-indian-rupee-sign"></i>
          </span>
          {props.price}
        </h5>
        <Button width="120px" marginTop="5px" btnName={props.btnName} onClick={() => props.onBuyCourseHandler(props.id)} />
      </div>
    </div>
  );
};

export default CoursePakage;
