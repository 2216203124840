import React from "react";
import style from "./index.module.scss";

const Button = (props) => {
  return (
    <button
      style={{
        height: `${props.height}`,
        width: `${props.width}`,
        textTransform: `${props.textTransform}`,
        borderRadius: `${props.borderRadius}`,
        border: `${props.border}`,
        marginTop: `${props.marginTop}`,
        fontSize: `${props.fontSize}`,
        backgroundColor: `${props.backgroundColor}`,
        color: `${props.color}`,
        marginBottom: `${props.marginBottom}`,
        padding: `${props.padding}`,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
      className={style.button}>
      {props.btnName}
    </button>
  );
};

export default Button;
