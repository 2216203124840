import React, { useState } from "react";
import style from "./index.module.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useRef } from "react";
import { useEffect } from "react";

const Select = (props) => {
  let { selectedList, select } = props;
  const [show, setShow] = useState(false);

  const selectedListHandler = (list) => {
    setShow(false);
    selectedList(list);
  };

  const onListOpen = () => {
    setShow(!show);
  };

  // ************************Close Select **********************
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(show);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div ref={wrapperRef} style={{ margin: `${props.margin}` }} onClick={onListOpen} className={style.select}>
      <div className={style.select__content} style={{ color: `${props.color}` }}>
        {!select ? props.initial : select}
        <div className={style.select__content__img}>
          <MdKeyboardArrowDown color={props.color2} />
        </div>
      </div>
      {show && (
        <div style={{ top: `${props.top}` }} className={style.select__options}>
          {props?.lists?.map((list) => (
            <p
              key={list.id}
              style={{ color: `${props.color}` }}
              onClick={() => selectedListHandler(list)}
              className={style.select__options__option}>
              {list.name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
