import React from "react";
import styles from "./index.module.scss";

const Disclaimer = () => {
  return (
    <div className={styles.disclaimer}>
      <h1>Our Disclaimer</h1>
      <p>
        The content and information presented on the adhigyanam.com website are intended solely for entertainment purposes. Any
        forecasts or messages provided should not be regarded as a substitute for advice, programs, or treatment from qualified
        professionals, including but not limited to lawyers, doctors, psychiatrists, or financial advisors. Therefore,
        adhigyanam.com does not offer any guarantees, implicit warranties, or assurances, and will not assume responsibility for
        the interpretation or utilisation of the information and data provided to recipients.
      </p>
      <p>
        It is important to note that adhigyanam.com is not a registered entity, but rather a creation of Sauntesthyam LLP. All
        transactions and collected data are and will be managed by Sauntesthyam LLP.
      </p>
    </div>
  );
};

export default Disclaimer;
