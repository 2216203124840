import React from "react";
import Styles from "./index.module.scss";
import { useState } from "react";
const CommonTab = ({ onClick, items, marginBottom, width, width2 }) => {
  const [active, setActive] = useState("telephonic_consultation");

  const tabClicked = (name, id) => {
    setActive(name);
    onClick(name, id);
  };

  return (
    <div style={{ marginBottom: `${marginBottom}`, width: `${width}` }} className={Styles.commantab}>
      {items.map((item) => (
        <span key={item.id}>
          <p
            onClick={() => tabClicked(item.name_key, item.id)}
            style={{ width: `${width2}` }}
            className={`${Styles.commantab__tabArea} ${active === item.name_key ? Styles.active : ""}`}>
            {item.name}
          </p>
        </span>
      ))}
    </div>
  );
};

export default CommonTab;
