import React from "react";
import Styles from "./index.module.scss";
import Button from "../../widgets/Button";
import noDataFound from "../../assets/noData.png";
const BloggingCard = ({ image, tittleName, tittle, description, date, onclick, name, like, comment, editor }) => {
  return (
    <div className={Styles.cardArea}>
      {editor === "1" ? (
        <div className={Styles.cardArea__ribbonbottomright}>
          <span>Editor Choice</span>
        </div>
      ) : (
        ""
      )}
      <div className={Styles.cardArea__ImgArea}>
        <img src={image ? image : noDataFound} alt="img" />
      </div>
      <div className={Styles.cardArea__detaile}>
        <p className={Styles.cardArea__detaile__smallTitle}>{tittleName}</p>
        <p className={Styles.cardArea__detaile__name}>{name}</p>
        <h2 className={Styles.cardArea__detaile__tittle}>{tittle}</h2>
        <p
          className={Styles.cardArea__detaile__paragraf}
          dangerouslySetInnerHTML={{
            __html: description,
          }}></p>
        <Button height="35px" btnName="Read More" width="auto" padding="0 10px" marginTop="10px" onClick={onclick} />
        <div className={Styles.cardArea__detaile__notification}>
          <span>{date}</span>
          <span>
            <i className="fa-regular fa-heart"></i> {like}
          </span>
          <span>
            {" "}
            <i className="fa-regular fa-comment"></i>
            {comment}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BloggingCard;
