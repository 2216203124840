import React from "react";
import style from "./index.module.scss";

const Location = () => {
  return (
    <div className={style.iframe}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.559928602812!2d80.99457509999999!3d26.885719400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be2a9d8312ae5%3A0x3ff683d965f9eb5b!2sAarohi%20Arcade%2C%20Munshi%20Pulia%2C%20Sector%2016%2C%20Indira%20Nagar%2C%20Lucknow%2C%20Uttar%20Pradesh%20226016!5e0!3m2!1sen!2sin!4v1705296278847!5m2!1sen!2sin"
        style={{ border: "0", height: "500px", width: "100%" }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
};

export default Location;
