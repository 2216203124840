import React from "react";
import Styles from "./index.module.scss";
// import ban from "../../../assets/ban.webp";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import horoscop from "../../../assets/horoscope.png";

const Banner2 = ({ aboutBan,height }) => {
  // const options = {
  //   showStatus: false,
  //   showArrows: true,
  //   showThumbs: false,
  //   autoPlay: true,
  //   interval: 2000,
  //   infiniteLoop: true,
  //   slideTransition: "ease-in",
  //   dynamicHeight: true,
  //   slideTransitionDuration: 2000,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     576: {
  //       items: 1,
  //     },
  //     768: {
  //       items: 1,
  //     },
  //     992: {
  //       items: 1,
  //     },
  //     1200: {
  //       items: 1,
  //     },
  //   },
  // };
  return (
    <div className={Styles.Banner2Area}style={{height:height}}>
      {/* <div className={Styles.Banner2Area__circleImg}>
        <img src={horoscop} alt="err" />
      </div> */}
      <div className={Styles.Banner2Area__carouselArea}>
        <div className={Styles.Banner2Area__carouselArea__banner}>
          <img src={aboutBan} alt="error" />
        </div>
        {/* <Carousel {...options}>
          <div className={Styles.Banner2Area__carouselArea__banner}>
            <img src={ban} alt="error" />
          </div>
          <div className={Styles.Banner2Area__carouselArea__banner}>
            <img src={ban} alt="error" />
          </div>
          <div className={Styles.Banner2Area__carouselArea__banner}>
            <img src={ban} alt="error" />
          </div>
        </Carousel> */}
      </div>
    </div>
  );
};

export default Banner2;
