import React from "react";
import styles from "./index.module.scss";
// import { FaLink } from "react-icons/fa6";
import dummyImg from "../../../assets/dummyImg.jpg"


const Details = ({ user, onClick, showFormHandler, onLogOut }) => {
  let d = new Date(user?.date_of_birth).getDate();
  let m = new Date(user?.date_of_birth).getMonth() + 1;
  let y = new Date(user?.date_of_birth).getFullYear();
  let dob = d + "-" + m + "-" + y;

  return (
    <div className={styles.profileArea}>
      <div className={styles.profileArea__bg}></div>
      <span className={styles.profileArea__close} onClick={onClick}>
        <i className="fa-solid fa-arrow-left"></i>
      </span>
      <div className={styles.profileArea__imgArea}>
        <div className={styles.profileArea__imgArea__emailrea}>
          <h2> {user?.name}</h2>
          <p className={styles.profileArea__imgArea__emailrea__email}>
            <i className="fa-solid fa-envelope"></i> {user?.email}
          </p>
        </div>

        <span className={styles.profileArea__imgArea__profilePic}>
          <img src={user?.profile_ic ? user?.profile_ic : dummyImg} alt="profile" />
          <span className={styles.profileArea__imgArea__profilePic__edit} onClick={showFormHandler}>
            <i className="fa-solid fa-pen-to-square"></i>
          </span>
        </span>
      </div>

      <div className={styles.profileArea__detail}>
        <p className={styles.profileArea__detail__experience}>
          <span>
            <i className="fa-solid fa-book"></i> Experience :
          </span>
          {user?.experience} Year
        </p>
        <p className={styles.profileArea__detail__experience}>
          <span>
            <i className="fa-solid fa-briefcase"></i> Speciality :
          </span>
          {user?.speacility}
        </p>
        <p className={styles.profileArea__detail__experience}>
          <span>
            <i className="fa-solid fa-user"></i> Gender :
          </span>
          {user?.gender}
        </p>
        <p className={styles.profileArea__detail__experience}>
          <span>
            <i className="fa-solid fa-language"></i> Language :
          </span>
          {user?.language}
        </p>
        <p className={styles.profileArea__detail__experience}>
          <span>
            <i className="fa-solid fa-phone"></i> Mobile No :
          </span>
          {user?.contact_no}
        </p>
        <p className={styles.profileArea__detail__experience}>
          <span>
            <i className="fa-solid fa-calendar-days"></i> Date of Birth :
          </span>
          {dob}
        </p>
        {/* <p className={styles.profileArea__detail__experience}>
          <span>
            <i className="fa-solid fa-link"></i> Social Link :
          </span>
          <a href={user?.external_link} target="blank">
            <button>
              Link <FaLink />
            </button>
          </a>
        </p> */}
        <div className={styles.profileArea__detail__buttonArea}>
          <button className={styles.profileArea__detail__buttonArea__logout} onClick={onLogOut}>
            <span>
              <i className="fa-solid fa-right-from-bracket"></i>
            </span>{" "}
            LogOut
          </button>
        </div>
      </div>
    </div>
  );
};

export default Details;
