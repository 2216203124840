import React from "react";
import style from "./index.module.scss";

const InputFieldWithIcon = (props) => {
  return (
    <>
      <label style={{ color: `${props.color}`, textTransform: `${props.textTransform}` }} htmlFor={props.id}>
        {props.label}
      </label>
      <div style={{ borderColor: `${props.borderColor}` }} className={style.input}>
        <div onClick={props.onDecrement} style={{ display: `${props.display}` }} className={style.input__img1}>
          <img style={{ height: `${props.height}`, width: `${props.width}` }} src={props.src1} alt={props.alt} />
        </div>
        <input
          style={{
            padding: `${props.padding}`,
            borderRadius: `${props.borderRadius}`,
            textAlign: `${props.textAlign}`,
            marginBottom: `${props.marginBottom}`,
          }}
          type={props.type}
          id={props.id}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          value={props.value}
          autoComplete="off"
        />
        <div onClick={props.onIncrement} style={{ display: `${props.display2}` }} className={style.input__img2}>
          <img style={{ height: `${props.height}`, width: `${props.width}` }} src={props.src2} alt={props.alt} />
        </div>
      </div>
      <span className={style.guid}>{props.guide}</span>
    </>
  );
};

export default InputFieldWithIcon;
