import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicRoute from "./routings/PublicRoute";
import Home from "./pages/Home";
import Course from "./pages/Course";
import TestimonialPage from "./pages/TestimonialPage";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import BookPanditJi from "./pages/BookPanditJi";
import AstrologerList from "./pages/AstrologerList";
import ChoosePredictionType from "./modal/ChoosePredictionType";
import BookSlot from "./modal/BookSlot";
import PanditJiModal from "./modal/PanditJiModal";
import IndividualConsultation from "./pages/IndividualConsultation";

import IndividualConsultationForm from "./pages/IndividualConsultationForm";
import AstrologerDetails from "./pages/AstrologerDetails";
import CustomizeSlot from "./modal/CustomizeSlot";
import GraphologyForm from "./pages/GraphologyForm";
import Graphology from "./pages/Graphology";
import VastuAnalysis from "./pages/VastuAnalysis";
import MatchMaking from "./pages/MatchMaking";
import MarriedLifeAnalysis from "./pages/MarriedLifeAnalysis";
import BirthTimeRectification from "./pages/BirthTimeRectification";
import KundliReport from "./pages/KundaliReport";
import TaroCardReading from "./pages/TarotCardReading";
import PalmReading from "./pages/PalmReading";
import PalmReadingForm from "./pages/PalmReadingForm";
import Bookings from "./pages/Bookings";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourseVideo from "./pages/CourseVideo";
import NotFound from "./pages/NotFound";
import PageNotFound from "./routings/404";
import TermsAndConditions from "./components/TermsAndConditions";
import Disclaimer from "./components/Disclaimer";
import PricingPolicy from "./components/PricingPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import HoroscopeDetail from "./pages/HoroscopeDetail";
import Blogging from "./pages/Blogging";
import Numrology from "./pages/Numrology";
import { Context } from "./utils/ContextStor";
import { useEffect, useState } from "react";
import FaceReading from "./pages/FaceReading";
import ThankYouPopUp from "./components/Thank-You-PopUp";
import axios from "axios";
import SignatureAnalysis from "./pages/SignatureAnalysis";
import PaymentFailed from "./pages/PaymentFailed";
import FreeKundli from "./pages/FreeKundli";
import PoojaDetails from "./pages/BookPanditJi/PoojaDetails";
import PurchaseVideo from "./pages/PurchaseVideo";
import CourseThankYou from "./components/Course-ThankYou";
import RegisterDoubtSession from "./pages/PurchaseVideo/RegisterDoubtSession";
import MyCourses from "./pages/MyCoursesDetail";
import MyCoursesVideos from "./pages/MyCoursesDetail/MyCoursesVideos";
import BlogDetail from "./pages/BlogDetail";
import FeedBack from "./pages/FeedBackPage";
function App() {
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const [isLog, setIsLog] = useState(false);

  const [token, setToken] = useState();
  const [updatedUser, setUpdatedUser] = useState();

  // ***************************** Get User Data ********************************
  // useEffect(() => {
  //   // eslint-disable-next-line
  // }, [token]);

  useEffect(() => {
    if (localStorage.getItem("jsonData")) {
      const cData = atob(localStorage.getItem("jsonData"));

      // const cData = atob(
      //   "eyJzdGF0dXMiOnRydWUsImRhdGEiOnsiaWQiOjE3MCwibmFtZSI6Ik1EIElNUkFOIEFOU0FSSSIsImVtYWlsIjoiaW1yYW5mcm9udGVuZDIwMjNAZ21haWwuY29tIiwiZ2VuZGVyIjoiIiwiZGF0ZV9vZl9iaXJ0aCI6IjE5OTctMDMtMDIiLCJ0aW1lX29mX2JpcnRoIjpudWxsLCJwbGFjZV9vZl9iaXJ0aCI6bnVsbCwiaXNfYWN0aXZlIjoiMSIsImNvbnRhY3Rfbm8iOm51bGwsImxhbmd1YWdlIjpudWxsLCJzcGVjaWFsaXR5IjoiTkEiLCJleHBlcmllbmNlIjpudWxsLCJ1c2VyX3R5cGUiOiJVc2VyIiwicHJvZmlsZV9pYyI6Imh0dHBzOlwvXC9kZXYuYWRoaWd5YW5hbS5jb21cL2FkbWluXC9wdWJsaWNcLyIsImdvb2dsZV9pbWFnZSI6Imh0dHBzOlwvXC9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tXC9hXC9BQ2c4b2NMSHlaWGtJd2NERFB6U0VUNEFkV2l4RWVWeENkOE1JT0g3UEkwLWpTN2c9czk2LWMiLCJ0b2tlbiI6ImV5SjBlWEFpT2lKS1YxUWlMQ0poYkdjaU9pSklVekkxTmlKOS5leUpwYzNNaU9pSm9kSFJ3Y3pvdkwyUmxkaTVoWkdocFozbGhibUZ0TG1OdmJTOWhaRzFwYmk5allXeHNZbUZqYXk5bmIyOW5iR1VpTENKcFlYUWlPakUzTVRFNU5UVXdOVE1zSW1WNGNDSTZNVGN4TnpFek9UQTFNeXdpYm1KbUlqb3hOekV4T1RVMU1EVXpMQ0pxZEdraU9pSllZVXhHVGpSTFdtdElXamxUVmtVNUlpd2ljM1ZpSWpvaU1UY3dJaXdpY0hKMklqb2lZVFJsWW1SbE1qaG1PR001Tnpnd016UTBOV1V3T0dJeVlUTTROVFkyTVRsbE1HSTJNemd4TUNKOS5NdEJaUXFjUWJGYnduam1OV2Z1VzNwRVo3NHdnMTJtSktwS1lQcHNUQzk0In0sIm1lc3NhZ2UiOiJEZXRhaWxzIEZldGNoZWQgU3VjY2Vzc0Z1bGx5In0="
      // );
      const gmailData = JSON.parse(cData);
      const newUserData = {
        name: gmailData?.data?.name,
        email: gmailData?.data?.email,
        token: gmailData?.data?.token,
        profile_ic: gmailData?.data?.google_image,
        user_type: gmailData?.data?.user_type,
        speciality: gmailData?.data?.speciality,
        experience: gmailData?.data?.experience,
        id: gmailData?.data?.id,
        date_of_birth: gmailData?.data?.date_of_birth,
        place_of_birth: gmailData?.data?.place_of_birth,
        time_of_birth: gmailData?.data?.time_of_birth,
        gender: gmailData?.data?.gender,
        contact_no: gmailData?.data?.contact_no,
        language: gmailData?.data?.language,
      };
      setToken(gmailData?.data?.token);
      setUpdatedUser(newUserData);
      localStorage.setItem("token", gmailData?.data?.token);
      localStorage.setItem("user", JSON.stringify(newUserData));
    } else {
      // if (token) {
      const getUser = {
        url: `https://admin.adhigyanam.com/api/profile-get/${userDetails?.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userDetails?.token}`,
        },
      };
      axios(getUser)
        .then((res) => {
          const filteredData = {
            name: res.data.data[0].name,
            email: res.data.data[0].email,
            profile_ic: res.data.data[0].profile_pic,
            user_type: res.data.data[0].user_type,
            speacility: res.data.data[0].speacility,
            experience: res.data.data[0].experience,
            id: res.data.data[0].id,
            date_of_birth: res.data.data[0].date_of_birth,
            place_of_birth: res.data.data[0].place_of_birth,
            time_of_birth: res.data.data[0].time_of_birth,
            gender: res.data.data[0].gender,
            contact_no: res.data.data[0].contact_no,
            language: res.data.data[0].language,
            no_of_consultations: res.data.data[0].no_of_consultations,
            is_active: res.data.data[0].is_active,
            external_link: res.data.data[0].external_link,
          };
          setUpdatedUser(filteredData);
        })
        .catch((err) => {
          // console.log(err)
        });
      // }
    }

    // eslint-disable-next-line
  }, [isLog]);

  //Start Avoid Right Click

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  document.onkeydown = function (e) {
    if (e.key === "F12") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      return false;
    }
    if (e.ctrlKey && e.key === "u") {
      return false;
    }
  };

  //Start Avoid Right Click

  return (
    <>
      <Context.Provider value={[token, setToken, updatedUser, setUpdatedUser, isLog, setIsLog]}>
        <Router>
          <Routes>
            {userDetails?.user_type === "Astrologer" || userDetails?.user_type === 1 ? (
              <>
                <Route path="/" element={<PublicRoute element={<AstrologerDetails />} />} />
                <Route path="/customize-slot" element={<PublicRoute element={<CustomizeSlot />} />} />
                <Route path="/contact-us" element={<PublicRoute element={<ContactUs />} />} />
                <Route path="/terms-and-conditions" element={<PublicRoute element={<TermsAndConditions />} />} />
                <Route path="/disclaimer" element={<PublicRoute element={<Disclaimer />} />} />
                <Route path="/pricing-policy" element={<PublicRoute element={<PricingPolicy />} />} />
                <Route path="/privacy-policy" element={<PublicRoute element={<PrivacyPolicy />} />} />
                <Route path="/my-bookings" element={<PublicRoute element={<Bookings />} />} />
                <Route path="*" element={<PageNotFound element={<NotFound />} />} />
                <Route path="/gmail-login/:data" element={<PublicRoute element={<AstrologerDetails />} />} />
              </>
            ) : (
              <>
                {/* <Route path="/" element={<Navigate replace to="/home" />} /> */}
                <Route path="/" element={<PublicRoute element={<Home />} />} />
                <Route path="/gmail-login/:data" element={<PublicRoute element={<Home />} />} />
                <Route path="/course" element={<PublicRoute element={<Course />} />} />
                <Route path="/blogging" element={<PublicRoute element={<Blogging />} />} />
                <Route path="/blogging-details" element={<PublicRoute element={<BlogDetail />} />} />
                <Route path="/feedback" element={<PublicRoute element={<TestimonialPage />} />} />
                <Route path="/contact-us" element={<PublicRoute element={<ContactUs />} />} />
                <Route path="/our-story" element={<PublicRoute element={<AboutUs />} />} />
                <Route path="/book-pandit-ji" element={<PublicRoute element={<BookPanditJi />} />} />
                <Route path="/book-pandit-ji/pooja-details" element={<PublicRoute element={<PoojaDetails />} />} />
                <Route path="astrology/:id/:name/:type/type1/book-slot" element={<PublicRoute element={<BookSlot />} />} />
                <Route path="astrology/:id/:name/:type/type2/book-slot" element={<PublicRoute element={<BookSlot />} />} />
                <Route path="astrology/:id/:name/:type/type3/book-slot" element={<PublicRoute element={<BookSlot />} />} />
                <Route path="astrology/:id/:name/:type/type4/book-slot" element={<PublicRoute element={<BookSlot />} />} />
                <Route path="astrology/:id/:name/:type/type5/book-slot" element={<PublicRoute element={<BookSlot />} />} />
                <Route path="/astrology/:id/:name" element={<PublicRoute element={<AstrologerList />} />} />
                <Route path="/choose-prediction-type" element={<PublicRoute element={<ChoosePredictionType />} />} />
                <Route path="/pandit-ji-book-slot" element={<PublicRoute element={<PanditJiModal />} />} />
                <Route path="astrology/:id/:name/:type/type1" element={<PublicRoute element={<IndividualConsultation />} />} />
                <Route path="astrology/:id/:name/:type/type1/book-slot/form" element={<PublicRoute element={<IndividualConsultationForm />} />} />
                {/* <Route path="/login" element={<PublicRoute element={<Login />} />} />
            <Route path="/signup" element={<PublicRoute element={<SignUp />} />} /> */}
                <Route path="/astrology/:id/:name/graphology-details" element={<PublicRoute element={<Graphology />} />} />
                <Route path="/astrology/:id/:name/vastu-analysis-form" element={<PublicRoute element={<VastuAnalysis />} />} />
                <Route path="astrology/:id/:name/:type/type2" element={<PublicRoute element={<MatchMaking />} />} />
                <Route path="astrology/:id/:name/:type/type3" element={<PublicRoute element={<MarriedLifeAnalysis />} />} />
                <Route path="astrology/:id/:name/:type/type4" element={<PublicRoute element={<BirthTimeRectification />} />} />
                <Route path="astrology/:id/:name/:type/type5" element={<PublicRoute element={<KundliReport />} />} />
                <Route path="/astrology/:id/:name/tarot-card-reading-form" element={<PublicRoute element={<TaroCardReading />} />} />
                <Route path="/astrology/:id/:name/palmistry-details" element={<PublicRoute element={<PalmReading />} />} />
                <Route path="/astrology/:id/:name/palmistry-details/palm-reading-form" element={<PublicRoute element={<PalmReadingForm />} />} />
                <Route path="/astrology/:id/:name/graphology-details/graphology-form" element={<PublicRoute element={<GraphologyForm />} />} />
                <Route path="/course/:id" element={<PublicRoute element={<CourseVideo />} />} />
                <Route path="/course/:id/purchase-video" element={<PublicRoute element={<PurchaseVideo />} />} />
                <Route path="/course/:id/purchase-video/register-doubt-session" element={<PublicRoute element={<RegisterDoubtSession />} />} />
                <Route path="/astrology/:id/:name/face-reading-form" element={<PublicRoute element={<FaceReading />} />} />
                <Route path="/astrology/:id/:name/signature-analysis-form" element={<PublicRoute element={<SignatureAnalysis />} />} />

                <Route path="*" element={<PageNotFound element={<NotFound />} />} />
                <Route path="/terms-and-conditions" element={<PublicRoute element={<TermsAndConditions />} />} />
                <Route path="/disclaimer" element={<PublicRoute element={<Disclaimer />} />} />
                <Route path="/pricing-policy" element={<PublicRoute element={<PricingPolicy />} />} />
                <Route path="/privacy-policy" element={<PublicRoute element={<PrivacyPolicy />} />} />
                <Route path="/horoscope/:id" element={<PublicRoute element={<HoroscopeDetail />} />} />
                <Route path="/astrology/4/:name" element={<PublicRoute element={<Numrology />} />} />
                <Route path="/thank-you" element={<PublicRoute element={<ThankYouPopUp />} />} />
                <Route path="/payment-failed" element={<PublicRoute element={<PaymentFailed />} />} />
                <Route path="/free-kundli" element={<PublicRoute element={<FreeKundli />} />} />
                <Route path="/course/thank-you/:transactionId" element={<PublicRoute element={<CourseThankYou />} />} />
                <Route path="/my-courses" element={<PublicRoute element={<MyCourses />} />} />
                <Route path="/my-courses/courses-videos" element={<PublicRoute element={<MyCoursesVideos />} />} />
                <Route path="/feedbackform" element={<PublicRoute element={<FeedBack />} />} />
              </>
            )}
          </Routes>
        </Router>
      </Context.Provider>
      <ToastContainer style={{ zIndex: 999999999999999, height: "200px" }} autoClose={5000} />
    </>
  );
}

export default App;
