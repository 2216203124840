import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import style from "./index.module.scss";
import Loading from "../../../components/Loading";

const socialObj = [
  { id: 1, icon: faTwitter, link: "https://twitter.com/TheIndianAstro?t=-FXDLj4CYrIMYyDaBoG1nA&s=08" },
  { id: 2, icon: faFacebookF, link: "https://www.facebook.com/TheIndianAstrology?mibextid=ZbWKwL" },
  { id: 4, icon: faInstagram, link: "https://instagram.com/the_vedic_astro?igshid=MzRlODBiNWFlZA==" },
];

const About = ({ showLoder, about }) => {
  return (
    <>
      {showLoder && <Loading />}
      <div className={style.about}>
        <div dangerouslySetInnerHTML={{ __html: about }} />

        <div className={style.about__bottom}>
          <ul className={style.about__bottom__ul}>
            {socialObj.map((item) => {
              return (
                <li key={item.id} className={style.about__bottom__ul__li}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={item.icon} />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default About;
