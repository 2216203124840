import { useLocation, useNavigate, useParams } from "react-router-dom";
// import Footer from "../../components/Footer";
// import Header from "../../components/Header";
// import Navbar from "../../components/Navbar";
import React, { Suspense, useContext, useEffect } from "react";
import { useState } from "react";
import Modal from "../../modal";
import Login from "../../modal/Login";
import SignUp from "../../modal/SignUp";
import { useCallback } from "react";
import { ToastContainer } from "react-toastify";
import CommonDropDownBtn from "../../components/Common-Dropdown-Btn";
import { Context } from "../../utils/ContextStor";
import UserProfile from "../../components/UserProfile";
import swal from "sweetalert";
import Loading from "../../components/Loading";
import useDevToolsDetect from "../../hooks/useDevtoolDetect";
// import { initializeApp } from "firebase/app";
// import { getFirestore, collection, addDoc, serverTimestamp, getDocs, query } from "firebase/firestore";
// import ChatButton from "../../components/ChatButton";
// import Chat from "../../pages/Chat";

const Footer = React.lazy(() => import("../../components/Footer"));
const Navbar = React.lazy(() => import("../../components/Navbar"));
const Header = React.lazy(() => import("../../components/Header"));

const PublicRoute = ({ element }) => {
  // const { isOpen, orientation } = useDevToolsDetect();
  // eslint-disable-next-line
  // const isDevtoolOpen = useDevToolsStatus();
  // const [devToolsOpen, setDevToolsOpen] = useState(false);
  // eslint-disable-next-line
  const [token, setToken, updatedUser, setUpdatedUser] = useContext(Context);
  // const [allMessages, setAllMessages] = useState([]);
  const [modal, setModal] = useState(false);
  // const [chat, setChat] = useState(false);
  const [signup, setSignup] = useState(false);
  const [profile, setProfile] = useState(false);

  const navigate = useNavigate();

  const onLoginForm = () => {
    setModal(true);
  };
  const onCloseHandler = () => {
    setModal(false);
    setSignup(false);
  };

  const onSignupHandler = () => {
    setModal(false);
    setSignup(true);
  };

  const onCancelSignUp = () => {
    setModal(true);
    setSignup(false);
  };

  const param = useParams();
  const { pathname } = useLocation();

  if (pathname.includes("gmail-login")) {
    localStorage.setItem("jsonData", param.data);

    // <Navigate to="/" replace={true} />;
    navigate("/", { replace: true });
  }

  useEffect(() => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    scrollToTop();
  }, [pathname]);

  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setModal(false);

    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speacility: userDetails.speacility,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.gender,
      contact_no: userDetails.contact_no,
      language: userDetails.language,
      is_active: userDetails.is_active,
    };

    localStorage.setItem("user", JSON.stringify(filteredData));
    localStorage.setItem("profilePicture", JSON.stringify(userDetails.profile_ic));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);
    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onlogoutHandler = (data) => {
    swal("Successful!", data === "devtool" ? "Close Devtools & Login Again" : "Logout Successfully!", data === "devtool" ? "warning" : "success");

    setToken(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("jsonData");
    setUpdatedUser("");
    navigate("/");
    setProfile(false);
  };

  // useEffect(() => {
  //   if (isDevtoolOpen) {
  //     // onlogoutHandler("devtool");
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line
  // }, [pathname, isDevtoolOpen, navigate]);

  // useEffect(() => {
  //   const handleDevToolsChange = (e) => {
  //     // console.log("DevTools status changed:", e.detail);
  //   };

  //   window.addEventListener("devtoolschange", handleDevToolsChange);
  //   return () => {
  //     window.removeEventListener("devtoolschange", handleDevToolsChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isOpen) {
  //     onlogoutHandler("devtool");
  //   }
  // }, [isOpen, navigate, pathname]);

  // const detectDevTools = () => {
  //   let devtools = { open: false };
  //   const threshold = 100;
  //   const before = performance.now();
  //   const after = performance.now();
  //   devtools.open = after - before > threshold;

  //   if (devtools.open) {
  //     console.warn("DevTools is open! Taking action...");
  //   }
  // };

  // setInterval(detectDevTools, 100);

  useEffect(() => {
    // if (
    //   pathname === "/astrology/1/Astrology/Individual/type1" ||
    //   pathname === "/astrology/1/Astrology/Individual/type1/book-slot" ||
    //   pathname === "/astrology/1/Astrology/Individual/type1/book-slot/form"
    // ) {
    // } else {
    //   localStorage.removeItem("allConsultationData");
    // }
    // if (
    //   pathname === "/astrology/3/Palmistry/palmistry-details" ||
    //   pathname === "/astrology/3/Palmistry/palmistry-details/palm-reading-form"
    // ) {
    // } else {
    //   localStorage.removeItem("allConsultationDataForPalm");
    // }
    // if (
    //   pathname === "/astrology/7/Handwriting Analysis/graphology-details" ||
    //   pathname === "/astrology/7/Handwriting Analysis/graphology-details/graphology-form"
    // ) {
    // } else {
    //   localStorage.removeItem("allGraphologyData");
    // }
    // if (pathname === "/book-pandit-ji" || pathname === "/book-pandit-ji/pooja-details") {
    // }
    if (pathname === "/") {
      localStorage.removeItem("poojaDetails");
      localStorage.removeItem("allGraphologyData");
      localStorage.removeItem("allConsultationDataForPalm");
      localStorage.removeItem("allConsultationData");
    }
  }, [pathname]);

  const onShoProfile = () => {
    if (profile === false) {
      setProfile(true);
    } else {
      setProfile(false);
    }
  };
  const onCloseProfile = () => {
    setProfile(false);
  };

  let user = JSON.parse(localStorage.getItem("user"));

  // const firebaseConfig = {
  //   apiKey: "AIzaSyC4FFXg1DGiQ6Vx_FViSg2M3J9IgRDlkh8",
  //   authDomain: "adhigyanam-chat-app.firebaseapp.com",
  //   databaseURL: "https://adhigyanam-chat-app-default-rtdb.firebaseio.com",
  //   projectId: "adhigyanam-chat-app",
  //   storageBucket: "adhigyanam-chat-app.appspot.com",
  //   messagingSenderId: "866917208288",
  //   appId: "1:866917208288:web:3ed9f3a1c1c5da85498085",
  // };

  // const app = initializeApp(firebaseConfig);
  // const db = getFirestore(app);

  // const getMessages = async () => {
  //   try {
  //     // Reference to the "messages" collection within the specified chat room
  //     const messagesRef = collection(db, "chat-rooms", `${updatedUser?.id}+${290}`, "messages");
  //     const messageQuery = query(messagesRef);

  //     // Retrieve documents from the collection
  //     const querySnapshot = await getDocs(messageQuery);
  //     // console.log(querySnapshot.docs);

  //     const fetchedMessages = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  //     // console.log(fetchedMessages);
  //     setAllMessages(fetchedMessages);
  //   } catch (error) {
  //     console.error("Error getting messages: ", error);
  //   }
  // };

  // getMessages();

  // const openChat = () => {
  //   setChat(true);
  // };

  // const onCloseChat = () => {
  //   setChat(false);
  // };

  return (
    <>
      <Suspense fallback={<Loading />}>
        {profile && <UserProfile show={setProfile} onClick={onCloseProfile} onLogOut={onlogoutHandler} />}
        {modal && (
          <Modal onClose={onCloseHandler}>
            <Login onSignupHandler={onSignupHandler} onLoginHandler={onLogin} onCloseHandler={onCloseHandler} />
          </Modal>
        )}
        {signup && (
          <Modal onClose={onCloseHandler}>
            <SignUp setSignup={setSignup} onCloseHandler={onCloseHandler} onCancelSignUp={onCancelSignUp} />
          </Modal>
        )}
        <Header token={token} onClick={onLoginForm} onlogoutHandler={onlogoutHandler} onShoProfile={onShoProfile} />
        {user?.user_type !== "Astrologer" && user?.user_type !== 1 && <Navbar />}
        {element}
        <CommonDropDownBtn />
        {/* {chat && <Chat onCloseChat={onCloseChat} db={db} allMessages={allMessages} />}
        <ChatButton openChat={openChat} /> */}
        <Footer />
        <ToastContainer style={{ height: "200px" }} />
      </Suspense>
    </>
  );
};

export default PublicRoute;
