import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import Loading from "../../../components/Loading";
import VideoCard from "../../PurchaseVideo/VideoCard";
import { Rating } from "@mui/material";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import axios from "axios";
import noDataFoundImg from "../../../assets/noData.png";

const MyCoursesVideos = () => {
  const [courseDetails, setCourseDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [vLoding, setVLoding] = useState(true);
  const [videos, setVideos] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();
  let user = JSON.parse(localStorage.getItem("user"));
  let token = user.token;
  // *********************************Avoid Right Click**************************************
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  document.onkeydown = function (e) {
    if (e.key === "F12") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "I") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === "J") {
      return false;
    }
    if (e.ctrlKey && e.key === "u") {
      return false;
    }
  };
  //  ******************************* Get Pakage ***************************
  useEffect(() => {
    setLoading(true);
    const getConfig = {
      url: `https://admin.adhigyanam.com/api/getVideoSubscriptionById/${location?.state?.courseId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          setCourseDetails(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err)
        setLoading(false);
      });
  }, [token, location?.state?.courseId]);
  //  ******************************* Get Pakage ***************************
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("video_id", courseDetails.package_id);
    const getVideos = {
      url: "https://admin.adhigyanam.com/api/course-details",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      },
      data: formdata,
    };
    axios(getVideos)
      .then((res) => {
        setVideos(res.data.data);
        setVLoding(false);
      })
      .catch((err) => {
        // console.log(err)
      });

    // eslint-disable-next-line
  }, [courseDetails]);
  // ***********************************************************
  const OnSelectVideoHandler = (vido, tittle, description) => {
    setVideoUrl(vido);
    setTitle(tittle);
    setDescription(description);
  };
  return (
    <>
      <div className={style.courseDetails__bannerArea}>
        {loading ? (
          <Loading />
        ) : (
          <div className={style.courseDetails__bannerArea__container}>
            <p>
              Adhigyanam Courses <i className="fa-solid fa-angle-right"></i>{" "}
              <span>
                Category <i className="fa-solid fa-angle-right"></i>
              </span>
              <span>{courseDetails.course_package_title}</span>
            </p>
            <h1>{courseDetails.course_package_title}</h1>
            <h4>{courseDetails.course_package_subtitle}</h4>
            <h5>
              Tought By : <span>{courseDetails.course_package_taught_by}</span>
            </h5>
            <h5>
              Language : <span>{courseDetails.course_package_language}</span>
            </h5>
            <div className={style.courseDetails__bannerArea__container__rattingArea}>
              <span>
                <Rating
                  name="half-rating-read"
                  defaultValue={courseDetails?.course_package_ratings ? courseDetails?.course_package_ratings : "0"}
                  precision={0.5}
                  readOnly
                />
              </span>
              <span>({courseDetails?.course_package_rating_count})</span>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className={style.courseDetails}>
            <div className={style.courseDetails__detailsArea}>
              <div className={style.courseDetails__detailsArea__title}>
                <h1>Your Videos</h1>
                <p className={style.courseDetails__detailsArea__title__arrowTitle}></p>
              </div>
              <div className={style.courseDetails__detailsArea__videoArea}>
                <div className={style.courseDetails__detailsArea__videoArea__videoCard}>
                  <div className={style.courseDetails__detailsArea__videoArea__videoCard__video}>
                    <ReactPlayer
                      playing={true}
                      controls={true}
                      config={{
                        file: { attributes: { controlsList: "nodownload" } },
                      }}
                      width="100%"
                      height="100%"
                      url={videoUrl ? videoUrl : courseDetails?.course_package_demo_video}
                    />
                  </div>
                  <div className={style.courseDetails__detailsArea__videoArea__videoCard__detailArea}>
                    <h2>{title ? title : courseDetails.course_package_title}</h2>
                    <p>{description ? description : courseDetails.course_package_subtitle}</p>
                    <span>
                      <Rating
                        name="half-rating-read"
                        defaultValue={courseDetails.course_package_ratings ? courseDetails.course_package_ratings : "0"}
                        precision={0.5}
                        readOnly
                      />
                    </span>
                  </div>
                </div>
                <div className={style.courseDetails__detailsArea__videoArea__priceArea}>
                  <>
                    {vLoding ? (
                      <div className={style.vLoding}>
                        <Loading />
                      </div>
                    ) : (
                      <>
                        {videos.length <= 0 ? (
                          <span className={style.emptyMsg}>
                            <img src={noDataFoundImg} alt="err" />
                          </span>
                        ) : (
                          <>
                            {videos.map((item) => {
                              return (
                                <VideoCard
                                  key={item.id}
                                  videoDate={item.video_date}
                                  tittle={item.title}
                                  description={item.description}
                                  onClick={() => OnSelectVideoHandler(item.video_url, item.title, item.description)}
                                  ratting={courseDetails.course_package_ratings ? courseDetails.course_package_ratings : "0"}
                                />
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>

              <div className={style.courseDetails__detailsArea__descriptionArea}>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> Description{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails.course_package_description,
                    }}
                  />
                </div>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> What You Will Learn{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails.course_package_what_you_will_learn,
                    }}
                  />
                </div>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> What You Will Get{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails.course_package_what_you_will_get,
                    }}
                  />
                </div>
                <div className={style.courseDetails__detailsArea__descriptionArea__cardArea}>
                  <h1>
                    <i className="fa-solid fa-book-tanakh"></i> Course Content{" "}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseDetails?.course_content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MyCoursesVideos;
