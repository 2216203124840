import React from "react";
import Styles from "./index.module.scss";
import AstroButton from "../../pages/AstrologerList/AstroButton";

const AstroCard = ({ ratting, img, name, exprience, detail, onClick, onDetailHandler }) => {
  return (
    <div className={Styles.astroCardArea}>
      <div className={Styles.astroCardArea__ratting}>
        {/* <span>
          <img src={star} alt="error" />
        </span>
        <p>{ratting}</p> */}
      </div>
      <div className={Styles.astroCardArea__astroImage}>
        <img src={img} alt="error" />
      </div>
      <div className={Styles.astroCardArea__astroDetail}>
        <h3 className={Styles.astroCardArea__astroDetail__name}>{name}</h3>
        <span className={Styles.astroCardArea__astroDetail__exprience}>Exp : {exprience} years</span>
        <p className={Styles.astroCardArea__astroDetail__details}>(HINDI)</p>
        <p className={Styles.astroCardArea__astroDetail__details}>{detail}</p>
      </div>
      <p className={Styles.astroCardArea__seemore} onClick={onDetailHandler}>
        About Us.
        <span>
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </p>
      <div className={Styles.astroCardArea__button}>
        <AstroButton onClick={onClick} />
      </div>
    </div>
  );
};

export default AstroCard;
