import React from "react";
import Styles from "./index.module.scss";
import individual from "../../assets/individual.png";
import X from "../../assets/x-png.jpg";
import whatsapp from "../../assets/whatsapp.png";
import phone from "../../assets/phone2.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const CommonDropDownBtn = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const showDropDown = () => {
    setShow(false);
  };
  const CloseDropDown = () => {
    setShow(true);
  };
  const onContactUsHandler = () => {
    navigate("/contact-us");
  };
  return (
    <>
      {show ? (
        <div className={Styles.dropBtn} onClick={showDropDown}>
          <p>Connect With Us</p>
          <img src={individual} alt="error" />
        </div>
      ) : (
        <div className={Styles.dropDown}>
          {/* <div className={Styles.dropDown__drop1}>
            <p>Follow Us</p>
            <div className={Styles.dropDown__drop1__image}>
              <a href="https://instagram.com/the_vedic_astro?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                <img src={insta} alt="error" />
              </a>
            </div>
          </div> */}

          <div className={Styles.dropDown__drop1}>
            <p>Chat with Us</p>
            <a href="https://wa.me/+918960708425">
              <div className={Styles.dropDown__drop1__imageC}>
                <img src={whatsapp} alt="error" />
              </div>
            </a>
          </div>
          <div className={Styles.dropDown__drop1}>
            <p>Contact Us</p>
            <div className={Styles.dropDown__drop1__imageC} onClick={onContactUsHandler}>
              <img src={phone} alt="error" />
            </div>
          </div>
          <div className={Styles.dropDown__drop1}>
            <div className={Styles.dropDown__drop1__imageX} onClick={CloseDropDown}>
              <img src={X} alt="error" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommonDropDownBtn;
