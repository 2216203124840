import React from "react";
import styles from "./index.module.scss";

const Incoming = ({ incoming, iTime }) => {
  return (
    <div className={styles.incoming}>
      <p>{incoming}</p>
      <p className={styles.incoming__time}>{iTime}</p>
    </div>
  );
};

export default Incoming;
