import React from "react";
import style from "./index.module.scss";

const CustomeBanner = (props) => {
  return (
    <div className={style.courseBanner} style={{ backgroundImage: `url(${props.bgImage})`, height: `${props.height}` }}>
      <div style={{ width: props.width, textAlign: props.textAlign }} className={style.courseBanner__left}>
        <h2>{props.title}</h2>
        <p>{props.desc}</p>
      </div>
    </div>
  );
};

export default CustomeBanner;
