import React from "react";
import close from "../../assets/x.png";
import style from "./index.module.scss";

const Close = (props) => {
  return (
    <div className={style.close} onClick={props.onCloseHandler}>
      <img src={close} alt="close" />
    </div>
  );
};

export default Close;
