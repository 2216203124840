import React from "react";
import Style from "./index.module.scss";
const CommonSelect = ({ list, label, onChange, value, marginTop, marginBottom, margin }) => {
  return (
    <div className={Style.selectArea} style={{ margin: `${margin}` }}>
      <label htmlFor={label}>{label}</label>
      <select
        style={{ marginTop: `${marginTop}`, marginBottom: `${marginBottom}` }}
        className={Style.selectArea__select}
        value={value}
        name={label}
        id={label}
        onChange={(e) => onChange(e, label)}>
        {list.map((item) => {
          return <option value={item?.name}>{item?.name}</option>;
        })}
      </select>
    </div>
  );
};

export default CommonSelect;
