import React from "react";
import Styles from "./index.module.scss";
const KundliCard = (props) => {
  return (
    <div className={Styles.kundliCard} onClick={props.onClick}>
      <div className={Styles.kundliCard__cardName}>
        <h3>{props.name}</h3>
      </div>
      <div className={Styles.kundliCard__cardImage}>
        <img src={props.src} alt="error" />
      </div>
      <p>{props.description}</p>
    </div>
  );
};

export default KundliCard;
