import React from "react";
import Styles from "./index.module.scss";
import failed from "../../assets/newassets/failed.gif";
import Button from "../../widgets/Button";
import { useNavigate } from "react-router-dom";
const PaymentFailed = () => {
  const naviget = useNavigate();
  const onRedirectHandler = () => {
    naviget("/");
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.container__popupArea}></div>
      <div className={Styles.container__messageArea}>
        <div className={Styles.container__messageArea__img}>
          <img src={failed} alt="success" />
        </div>
        <h2>Sorry!</h2>
        <p>Payment Failed!</p>
        <p>Please Try Again!</p>
        <Button onClick={onRedirectHandler} btnName=" Home" width="150px" fontSize="18px" marginBottom="5px" marginTop="5px" />
      </div>
    </div>
  );
};

export default PaymentFailed;
