import React, { useCallback, useContext } from "react";
import Styles from "./index.module.scss";
import tittleImg from "../../assets/newassets2/Astrology assets/numro.png";
import PlanList from "../../components/PlanList";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import CommonTab from "../../components/CommanTab";
import CommonInputField from "../../widgets/CommonInputField";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { endpoints } from "../../utils/Endpoints";
import { useEffect } from "react";
import axios from "axios";
import Button from "../../widgets/Button";
import Loading from "../../components/Loading";
import Modal from "../../modal";
import BookSlot from "../../modal/BookSlot";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneNumber from "../../components/PhoneInput";
import CommonSelect from "../../components/CommonSelect";
import swal from "sweetalert";
import SignUp from "../../modal/SignUp";
import Login from "../../modal/Login";
import { Context } from "../../utils/ContextStor";
import noDataFoundImg from "../../assets/noData.png";
import DragAndDrop from "../../components/DragAndDrop";
import pointer from "../../assets/pointer.webp";
import { loadScript } from "../../helpers/razorpay";
import ModalLayout from "../../components/ModalLayout";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import closeImg from "../../assets/close.svg";
import Logo from "../../assets/LOGO.webp";
const genderList = [
  { id: 1, name: "Select" },
  { id: 2, name: "Male" },
  { id: 3, name: "Female" },
  { id: 4, name: "Other" },
];
const languageList = [
  { id: 1, name: "Select" },
  { id: 2, name: "Hindi" },
  { id: 3, name: "English" },
];

const SignatureAnalysis = () => {
  const [image1, setImage1] = useState("");
  const [document1, setDucument1] = useState();
  const [showDoc, setShowDoc] = useState();
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [ctType, setCttype] = useState([]);
  const [consultationId, setConsultantionId] = useState(12);
  const [consultationtype, setConsultantionType] = useState('');
  const [selectedDate, setSelectedDate] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [slotTime, setSlotTime] = useState("Your Slot Time");
  const [slotDate, setSlotDate] = useState("Your Slot Date");
  const [loder, setLoder] = useState(true);
  const [submit, setSubmit] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [showplanloader, setShowplanloader] = useState(true);
  const [signatureData, setSingnatureData] = useState({
    name: "",
    dob: "",
    Language: languageList[0].name,
    email: "",
    Gender: genderList[0].name,
  });

  const [signaturePlan, setSingnaturePlan] = useState([]);
  // ********************************Payment Conde*****************************
  const [price, setPrice] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  // *************************************************************************
  const params = useParams();
  const [select, setSelect] = useState("");
  let astroData = JSON.parse(localStorage.getItem("astroDet"));
  let pathId = JSON.parse(localStorage.getItem("predictionId"));
  const user = JSON.parse(localStorage.getItem("user"));
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const navigate = useNavigate();
  const formObj = [
    {
      inputName: "Name",
      inputType: "text",
      plaholder: "Name",
      display: "none",
      id: "name",
      value: signatureData.name,
    },
    {
      inputName: "dob",
      inputType: "text",
      plaholder: "dob",
      display: "none",
      id: "dob",
      value: signatureData.name,
    },
    {
      inputName: "gender",
      inputType: "text",
      plaholder: "gender",
      display: "none",
      id: "gender",
      value: signatureData.name,
    },

    {
      inputName: "Email Id",
      inputType: "email",
      plaholder: "Email Id",
      display: "none",
      id: "email",
      value: signatureData.email,
    },
  ];

  // **********************************************
  useEffect(() => {
    const setPlan = {
      url: endpoints.plans,
      method: "POST",
      data: {
        predection_id: params.id,
        consultation_id: consultationId,
      },
    };
    axios(setPlan)
      .then((res) => {
        setSingnaturePlan(res.data.data);
        setShowplanloader(false);
        setLoder(false);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, [params.id, consultationId]);

  useEffect(() => {
    const getcsList = {
      url: "https://admin.adhigyanam.com/api/get-consultation-type",
      method: "POST",
      data: {
        predection_id: astroData.servicId,
      },
    };
    axios(getcsList)
      .then((res) => {
        setCttype(res.data.data);
        setConsultantionType(res.data.data[0].name_key)
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, []);
  // ********************** Send Data ********************
  const onChangeHandler = (e, id) => {
    setSingnatureData({
      ...signatureData,
      [id]: e.target.value,
    });
  };

  // ********************************************************
  const onSelectedSlot = (date, time) => {
    setSlotDate(date);
    setSlotTime(time);
    setModalIsVisible(false);
  };

  // *****************************Start Booking Here*************************
  const bookingHandler = (paymentId, paymentOption) => {
    const formData = new FormData();

    formData.append("astrologer_id", astroData.astrologerID);
    formData.append("predection_id", pathId);
    formData.append("consultation_type", consultationtype);
    formData.append("name", signatureData.name);
    formData.append("language", signatureData.Language);
    formData.append("gender", signatureData.Gender);
    formData.append("date_of_birth", selectedDate);
    formData.append("emailid", signatureData.email);
    formData.append("contact_no", phone);
    formData.append("slot_time", slotTime);
    formData.append("slot_date", slotDate);
    formData.append("plan_id", select);
    formData.append("sample", image1);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);

    const postData = {
      url: endpoints.signatureAnalysisStor,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: formData,
    };
    axios(postData)
      .then((res) => {
        if (res.data.status === true) {
          window.location.replace(res.data.url);
          swal("Successful!", "Successfully Submit", "success");
          setSubmit(true);
        } else {
          swal(res.data.message, "error");
          setSubmit(true);
        }
      })
      .catch((err) => {
        swal("Failed!", "Somthing Wrong", "error");
        setSubmit(true);
      });
  };

  const onSubmitHandler = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    setSubmit(false);

    if (signatureData.name === "") {
      setSubmit(true);
      swal("Failed!", "Name is required", "error");
    } else if (specialChars.test(signatureData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters", "error");
    } else if (selectedDate === new Date()) {
      setSubmit(true);
      swal("Failed!", "Date of Birth is required", "error");
    } else if (signatureData.Gender === "Select") {
      setSubmit(true);
      swal("Failed!", "Gender is required", "error");
    } else if (signatureData.email === "") {
      setSubmit(true);
      swal("Failed!", "Email is required", "error");
    } else if (!signatureData.email.includes("@") || !signatureData.email.includes(".")) {
      swal("Failed!", "Please enter valid email!", "error");
      setSubmit(true);
    } else if (phone === "" || phone === undefined) {
      setSubmit(true);
      swal("Failed!", "Phone is required!", "error");
    } else if (signatureData.Language === "Select") {
      setSubmit(true);
      swal("Failed!", "Language is required", "error");
    } else if (slotDate === "Your Slot Date") {
      setSubmit(true);
      swal("Failed!", "Please Select any Slot", "error");
    } else if (slotTime === "Your Slot Time") {
      setSubmit(true);
      swal("Failed!", "Please Select any Slot", "error");
    } else if (image1 === "") {
      setSubmit(true);
      swal("Failed!", "Please Upload Image First", "error");
    } else if (select === "") {
      setSubmit(true);
      swal("Failed!", "Please Select any Plan", "error");
    } else if (paymentOption === "") {
      setPaymentModal(true);
    } else {
      if (user) {
        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: price * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://admin.adhigyanam.com/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      } else {
        swal("Failed!", "Login first and Proceed Pay!", "error");
        setSubmit(true);
        setLoginModal(true);
      }
    }
  };
  const onSelectPlanHandler = (id, price) => {
    setSelect(id);
    setPrice(price);
  };

  const chooseYourBookSlot = () => {
    setModalIsVisible(true);
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
    setPaymentModal(false);
  };

  const onConsultantType = (type, id) => {
    setConsultantionId(id);
    setConsultantionType(type);
    setSelect(0);
    setShowplanloader(true);
  };
  // ****************************************************
  const onSelectDateHandler = (date) => {
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    setStartDate(date);
  };

  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    /////////////////////////////////////////////////
    // eslint-disable-next-line
  }, []);
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };
  // ***********************Cancel*************************
  const onCancleHandler = () => {
    setSingnatureData({
      name: "",
      dob: "",
      Language: languageList[0].name,
      email: "",
      Gender: genderList[0].name,
    });
    setPhone("");
    setSlotTime("Your Slot Time");
    setSlotDate("Your Slot Date");
    setSelect(0);
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };

  const filesHandlerOne = (e) => {
    let path = e.target.files[0];
    setImage1(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument1(image);
      setShowDoc(true);
    }
  };
  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onSubmitHandler(payOption);
  };
  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };
  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseMoadal}>
          <Modal onClose={onCloseMoadal} height="auto" overflowY="auto" width="50%">
            <div className={Styles.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={Styles.paymentOption__cros} onClick={onCloseMoadal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={Styles.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}
      {modalIsVisible && (
        <Modal onClose={onCloseMoadal} overflowY="auto">
          <BookSlot display="none" width="100%" padding="0" onClick={onSelectedSlot} modalIsVisible={modalIsVisible} />
        </Modal>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div className={Styles.readingArea}>
        <div className={Styles.readingArea__tittle}>
          <span>
            <CommanTitleWithLogo height="80px" width2="80px" icon={tittleImg} tittle={params.name} />
          </span>
          <CommonTab marginBottom="0" onClick={onConsultantType} items={ctType} />
        </div>
        {loder ? (
          <Loading />
        ) : (
          <div className={Styles.readingArea__TarroDeatailArea}>
            <div className={Styles.readingArea__TarroDeatailArea__formArea}>
              <h2>Please Fill Some Details</h2>
              <div className={Styles.readingArea__TarroDeatailArea__formArea__requredform}>
                {formObj.map((item, index) => {
                  if (item.id === "dob") {
                    return (
                      <div className={Styles.readingArea__TarroDeatailArea__formArea__requredform__select}>
                        <p>Date of Birth</p>
                        <DatePicker
                          maxDate={new Date()}
                          className={Styles.datePicker}
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={onSelectDateHandler}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    );
                  } else if (item.id === "gender") {
                    return <CommonSelect value={signatureData.Gender} label="Gender" list={genderList} onChange={onChangeHandler} />;
                  } else {
                    return (
                      <CommonInputField
                        key={index}
                        type={item.inputType}
                        placeholder={item.inputName}
                        display={item.display}
                        value={item.value}
                        margin="6px 0"
                        onChange={(e) => onChangeHandler(e, item.id)}
                      />
                    );
                  }
                })}

                <div className={Styles.readingArea__TarroDeatailArea__formArea__requredform__select}>
                  <p>Enter Phone</p>
                  <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                </div>
                <CommonSelect value={signatureData.Language} label="Language" list={languageList} onChange={onChangeHandler} />

                <div>
                  <Button
                    marginTop="12px"
                    width="100%"
                    fontSize="18px"
                    btnName="Choose Slots"
                    textTransform="capitalize"
                    onClick={chooseYourBookSlot}
                  />
                  <div className={Styles.readingArea__TarroDeatailArea__formArea__timeSlot}>
                    <p>{slotTime}</p>
                    <p>{slotDate}</p>
                    {/* {paymentOption && (
                      <div className={Styles.showPayment}>
                        <p>
                          Payment Option : <span>{paymentOption}</span>{" "}
                        </p>
                        <span>
                          <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                        </span>
                      </div>
                    )} */}
                  </div>
                </div>
                <div className={Styles.readingArea__TarroDeatailArea__formArea__drag}>
                  <DragAndDrop
                    filesHandler={filesHandlerOne}
                    image={document1}
                    showFile={showDoc}
                    fileType="Upload Your Signature"
                    fontSize="17px"
                    marginTop="10px"
                  />
                </div>
              </div>
            </div>

            <div className={Styles.readingArea__TarroDeatailArea__planeAre}>
              <>
                {showplanloader ? (
                  <Loading />
                ) : (
                  <>
                    {signaturePlan.length <= 0 ? (
                      <span className={Styles.emptyMsg}>
                        <img src={noDataFoundImg} alt="err" />
                      </span>
                    ) : (
                      <>
                        <h4>
                          <p>Select Any Plan</p>
                          <span>
                            <img src={pointer} alt="err" />
                          </span>
                        </h4>
                        {signaturePlan.map((item, index) => {
                          return (
                            <div key={index}>
                              <PlanList
                                onClick={() => onSelectPlanHandler(item.id, item.price)}
                                key={index}
                                fontSize="18px"
                                planNo={item.name}
                                plan={item.description}
                                width="34%"
                                selected={select === item.id}
                                cursor="pointer"
                                planWidth="auto"
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </>
            </div>

            <div className={Styles.readingArea__TarroDeatailArea__btnArea}>
              <Button
                btnName="Cancel"
                backgroundColor="#d4d4d4"
                textTransform="capitalize"
                color="#444"
                fontSize="18px"
                border="1px solid #d4d4d4"
                width="70%"
                onClick={onCancleHandler}
              />
              <Button
                width="70%"
                onClick={() => onSubmitHandler("")}
                btnName={submit ? "Submit" : "Processing.."}
                fontSize="18px"
                textTransform="capitalize"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SignatureAnalysis;
