import React from "react";
import Styles from "./index.module.scss";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "../../widgets/Button";
// import { Helmet } from "react-helmet";
const BlogDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blogingData = location?.state?.blogData;
  // window.title(blogingData.meta_title);
  // window.description(blogingData.meta_description);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const onRedirectHandler = (id, name) => {
    let path = generatePath(`/astrology/${id}/${name}`);
    navigate(path, {
      state: { predictionName: blogingData?.predection_id_name },
    });
  };
  return (
    <div className={Styles.blogDetail}>
      {/* <Helmet>
        <title>{blogingData.meta_title}</title>
        <meta property="title" content={blogingData.meta_title} />
        <meta name="description" content={blogingData.meta_description} />
      </Helmet> */}

      <h2 className={Styles.blogDetail__title}>{`${blogingData.astrologer_id_name} Blog`}</h2>
      <div className={Styles.blogDetail__bannerArea}>
        <div className={Styles.blogDetail__bannerArea__sliderArea}>
          <Carousel
            infinite={true}
            showDots={true}
            autoPlaySpeed={2000}
            swipeable={true}
            draggable={true}
            autoPlay={true}
            arrows={false}
            itemClass="carousel-item-margin-40-px"
            responsive={responsive}>
            {blogingData?.image.map((item, index) => {
              return (
                <div key={index} className={Styles.blogDetail__bannerArea__sliderArea__image}>
                  <img src={item} alt="error" />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className={Styles.blogDetail__bannerArea__sliderArea__detalArea}>
          <p className={Styles.blogDetail__bannerArea__sliderArea__detalArea__smallTitle}>{blogingData.title_name}</p>
          <p className={Styles.blogDetail__bannerArea__sliderArea__detalArea__name}>{blogingData.astrologer_id_name}</p>
          <h2 className={Styles.blogDetail__bannerArea__sliderArea__detalArea__tittle}>{blogingData.title}</h2>
          <div className={Styles.blogDetail__bannerArea__sliderArea__detalArea__notification}>
            <span>
              <i className="fa-solid fa-calendar-days"></i>
              {new Date(blogingData.date).getDate() +
                "-" +
                new Date(blogingData.date).getMonth() +
                "-" +
                new Date(blogingData.date).getFullYear()}
            </span>
            <span>
              <i className="fa-regular fa-heart"></i>
              {blogingData.number_of_like}
            </span>
            <span>
              {" "}
              <i className="fa-regular fa-comment"></i>
              {blogingData.number_of_comment}
            </span>
          </div>
          <p
            className={Styles.blogDetail__bannerArea__sliderArea__detalArea__paragraf}
            dangerouslySetInnerHTML={{
              __html: blogingData.description,
            }}></p>

          <Button
            onClick={() => onRedirectHandler(blogingData.predection_id, blogingData.predection_id_name)}
            btnName="Book Now"
            width="150px"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
