import React from "react";
import style from "./index.module.scss";

const CourseTitle = ({ marginBottom }) => {
  return (
    <div className={style.courseTitle} style={{ marginBottom: `${marginBottom}` }}>
      <h4 className={style.courseTitle__latest}>Latest Course Videos</h4>
      {/* <p className={style.courseTitle__view}>View All Courses</p> */}
    </div>
  );
};

export default CourseTitle;
