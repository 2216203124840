import React from "react";
import chat from "../../assets/chat.gif";
import styles from "./index.module.scss";

const ChatButton = ({ openChat }) => {
  return (
    <div onClick={openChat} className={styles.icon}>
      <img src={chat} alt="chat" />
    </div>
  );
};

export default ChatButton;
