import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";

const Backdrop = (props) => {
  return <div onClick={props.onClose} className={styles.backdrop}></div>;
};

const ModalOverlay = (props) => {
  return (
    <div
      style={{
        width: `${props.width}`,
        height: `${props.height}`,
        overflowY: `${props.overflowY}`,
        borderRadius: `${props.borderRadius}`,
        border: `${props.border}`,
      }}
      className={styles.modal}>
      {props.children}
    </div>
  );
};

let modal = document.getElementById("modal");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, modal)}
      {ReactDOM.createPortal(
        <ModalOverlay
          height={props.height}
          overflowY={props.overflowY}
          width={props.width}
          border={props.border}
          className={props.className}>
          {props.children}
        </ModalOverlay>,
        modal
      )}
    </Fragment>
  );
};

export default Modal;
