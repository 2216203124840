import React from "react";
import styles from "./index.module.scss";

const Outgoing = ({ outgoing, oTime }) => {
  return (
    <div className={styles.cont}>
      <div className={styles.outgoing}>
        <p>{outgoing}</p>
        <p className={styles.outgoing__time}>{oTime}</p>
      </div>
    </div>
  );
};

export default Outgoing;
