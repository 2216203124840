import React from "react";
import Styles from "./index.module.scss";
import icon from "../../assets/individual.png";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import CommonTab from "../../components/CommanTab";
import PlanList from "../../components/PlanList";
import CommanConsultationList from "../../components/CommanConsultationLists";
import ganesh from "../../assets/banside.webp";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import Button from "../../widgets/Button";
import Loading from "../../components/Loading";
import noDataFoundImg from "../../assets/noData.png";

const IndividualConsultation = () => {
  ///////////////////////////////////////
  const [showPlan, setShowPlan] = useState(true);
  const [ctType, setCttype] = useState([]);
  const [consultationId, setConsultaitonId] = useState(1);
  const [selectedTab, setSeletedTab] = useState("telephonic_consultation");
  const [listLoding, setListLoding] = useState(true);
  const [showFormbtn, setShowFormbtn] = useState(false);
  const [individualPlan, setIndividualPlan] = useState([]);
  const [consaltentData, setConsaltentData] = useState([]);
  const [servicesList, setServicesList] = useState({
    telephonic_consultation: [],
    chat_consultation: [],
    video_consultation: [],
    telephonic_consultation_with_report: [],
  });
  const [select, setSelect] = useState("");
  const [allcheck, setAllcheck] = useState(false);
  const [price, setPrice] = useState("");

  const params = useParams();
  const navigate = useNavigate();
  const listOfConsultation = JSON.parse(localStorage.getItem("allConsultationData"));
  const consultationData = {
    asrtoDetail: JSON.parse(localStorage.getItem("astroDet")),
    consultationType: selectedTab,
    consultationList: servicesList[selectedTab],
    consultationTypeId: consultationId,
    planPrice: price,
  };
  const onConsultantType = (type, id) => {
    let consultType = type;
    consultationData.consultationType = consultType;
    setSeletedTab(consultType);
    setConsultaitonId(id);
    setSelect(0);
    setShowPlan(true);
  };

  useEffect(() => {
    const getcsList = {
      url: "https://admin.adhigyanam.com/api/get-consultation-type",
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
        predection_type_id: consultationData.asrtoDetail.serviceType,
      },
    };
    axios(getcsList)
      .then((res) => {
        setCttype(res.data.data);
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (listOfConsultation) {
      servicesList[listOfConsultation?.consultationType] = listOfConsultation?.consultationList;
      setServicesList(servicesList);
    }
    // eslint-disable-next-line
  }, []);

  // ****************************************************
  useEffect(() => {
    const setPlan = {
      url: "https://admin.adhigyanam.com/api/planService-get",
      method: "POST",
      data: {
        predection_id: consultationData.asrtoDetail.servicId,
        predection_type_id: consultationData.asrtoDetail.serviceType,
        consultation_id: consultationId,
      },
    };
    const setConsaltentList = {
      url: endpoints.consaltationlist + `/${params.id}`,
      method: "GET",
    };
    //****************Plans************* */
    axios(setPlan)
      .then((res) => {
        setIndividualPlan(res.data.data);
        setShowPlan(false);
      })
      .catch((err) => {
        // console.log(err)
      });
    //****************ConsaltantList************* */
    axios(setConsaltentList)
      .then((res) => {
        setConsaltentData(res.data.data);
        setListLoding(false);
        // ************************
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, [params.id, consultationId]);

  // ***************************
  const onRedirectHandler = () => {
    const path = generatePath(`/astrology/${params.id}/${params.name}/${params.type}/type1/book-slot`);
    navigate(path);
    localStorage.setItem("allConsultationData", JSON.stringify(consultationData));
  };

  // **********************Consultant Type *************************

  // ***************** Select List ************************************
  const onSelectListHandler = (list) => {
    let allList = { ...servicesList };

    let filterList;
    let findList = allList[selectedTab]?.find((item) => {
      return item === list;
    });
    if (findList) {
      filterList = allList[selectedTab]?.filter((item) => item !== list);
      allList[selectedTab] = filterList;
      setServicesList(allList);
    } else {
      allList[selectedTab] = [...allList[selectedTab], list];
      setServicesList({ ...allList });
    }
  };
  useEffect(() => {
    let allData = consaltentData.map((item) => item.name);
    if (servicesList[selectedTab]?.length !== allData.length) {
      setAllcheck(false);
    } else {
      setAllcheck(true);
    }
    // eslint-disable-next-line
  }, [onSelectListHandler]);

  useEffect(() => {
    setSelect(servicesList[selectedTab]?.length);

    if (servicesList[selectedTab]?.length) {
      setShowFormbtn(true);
    } else {
      setShowFormbtn(false);
    }
    // eslint-disable-next-line
  }, [servicesList[selectedTab]]);

  const allselect = () => {
    let allList = { ...servicesList };
    let allData = consaltentData.map((item) => item.name);
    if (allList[selectedTab]?.length === allData.length) {
      allList[selectedTab] = [];
      setServicesList(allList);
      setAllcheck(false);
    } else {
      setAllcheck(true);
      allList[selectedTab] = allData;
      setServicesList(allList);
    }
  };

  // *************************************************RazorPay***************************************
  useEffect(() => {
    const planPrice = individualPlan.find(
      (item) => consultationData.consultationList.length >= item.topic && consultationData.consultationList.length <= item.max_topic
    );
    setPrice(planPrice?.price);

    // eslint-disable-next-line
  }, [servicesList]);
  // **************************************************************************************************
  return (
    <>
      <div className={Styles.mainContaner}>
        <div className={Styles.mainContaner__tittleArea}>
          <CommanTitleWithLogo tittle="Individual Consultation" icon={icon} />
        </div>
        <div className={Styles.mainContaner__referBtn}>
          <CommonTab onClick={onConsultantType} items={ctType} />
          {showFormbtn && (
            <Button
              backgroundColor="#1eb2a6"
              border="2px solid #1eb2a6"
              color="#fff"
              width="130px"
              fontSize="20px"
              btnName="Next"
              marginTop="0"
              onClick={onRedirectHandler}
            />
          )}
        </div>
        {listLoding ? (
          <Loading />
        ) : (
          <div className={Styles.mainContaner__consultaintArea}>
            <CommanConsultationList
              allChecked={allcheck}
              allselect={allselect}
              items={servicesList[selectedTab]}
              onClick={onSelectListHandler}
              lists={consaltentData}
              image={ganesh}
            />

            {showPlan ? (
              <div className={Styles.mainContaner__consultaintArea__plan}>
                <Loading />
              </div>
            ) : (
              <div className={Styles.mainContaner__consultaintArea__plan}>
                {individualPlan.length <= 0 ? (
                  <span className={Styles.emptyMsg}>
                    <img src={noDataFoundImg} alt="err" />
                  </span>
                ) : (
                  <>
                    {individualPlan.map((plan) => {
                      return (
                        <PlanList
                          selected={select >= parseInt(plan.topic) && select <= parseInt(plan.max_topic)}
                          // selected={select === plan.topic}
                          height="52px"
                          key={plan.id}
                          plan={plan.description}
                          planNo={plan.name}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default IndividualConsultation;
