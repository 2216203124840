import React from "react";
import Search from "../../../widgets/Search";
import style from "./index.module.scss";

const PoojaTitle = ({ onSearchHandler, tittle, marginBottom }) => {
  return (
    <div className={style.poojaTitle} style={{ marginBottom: `${marginBottom}` }}>
      <h2>{tittle}</h2>
      <div className={style.poojaTitle__search}>
        <Search onKeyUp={onSearchHandler} />
      </div>
    </div>
  );
};

export default PoojaTitle;
