import React from "react";
import style from "./index.module.scss";
import Rating from "@mui/material/Rating";

const TestimonialUser = (props) => {
  return (
    <div className={style.testimonialUser}>
      <div className={style.testimonialUser__profile}>
        <img src={props.src} alt={props.user} />
      </div>
      <div className={style.testimonialUser__det}>
        <h4 className={style.testimonialUser__det__name}>{props.user}</h4>
        <h5 className={style.testimonialUser__det__designation}>{props.designation}</h5>
      </div>
      <p className={style.testimonialUser__starArea}>
        <Rating name="half-rating-read" defaultValue={props.ratting} precision={0.5} readOnly />
      </p>
    </div>
  );
};

export default TestimonialUser;
