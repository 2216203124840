import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import BloggingCard from "../../components/BlogingCard";

import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import Loading from "../../components/Loading";
import Modal from "../../modal";
import ModalLayout from "../../components/ModalLayout";
import noDataFoundImg from "../../assets/noData.png";
import PoojaTitle from "../BookPanditJi/PoojaTitle";
import Button from "../../widgets/Button";
import { useNavigate } from "react-router-dom";

const Blogging = () => {
  const [blogData, setBlogData] = useState([]);
  const [description, setDescription] = useState([]);
  const [loding, setLoding] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [topBlog, setTopBlog] = useState(blogData[0]);
  const [blogFilterData, setBlogFilterData] = useState([]);
  // const [showList, setShowList] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getBlog = {
      url: endpoints.blog,
      method: "GET",
    };
    axios(getBlog)
      .then((res) => {
        setBlogData(res.data.data);
        setSearchData(res.data.data);
        setTopBlog(res.data.data[0]);
        setBlogFilterData(res.data.data);
        setLoding(false);
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-linen
  }, []);
  const onReadMore = (item) => {
    setDescription(item);
    // setModalIsVisible(true);
    navigate("/blogging-details", { state: { blogData: item } });
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
  };
  const onSearchHandler = (e) => {
    let matchData = searchData.filter((item) => item.title_name.toLowerCase().includes(e.target.value.toLowerCase()));
    setBlogData(matchData);
    setTopBlog(matchData[0]);
  };
  const onRelatedBlog = (blog) => {
    let matchData = searchData.filter((item) => item.title_name.toLowerCase().includes(blog.title_name.toLowerCase()));
    setBlogData(matchData);
    setTopBlog(matchData[0]);
  };
  const onAllBlogHandler = () => {
    setBlogData(blogFilterData);
    setTopBlog(blogFilterData[0]);
  };

  // console.log(blogFilterData);
  return (
    <>
      {modalIsVisible && (
        <Modal overflowY="auto" width="50%" onClose={onCloseMoadal}>
          <ModalLayout onCloseHandler={onCloseMoadal}>
            <div className={Styles.descript}>
              <h5>{description.astrologer_id_name}</h5>
              <h2>{description.title}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: description.description,
                }}
              />
              <Button btnName="Book Service" />
            </div>
          </ModalLayout>
        </Modal>
      )}
      <div className={Styles.blogingArea}>
        <div className={Styles.blogingArea__tittle}>
          <PoojaTitle marginBottom="30px" tittle="Blogging" onSearchHandler={onSearchHandler} />
        </div>
        {loding ? (
          <Loading />
        ) : (
          <div className={Styles.blogingArea__blogArea}>
            <div className={Styles.blogingArea__blogArea__blotArticle}>
              <ul>
                <li onClick={onAllBlogHandler}>
                  All{" "}
                  <span>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </li>
                {blogFilterData.map((item, index) => {
                  return (
                    <li key={index} onClick={() => onRelatedBlog(item)}>
                      {item.title_name}
                      <span>
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <>
              {blogData.length <= 0 ? (
                <span className={Styles.emptyMsg}>
                  <img src={noDataFoundImg} alt="err" />
                </span>
              ) : (
                <>
                  <div className={Styles.blogingArea__blogArea__blog}>
                    <div className={Styles.blogingArea__blogArea__blog__topblog}>
                      <div className={Styles.blogingArea__blogArea__blog__topblog__imgarea}>
                        <img src={topBlog?.image[0]} alt="img" />
                      </div>
                      <div className={Styles.blogingArea__blogArea__blog__topblog__detailsArea}>
                        <div className={Styles.blogingArea__blogArea__blog__topblog__detailsArea__detaile}>
                          <p className={Styles.blogingArea__blogArea__blog__topblog__detailsArea__detaile__smallTitle}>
                            {topBlog?.title_name}
                          </p>
                          <p className={Styles.blogingArea__blogArea__blog__topblog__detailsArea__detaile__name}>
                            {topBlog?.astrologer_id_name ? topBlog?.astrologer_id_name : "Name.."}
                          </p>
                          <h2 className={Styles.blogingArea__blogArea__blog__topblog__detailsArea__detaile__tittle}>
                            {topBlog?.title.slice(0, 40)}...
                          </h2>
                          <p
                            className={Styles.blogingArea__blogArea__blog__topblog__detailsArea__detaile__paragraf}
                            dangerouslySetInnerHTML={{
                              __html: topBlog?.description.slice(0, 200) + "...",
                            }}></p>
                          <div className={Styles.blogingArea__blogArea__blog__topblog__detailsArea__detaile__notification}>
                            <span>
                              {new Date(topBlog?.date).getDate() +
                                "-" +
                                (new Date(topBlog?.date).getMonth() + 1) +
                                "-" +
                                new Date(topBlog?.date).getFullYear()}
                            </span>
                            <span>
                              <i className="fa-regular fa-heart"></i> {topBlog?.number_of_like}
                            </span>
                            <span>
                              {" "}
                              <i className="fa-regular fa-comment"></i>
                              {topBlog?.number_of_comment}
                            </span>
                          </div>
                          <Button
                            height="35px"
                            btnName="Read More"
                            width="auto"
                            padding="0 10px"
                            marginTop="15px"
                            onClick={() => onReadMore(topBlog)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={Styles.blogingArea__blogArea__blog__blogCardArea}>
                      {blogData.map((item, index) => {
                        if (index > 0) {
                          return (
                            <div className={Styles.blogingArea__blogArea__blog__blogCardArea__blogCard}>
                              <BloggingCard
                                key={index}
                                onclick={() => onReadMore(item)}
                                image={item.image[0]}
                                tittleName={item.title_name}
                                tittle={`${item.title.slice(0, 40)}...`}
                                name={item?.astrologer_id_name ? item.astrologer_id_name : "Name.."}
                                description={item.description.slice(0, 120) + "..."}
                                date={
                                  new Date(item.date).getDate() +
                                  "-" +
                                  (new Date(item.date).getMonth() + 1) +
                                  "-" +
                                  new Date(item.date).getFullYear()
                                }
                                like={item.number_of_like}
                                comment={item.number_of_comment}
                                editor={item.editor_choice}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
};

export default Blogging;
