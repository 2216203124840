import React from "react";
import style from "./index.module.scss";
import Button from "../../widgets/Button";
import { Rating } from "@mui/material";

const CourseCard = (props) => {
  return (
    <div className={style.courseCard}>
      <div className={style.courseCard__courseImg}>
        <img src={props.image} alt={props.title} />
        {/* <span>
          <i className="fa-solid fa-circle-play"></i>
        </span> */}
      </div>
      <div className={style.courseCard__courseDet}>
        <h5 className={style.courseCard__courseDet__title}>{props.title}</h5>
        <span>
          <Rating name="half-rating-read" defaultValue={props.rating} precision={0.5} readOnly />
        </span>
        <h5 className={style.courseCard__courseDet__cName} style={{ display: `${props.display}` }}>
          <span>
            <i className="fa-solid fa-book"></i>
          </span>
          {props.sub_title}
        </h5>
        <h5 className={style.courseCard__courseDet__cName} style={{ display: `${props.display}` }}>
          <span>
            <i className="fa-solid fa-user"></i>
          </span>
          {props.trainer}
        </h5>
        <h5 className={style.courseCard__courseDet__cName} style={{ display: `${props.priceDisplay}` }}>
          <span>
            <i className="fa-solid fa-indian-rupee-sign"></i>
          </span>{" "}
          {props.price}/-
        </h5>
        <h5 className={style.courseCard__courseDet__tag} style={{ display: `${props.display}` }}>
          <span>
            <i className="fa-solid fa-tags"></i>
          </span>
          {props.label}
        </h5>

        <Button
          btnName={props.btnName}
          height={props.height}
          backgroundColor={props.backgroundColor}
          marginTop={props.marginTop}
          textTransform={props.textTransform}
          fontSize={props.fontSize}
          onClick={props.onClick}
          marginBottom={props.marginBottom}
        />
      </div>
      {/* <div className={style.courseCard__det}>
        <p className={style.courseCard__det__topic}>{props.topic} Hours</p>
        <div className={style.courseCard__det__btn}></div>
      </div> */}
    </div>
  );
};

export default CourseCard;
