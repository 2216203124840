import React, { useCallback, useContext } from "react";
import style from "./index.module.scss";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import CommonTab from "../../components/CommanTab";
import vastu from "../../assets/p5.webp";
import CommanConsultationList from "../../components/CommanConsultationLists";
import vastuImg from "../../assets/vastu.png";
import PlanList from "../../components/PlanList";
import Button from "../../widgets/Button";
import CommonInputField from "../../widgets/CommonInputField";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { endpoints } from "../../utils/Endpoints";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../components/Loading";
import BookSlot from "../../modal/BookSlot";
import Modal from "../../modal";
import PhoneNumber from "../../components/PhoneInput";
import CommonSelect from "../../components/CommonSelect";
import swal from "sweetalert";
import { Context } from "../../utils/ContextStor";
import Login from "../../modal/Login";
import SignUp from "../../modal/SignUp";
import { loadScript } from "../../helpers/razorpay";
import Logo from "../../assets/LOGO.webp";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import ModalLayout from "../../components/ModalLayout";
import closeImg from "../../assets/close.svg";
const items = [
  {
    id: 1,
    name: "Telephonic Consultation",
    name_key: "telephonic_consultation",
  },
];

const VastuAnalysis = () => {
  const genderList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Male" },
    { id: 3, name: "Female" },
    { id: 4, name: "Other" },
  ];
  const languageList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Hindi" },
    { id: 3, name: "English" },
  ];
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [consultationId, setConsultaitonId] = useState(11);
  const [selectedTab, setSeletedTab] = useState("telephonic_consultation");
  const [loder, setLoder] = useState(true);
  const [vastuPlans, setVastuPlans] = useState([]);
  const [consaltentData, setConsaltentData] = useState([]);
  const [servicesList, setServicesList] = useState({
    telephonic_consultation: [],
    chat_consultation: [],
    video_consultation: [],
    telephonic_consultation_with_report: [],
  });
  const [select, setSelect] = useState("");
  const params = useParams();
  const [submit, setSubmit] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [slotTime, setSlotTime] = useState("Your slot time");
  const [slotDate, setSlotDate] = useState("Your slot date");
  const [vastuData, setValuData] = useState({
    name: "",
    email: "",
    Language: languageList[0].name,
    Gender: genderList[0].name,
  });
  const [allChecked, setAllchecked] = useState(false);
  const [price, setPrice] = useState("");
  // eslint-disable-next-line
  const [paymentOption, setPaymentOption] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const navigate = useNavigate();
  // const listOfConsultation = JSON.parse(localStorage.getItem("allConsultationData"));
  const user = JSON.parse(localStorage.getItem("user"));
  let pathId = JSON.parse(localStorage.getItem("predictionId"));
  const consultationData = {
    asrtoDetail: JSON.parse(localStorage.getItem("astroDet")),
    consultationType: "Telephonic Consultation",
    consultationList: servicesList[selectedTab],
    consultationTypeId: consultationId,
  };

  const formObj = [
    {
      id: "name",
      value: vastuData.name,
      type: "text",
      placeholder: "Name",
      name: "name",
      display: "none",
    },
    {
      id: "gender",
      value: vastuData.name,
      type: "text",
      placeholder: "gender",
      name: "gender",
      display: "none",
    },
    {
      id: "email",
      value: vastuData.email,
      type: "email",
      placeholder: "Email Id",
      name: "email",
      display: "none",
    },
  ];
  // **********************OnChange *********************
  const onChangeHandler = (e, id) => {
    setValuData({
      ...vastuData,
      [id]: e.target.value,
    });
  };
  useEffect(() => {
    const setPlans = {
      url: endpoints.plans,
      method: "POST",
      data: {
        predection_id: params.id,
        consultation_id: consultationId,
      },
    };
    const setConsaltentList = {
      url: endpoints.consaltationlist + `/${params.id}`,
      method: "GEt",
    };
    axios(setPlans)
      .then((res) => {
        setVastuPlans(res.data.data);
      })
      .catch((err) => {
        // console.log(err)
      });
    //****************ConsaltantList************* */
    axios(setConsaltentList)
      .then((res) => {
        setConsaltentData(res.data.data);
        setLoder(false);
      })
      .catch((err) => {
        // console.log(err)
      });
    // eslint-disable-next-line
  }, [params.id]);

  const onSelectListHandler = (list) => {
    let allList = { ...servicesList };

    let filterList;
    let findList = allList[selectedTab]?.find((item) => {
      return item === list;
    });
    if (findList) {
      filterList = allList[selectedTab]?.filter((item) => item !== list);
      allList[selectedTab] = filterList;
      setServicesList(allList);
    } else {
      allList[selectedTab] = [...allList[selectedTab], list];
      setServicesList({ ...allList });
    }
  };

  useEffect(() => {
    setSelect(servicesList[selectedTab].length);
    // eslint-disable-next-line
  }, [consultationData.consultationList]);

  const bookingHandler = (paymentId, paymentOption) => {
    const formData = new FormData();
    formData.append("astrologer_id", consultationData.asrtoDetail.astrologerID);
    formData.append("consultation_type", consultationData.consultationType);
    formData.append("consultation_id[]", consultationData.consultationList);
    formData.append("name", vastuData.name);
    formData.append("emailid", vastuData.email);
    formData.append("contact_no", phone);
    formData.append("gender", vastuData.Gender);
    formData.append("language", vastuData.Language);
    formData.append("slot_time", slotTime);
    formData.append("slot_date", slotDate);
    formData.append("predection_id", pathId);
    formData.append("consultation_type_id", consultationId);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);
    const postData = {
      url: endpoints.vastuAnalysisStor,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: formData,
    };
    axios(postData)
      .then((response) => {
        if (response.data.status === true) {
          window.location.replace(response.data.url);
          swal("Successful!", "Success", "success");
          setSubmit(true);
        } else {
          setSubmit(true);
          swal("Sorry :(", response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        setSubmit(true);
      });
  };

  const paymentForBooking = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    setSubmit(false);

    // *********** Check Validation **********

    if (vastuData.name === "") {
      swal("Failed!", "Please enter name", "error");
      setSubmit(true);
    } else if (specialChars.test(vastuData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters or number", "error");
    } else if (vastuData.Gender === "Select") {
      swal("Failed!", "Please select gender", "error");
      setSubmit(true);
    } else if (vastuData.email === "") {
      swal("Failed!", "Please enter  email", "error");
      setSubmit(true);
    } else if (!vastuData.email.includes("@") || !vastuData.email.includes(".")) {
      swal("Failed!", "Please enter valid email", "error");
      setSubmit(true);
    } else if (phone === "" || phone === undefined) {
      swal("Failed!", "Please enter valid number", "error");
      setSubmit(true);
    } else if (vastuData.Language === "Select") {
      swal("Failed!", "Please select language", "error");
      setSubmit(true);
    } else if (slotDate === "Your slot date") {
      swal("Failed!", "Please select slot date", "error");
      setSubmit(true);
    } else if (slotTime === "Your slot time") {
      swal("Failed!", "Please select slot time", "error");
      setSubmit(true);
    } else if (servicesList[selectedTab]?.length === 0) {
      swal("Failed!", "Please Select Any Topic", "error");
      setSubmit(true);
    } else if (paymentOption === "") {
      // swal("Failed!", "Please Select Any Payment Option", "error");
      // setSubmit(true);
      setPaymentModal(true);
    } else {
      if (user) {
        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: price * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://admin.adhigyanam.com/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      } else {
        swal("Failed!", "Please login first and Proceed to Pay", "error");
        setSubmit(true);
        setLoginModal(true);
      }
    }
  };

  const onSelectedSlot = (date, time) => {
    setSlotDate(date);
    setSlotTime(time);
    setModalIsVisible(false);
  };
  const chooseYourBookSlot = () => {
    setModalIsVisible(true);
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
    setPaymentModal(false);
  };
  const onTabHandler = (name, id) => {
    setSeletedTab(name);
    setConsultaitonId(id);
  };
  const allselect = () => {
    let allList = { ...servicesList };
    let allData = consaltentData.map((item) => item.name);
    if (allList[selectedTab]?.length === allData.length) {
      allList[selectedTab] = [];
      setServicesList(allList);
      setAllchecked(false);
    } else {
      setAllchecked(true);
      allList[selectedTab] = allData;
      setServicesList(allList);
    }
  };
  useEffect(() => {
    let allData = consaltentData.map((item) => item.name);
    if (servicesList[selectedTab]?.length === allData.length) {
      setAllchecked(true);
    } else {
      setAllchecked(false);
    }
    // eslint-disable-next-line
  }, [onSelectListHandler]);
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };

  const onCancelHandler = () => {
    setValuData({
      name: "",
      email: "",
      Language: languageList[0].name,
      Gender: genderList[0].name,
    });
    setSlotTime("Your slot time");
    setSlotDate("Your slot date");
    setPhone("");
    setServicesList({
      telephonic_consultation: [],
      chat_consultation: [],
      video_consultation: [],
      telephonic_consultation_with_report: [],
    });
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };
  useEffect(() => {
    const planPrice = vastuPlans.find(
      (item) => consultationData.consultationList.length >= item.topic && consultationData.consultationList.length <= item.max_topic
    );
    setPrice(planPrice?.price);

    // eslint-disable-next-line
  }, [servicesList]);

  const onClickPaymentOption = (payOption) => {
    setPaymentOption(payOption);
    paymentForBooking(payOption);
    setPaymentModal(false);
  };
  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };
  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseMoadal}>
          <Modal onClose={onCloseMoadal} height="auto" overflowY="auto" width="50%">
            <div className={style.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={style.paymentOption__cros} onClick={onCloseMoadal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={style.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}
      {modalIsVisible && (
        <Modal onClose={onCloseMoadal}>
          <BookSlot display="none" width="100%" padding="0" onClick={onSelectedSlot} modalIsVisible={modalIsVisible} />
        </Modal>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <>
        {loder ? (
          <Loading />
        ) : (
          <>
            <div className={style.vastuHead}>
              <CommanTitleWithLogo height="62px" width="50%" tittle={params.name} icon={vastu} />
              <CommonTab items={items} marginBottom="0" onClick={onTabHandler} />
            </div>
            <div className={style.vastuAnalysis}>
              <div className={style.vastuAnalysis__left}>
                <CommanConsultationList
                  allChecked={allChecked}
                  allselect={allselect}
                  onClick={onSelectListHandler}
                  containerWidth="100%"
                  lists={consaltentData}
                  image={vastuImg}
                  width="380px"
                  items={servicesList[selectedTab]}
                />
              </div>
              <div className={style.vastuAnalysis__right}>
                <div>
                  {vastuPlans.map((plan) => (
                    <PlanList
                      selected={select >= parseInt(plan.topic) && select <= parseInt(plan.max_topic)}
                      fontSize="18px"
                      marginBottom="30px"
                      key={plan.id}
                      plan={plan.description}
                      planNo={plan.name}
                    />
                  ))}
                </div>

                <div className={style.vastuAnalysis__right__formArea}>
                  <div className={style.vastuAnalysis__right__formArea__input}>
                    {formObj.map((item, index) => {
                      if (item.id === "gender") {
                        return (
                          <div className={style.gender}>
                            <CommonSelect value={vastuData.Gender} label="Gender" list={genderList} onChange={onChangeHandler} />
                          </div>
                        );
                      } else {
                        return (
                          <CommonInputField
                            value={item.value}
                            key={index}
                            id={item.name}
                            placeholder={item.placeholder}
                            display={item.display}
                            type={item.type}
                            onChange={(e) => onChangeHandler(e, item.id)}
                          />
                        );
                      }
                    })}
                    <div className={style.vastuAnalysis__right__formArea__input__select}>
                      <p>Enter Phone</p>
                      <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                    </div>

                    <CommonSelect value={vastuData.Language} label="Language" list={languageList} onChange={onChangeHandler} />
                    <Button width="100%" fontSize="18px" btnName="Choose Slots" textTransform="capitalize" onClick={chooseYourBookSlot} />
                  </div>

                  <div className={style.vastuAnalysis__right__formArea__sloteArea}>
                    <p>{slotTime}</p>
                    <p>{slotDate}</p>
                    {/* {paymentOption && (
                      <div className={style.showPayment}>
                        <p>
                          Payment Option : <span>{paymentOption}</span>{" "}
                        </p>
                        <span>
                          <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                        </span>
                      </div>
                    )} */}
                  </div>
                </div>

                <div className={style.vastuAnalysis__right__buttonArea}>
                  <Button
                    btnName="Cancel"
                    fontSize="20px"
                    backgroundColor="#c4c4c4"
                    border="1px solid #c4c4c4"
                    color="#333"
                    textTransform="capitalize"
                    width="90%"
                    onClick={onCancelHandler}
                  />
                  <Button
                    onClick={() => paymentForBooking("")}
                    btnName={submit ? "Submit" : "Processing.."}
                    fontSize="20px"
                    textTransform="capitalize"
                    width="90%"
                  />
                </div>
                <div className={style.vastuAnalysis__right__note}>
                  <h3 className={style.vastuAnalysis__right__note__h3}>
                    Note :-
                    <p className={style.vastuAnalysis__right__note__h3__para}>You Should Have Digital Compass In Your Mobile</p>
                  </h3>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default VastuAnalysis;
