import React from "react";
import style from "./index.module.scss";
import Button from "../../widgets/Button";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className={style.notFound}>
      <div className={style.notFound__container}>
        <div className={style.notFound__container__content}>
          <h1>404</h1>
          <h2>UH OH! You're lost.</h2>
          <p>
            The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go
            back to the homepage.
          </p>
          <Button onClick={goToHome} btnName="Home" textTransform="capitalize" fontSize="20px" width="120px" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
