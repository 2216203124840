import React from "react";
import Styles from "./index.module.scss";
const PaymentOption = (props) => {
  return (
    <div className={Styles.optionArea}>
      <div className={Styles.optionArea__labelArea}>
        <div className={Styles.optionArea__labelArea__imgArea}>
          <img src={props.img} alt="img" />
        </div>
      </div>
      <input type="radio" name={props.name} value={props.value} id={props.id} onClick={props.onClick} />
    </div>
  );
};

export default PaymentOption;
