import React from "react";
import style from "./index.module.scss";

const Item = (props) => {
  return (
    <div className={style.item}>
      <p className={style.item__circle} style={{ backgroundColor: `${props.backgroundColor}` }}></p>
      <p className={style.item__tag}>{props.tag}</p>
    </div>
  );
};

export default Item;
