import React from "react";
import ReportCard from "./ReportCard";
import PredictionCard from "./PredictionCard";
import Styles from "./index.module.scss";
import CommonTittle from "../../components/CommonTitle";
import ModalLayout from "../../components/ModalLayout";
import Modal from "..";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../components/Loading";

const ChoosePredictionType = (props) => {
  const [showData, setShowData] = useState(true);
  const [pridictionData, setPridictionData] = useState([]);

  useEffect(() => {
    const getPridictionType = {
      url: endpoints.pridictionType,
      method: "GET",
    };
    axios(getPridictionType)
      .then((res) => {
        setPridictionData(res.data.data);
        setShowData(false);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);
  return (
    <Modal overflowY="auto" onClose={props.onClose}>
      <ModalLayout onCloseHandler={props.onClose}>
        <div className={Styles.modalArea}>
          {showData ? (
            <div className={Styles.modalArea__loder}>
              <Loading />
            </div>
          ) : (
            <div className={Styles.modalArea__modal}>
              <div className={Styles.modalArea__modal__heading}>
                <CommonTittle tittle="Choose Prediction Services" />
              </div>
              <div className={Styles.modalArea__modal__service}>
                {pridictionData.map((item, index) => {
                  return (
                    index <= 3 && (
                      <PredictionCard
                        onClick={() =>
                          props.onPageOpenHandler(item.name, item.id)
                        }
                        src={item.icon}
                        name={item.name}
                        key={index}
                      />
                    )
                  );
                })}
              </div>
              <div className={Styles.modalArea__modal__reportCard}>
                {pridictionData.map((item, index) => {
                  return (
                    index >= 4 && (
                      <ReportCard src={item.icon} details={item.name} onClick={() =>
                        props.onPageOpenHandler(item.description, item.id)
                      } />
                    )
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default ChoosePredictionType;
