import React from "react";
import styels from "./index.module.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const PhoneNumber = ({ value, onChange, border }) => {
  return (
    <div className={styels.inputArea} style={{ border: `${border}` }}>
      <PhoneInput
        defaultCountry="IN"
        country="IN"
        international={false}
        placeholder="Enter phone number"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
export default PhoneNumber;
