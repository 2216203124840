const BASE_URL = "https://admin.adhigyanam.com/api/";

export const endpoints = {
  enquiry: BASE_URL + "enquiry-store",
  bookPandit: BASE_URL + "poojaService-book",
  plans: BASE_URL + "planService-get",
  consaltationlist: BASE_URL + "consultationService-get",
  testimonial: BASE_URL + "testimonial-get",
  matchMaking: BASE_URL + "matchMaking-store",
  kundaliReport: BASE_URL + "kundaliReport-store",
  birthTimeRectification: BASE_URL + "birthTimeRectification-store",
  individualConsultantStor: BASE_URL + "individual-store",
  tarroCardStor: BASE_URL + "tarotCard-store",
  palmReadStor: BASE_URL + "palmistry-store",
  vastuAnalysisStor: BASE_URL + "vastu-store",
  faceReadingStor: BASE_URL + "faceReading-store",
  graphologyStor: BASE_URL + "graphology-store",
  aboutSetting: BASE_URL + "about-setting",
  pridictionType: BASE_URL + "predectionType-get/1",
  profileData: BASE_URL + "profile-get/",
  blog: BASE_URL + "blog-get",
  signatureAnalysisStor: BASE_URL + "signatureAnalysis-store",
  generalSettings: BASE_URL + "general-setting",
  submitFeedback: BASE_URL + "submit-feedback",
  getPanditBooking: BASE_URL + "book-pandit-ji-requests",
  getPanditList: BASE_URL + "panditDetails",
};

export const imgPath = {};
