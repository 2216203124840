import React from "react";
import Styles from "./index.module.scss";
// import ganeshPic from "../../../assets/banside.webp";
const ReportCard = (props) => {
  return (
    <div onClick={() => props.onClick("kundli")} className={Styles.cardArea}>
      <div className={Styles.cardArea__cardImage}>
        <img src={props.src} alt="error" />
      </div>
      <div className={Styles.cardArea__cardDetail}>
        <p>
          {props.details}
        </p>
      </div>
    </div>
  );
};

export default ReportCard;
