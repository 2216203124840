import { createPortal } from "react-dom";
import styles from "./index.module.scss";
import Button from "../Button/index";
const Modal = ({ children, onModalClose, onSaveHandler }) => {
  return createPortal(
    <div className={styles.backdrop}>
      <div className={styles.modal}>
        {children}
        <footer>
          <Button marginTop="0" marginBottom="0" btnName="Cancel" onClick={onModalClose} />
          <Button
            marginTop="0"
            marginBottom="0"
            btnName="Save"
            onClick={() => {
              onSaveHandler();
              onModalClose();
            }}
          />
        </footer>
      </div>
    </div>,
    document.getElementById("modal")
  );
};
export default Modal;
