import React, { useCallback, useContext, useEffect, useState } from "react";
import CourseTitle from "./CourseTitle";
import style from "./index.module.scss";
import CustomeBanner from "../../components/CustomeBanner";
import CourseCard from "../../components/CourseCard";
import CourseBg from "../../assets/learnBg.webp";
import axios from "axios";
import Loading from "../../components/Loading";
import { generatePath, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import swal from "sweetalert";
import Login from "../../modal/Login";
import SignUp from "../../modal/SignUp";
import Modal from "../../modal";
import cardImg from "../../assets/c7.webp";
import noDataFoundImg from "../../assets/noData.png";

import { Context } from "../../utils/ContextStor";
const Course = () => {
  const [showList, setShowList] = useState(false);
  const [courseObj, setCourseObj] = useState();
  const [loading, setLoading] = useState(true);
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [filterInputList, setFilterInputList] = useState([]);
  // eslint-disable-next-line
  const [filterList, setFilterList] = useState([]);
  // eslint-disable-next-line
  const [category, setCategory] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const getConfig = {
      url: "https://admin.adhigyanam.com/api/courses",
      method: "get",
    };
    // ***********************************************
    const getCatagory = {
      url: "https://admin.adhigyanam.com/api/course-category",
      method: "get",
    };
    // ***********************************************

    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          let val = res.data.data;
          setCourseObj(val);
          setSearchData(val);
          setFilterInputList(val);
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err)
      });

    // ***********************************************
    axios(getCatagory)
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);

  const viewAllVideoHandler = (id) => {
    if (userDetails === null) {
      swal("Failed!", "Please Login First", "error");
      setLoginModal(true);
    } else {
      const path = generatePath(`/course/${id}`);
      navigate(path, { state: { categoryId: id } });
    }
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };

  const onFillterHandler = () => {
    if (showList) {
      setShowList(false);
    } else {
      setShowList(true);
    }
  };
  const onSearchHandler = (e) => {
    let findList = filterList.find((item) => item === e.target.name);
    if (findList === undefined) {
      setFilterList([...filterList, e.target.name]);
    } else {
      let allList = filterList.filter((item) => item !== e.target.name);
      setFilterList(allList);
    }

    let matchData = searchData.filter((item) => filterList.includes(item.course_name));
    if (matchData.length > 0) {
      setCourseObj(matchData);
    } else {
      setCourseObj(courseObj);
    }
  };

  useEffect(() => {
    let matchData = searchData.filter((item) => filterList.includes(item.course_name));
    if (matchData.length > 0) {
      setCourseObj(matchData);
    } else {
      setCourseObj(searchData);
    }
    // eslint-disable-next-line
  }, [filterList]);

  return (
    <>
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <CustomeBanner
        width="100%"
        bgImage={CourseBg}
        title="Learn Bhartiya Science"
        desc="Welcome to Adhigyanam, your gateway to the revival of ancient Bhartiya Sciences. Inspired by the revered Gurukul system, we offer an Online E-Learning Platform where enthusiasts can explore Prediction Sciences & Vedic Scriptures. Our courses cover Astrology, Numerology, Palmistry, Sanskrit Language, and more, presented by experienced Jyotishacharyas and Teachers in easy-to-understand videos. We aim to bridge the gap between the past and present, making the profound knowledge of Ancient Bhartiya Sciences accessible to all. Join us in our mission to revive Bhartiya Sciences, contributing to a global renaissance. Embrace the wisdom of the ages at Adhigyanam."
      />
      <div className={style.container}>
        <div className={style.container__tittleArea}>
          <CourseTitle marginBottom="0" />
          <div className={style.container__tittleArea__tittle__fillterArea}>
            <span onClick={onFillterHandler} className={showList ? style.active : ""}>
              Fillter <i className="fa-solid fa-filter"></i>
            </span>

            {showList && (
              <div className={style.container__tittleArea__tittle__fillterArea__checkBox}>
                {filterInputList?.map((item) => {
                  return (
                    <div>
                      <input onChange={onSearchHandler} type="checkbox" name={item.course_name} id={item.course_name} />
                      <label htmlFor={item.course_name}>{item.course_name}</label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {loading && (
          <div style={{ zIndex: 200 }} className={style.container__loader}>
            <Loading />
          </div>
        )}

        {!loading && (
          <>
            {courseObj.length <= 0 ? (
              <span className={style.emptyMsg}>
                <img src={noDataFoundImg} alt="err" />
              </span>
            ) : (
              <>
                <div className={style.container__courses}>
                  {courseObj?.map((item, index) => {
                    return (
                      <CourseCard
                        key={index}
                        title={item.course_name}
                        topic={item.course_duration}
                        image={item.course_icon ? item?.course_icon : cardImg}
                        sub_title={item.sub_title}
                        trainer={item.trainer_name}
                        btnName="View All"
                        height="35px"
                        backgroundColor="$eight-color"
                        marginTop="0"
                        textTransform="capitalize"
                        fontSize="16px"
                        marginBottom="0"
                        rating={item.rating}
                        label={item.course_label}
                        priceDisplay="none"
                        onClick={() => viewAllVideoHandler(item.id)}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <ToastContainer style={{ height: "200px" }} />
    </>
  );
};

export default Course;
