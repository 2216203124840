import React, { useContext, useEffect, useState } from "react";
import style from "./index.module.scss";
import Button from "../../widgets/Button";
import CommonTitle from "../../components/CommonTitle";
import AstroDetCard from "./AstroDetCard";
import Item from "./Item";
import Graph from "./Graph";
import Modal from "../../modal";
import CustomizeSlot from "../../modal/CustomizeSlot";
// import ModalLayout from "../../components/ModalLayout";
import HistorySection from "../../components/HistorySection";
import { Context } from "../../utils/ContextStor";
import { useNavigate } from "react-router-dom";
import dummyImg from "../../assets/dummyImg.jpg";
import axios from "axios";
import Loading from "../../components/Loading";

const AstrologerDetails = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("dashboard");
  const [openModal, setOpenModal] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [bookCountData, setBookCountData] = useState([]);
  // eslint-disable-next-line
  const [token, setToken, updatedUser, setUpdatedUser] = useContext(Context);
  // ******************Graph**************************

  const bookingData = [
    {
      color: "#33b6ac",
      points: bookCountData.map((item, index) => {
        return { x: item.month_name.slice(0, 3), y: item.count };
      }),
    },
  ];
  // **********************Get Booking Count************************
  useEffect(() => {
    setIsLoading(true);
    let getData = {
      url: "https://admin.adhigyanam.com/api/getBookingCounts",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(getData)
      .then((res) => {
        setBookCountData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, [token]);
  // *************************************************

  const openCustomizeHandler = (name) => {
    setOpenModal(true);
    setActive(name);
  };

  const onCloseHandler = () => {
    setOpenModal(false);
    setActive("dashboard");
  };

  // ************************* Booking Section ********************************
  const openBookingsHandler = (name) => {
    navigate("/my-bookings");
  };

  // const onHistoryHandler = (name) => {
  //   if (active === "history") {
  //     setActive("dashboard");
  //   } else {
  //     setActive(name);
  //   }
  // };
  return (
    <>
      {openModal && (
        <Modal width="80%" onClose={onCloseHandler}>
          <CustomizeSlot
            setOpenModal={setOpenModal}
            onCloseHandler={onCloseHandler}
          />
        </Modal>
      )}

      {isLoading ? (
        <div className={style.loader}>
          <Loading />
        </div>
      ) : (
        <div className={style.astrologerDetails}>
          <div className={style.astrologerDetails__top}>
            <CommonTitle />
            <div className={style.astrologerDetails__top__btn}>
              <Button
                onClick={() => openCustomizeHandler("customize")}
                border="1px solid #bc5959"
                backgroundColor={active === "customize" ? "#155651" : "#fff"}
                color={active === "customize" ? "#fff" : "#000"}
                textTransform="capitalize"
                fontSize="18px"
                marginTop="0"
                btnName="Customize Slot"
              />
              <Button
                border="1px solid #bc5959"
                fontSize="18px"
                backgroundColor={active === "bookings" ? "#155651" : "#fff"}
                color={active === "bookings" ? "#fff" : "#000"}
                marginTop="0"
                textTransform="capitalize"
                btnName="Bookings"
                onClick={() => openBookingsHandler("bookings")}
              />
              {/* <Button
              border="1px solid #bc5959"
              fontSize="18px"
              backgroundColor={active === "history" ? "#155651" : "#fff"}
              color={active === "history" ? "#fff" : "#000"}
              marginTop="0"
              textTransform="capitalize"
              btnName="History"
              onClick={() => onHistoryHandler("history")}
            /> */}
            </div>
          </div>

          <div className={style.astrologerDetails__bottom}>
            <div className={style.astrologerDetails__bottom__det}>
              <AstroDetCard
                img={
                  updatedUser?.profile_ic ? updatedUser?.profile_ic : dummyImg
                }
                ratting="4.5"
                name={updatedUser?.name}
                exprience={`Exp : ${updatedUser?.experience} years`}
                detail={updatedUser?.speciality}
                numOfCun={updatedUser?.no_of_consultations}
                id={updatedUser?.id}
              />
            </div>

            {active === "history" && (
              <div className={style.booking}>
                <HistorySection />
              </div>
            )}
            {active === "dashboard" && (
              <div className={style.astrologerDetails__bottom__graph}>
                <div className={style.astrologerDetails__bottom__graph__header}>
                  <h4>Execution & Monitoring</h4>
                  <div
                    className={
                      style.astrologerDetails__bottom__graph__header__right
                    }
                  >
                    <Item backgroundColor="#33b6ac" tag="Bookings" />
                    {/* <Item backgroundColor="#e28056" tag="Consultant" /> */}
                  </div>
                </div>
                <Graph data={bookingData} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AstrologerDetails;
