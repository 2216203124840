import React, { useCallback, useContext, useEffect } from "react";
import Styles from "./index.module.scss";
import tittleImg from "../../assets/p7.webp";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import CommonInputField from "../../widgets/CommonInputField";
import Button from "../../widgets/Button";
import DragAndDrop from "../../components/DragAndDrop";
import hand1 from "../../assets/hand1.png";
import hand2 from "../../assets/hand2.png";
import hand3 from "../../assets/hand3.png";
import hand4 from "../../assets/hand4.png";
import { useState } from "react";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import Modal from "../../modal";
import BookSlot from "../../modal/BookSlot";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneNumber from "../../components/PhoneInput";
import CommonSelect from "../../components/CommonSelect";
import swal from "sweetalert";
import { Context } from "../../utils/ContextStor";
import Login from "../../modal/Login";
import SignUp from "../../modal/SignUp";
import { loadScript } from "../../helpers/razorpay";
import Logo from "../../assets/LOGO.webp";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import ModalLayout from "../../components/ModalLayout";
import closeImg from "../../assets/close.svg";

const PalmReadingForm = () => {
  const genderList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Male" },
    { id: 3, name: "Female" },
    { id: 4, name: "Other" },
  ];
  const languageList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Hindi" },
    { id: 3, name: "English" },
  ];
  const [signup, setSignup] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [phone, setPhone] = useState("");

  const [selectedDate, setSelectedDate] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [slotTime, setSlotTime] = useState("Your slot time");
  const [slotDate, setSlotDate] = useState("Your slot date");
  const [image1, setImage1] = useState("");
  const [document1, setDucument1] = useState();
  const [showDoc, setShowDoc] = useState();
  const [image2, setImage2] = useState("");
  const [document2, setDucument2] = useState();
  const [showDoc2, setShowDoc2] = useState();
  const [image3, setImage3] = useState("");
  const [document3, setDucument3] = useState();
  const [showDoc3, setShowDoc3] = useState();
  const [image4, setImage4] = useState("");
  const [document4, setDucument4] = useState();
  const [showDoc4, setShowDoc4] = useState();
  const [submit, setSubmit] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const navigate = useNavigate();
  const params = useParams();
  const [palmistryData, setPalmistryData] = useState({
    name: "",
    Language: languageList[0].name,
    Gender: genderList[0].name,
    email: "",
  });
  // ********************************Payment Conde*****************************
  const [paymentModal, setPaymentModal] = useState(false);
  // *************************************************************************

  const formObj = [
    {
      inputName: "Name",
      inputType: "text",
      plaholder: "Name",
      display: "none",
      id: "name",
      value: palmistryData.name,
    },
    {
      inputName: "dob",
      inputType: "text",
      plaholder: "dob",
      display: "none",
      id: "dob",
      value: palmistryData.name,
    },
    {
      inputName: "gender",
      inputType: "text",
      plaholder: "gender",
      display: "none",
      id: "gender",
      value: palmistryData.name,
    },
    {
      inputName: "Email Id",
      inputType: "email",
      plaholder: "Email Id",
      display: "none",
      id: "email",
      value: palmistryData.email,
    },
  ];
  let astroData = JSON.parse(localStorage.getItem("allConsultationDataForPalm"));
  const user = JSON.parse(localStorage.getItem("user"));
  let pathId = JSON.parse(localStorage.getItem("predictionId"));

  const filesHandlerOne = (e) => {
    let path = e.target.files[0];
    setImage1(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument1(image);
      setShowDoc(true);
    }
  };
  const filesHandlerTwo = (e) => {
    let path = e.target.files[0];
    setImage2(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument2(image);
      setShowDoc2(true);
    }
  };
  const filesHandlerThree = (e) => {
    let path = e.target.files[0];
    setImage3(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument3(image);
      setShowDoc3(true);
    }
  };
  const filesHandlerFour = (e) => {
    let path = e.target.files[0];
    setImage4(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDucument4(image);
      setShowDoc4(true);
    }
  };

  const onChangeHandler = (e, id) => {
    setPalmistryData({
      ...palmistryData,
      [id]: e.target.value,
    });
  };
  // ********************** Submit Data *****************
  const onSelectedSlot = (date, time) => {
    setSlotDate(date);
    setSlotTime(time);
    setModalIsVisible(false);
  };
  // ******************************************************************
  const onSelectDateHandler = (date) => {
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    setStartDate(date);
  };

  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);

    // eslint-disable-next-line
  }, []);
  // *************************Submit***********************
  const bookingHandler = async (paymentId, paymentOption) => {
    setSubmit(false);
    const formdata = new FormData();
    formdata.append("astrologer_id", astroData?.asrtoDetail?.astrologerID);
    formdata.append("consultation_type", astroData?.consultationType);
    formdata.append("consultation_id[]", astroData?.consultationList);
    formdata.append("name", palmistryData.name);
    formdata.append("gender", palmistryData.Gender);
    formdata.append("language", palmistryData.Language);
    formdata.append("date_of_birth", selectedDate);
    formdata.append("emailid", palmistryData.email);
    formdata.append("contact_no", phone);
    formdata.append("sample1", image1);
    formdata.append("sample2", image2);
    formdata.append("sample3", image3);
    formdata.append("sample4", image4);
    formdata.append("slot_time", slotTime);
    formdata.append("slot_date", slotDate);
    formdata.append("predection_id", pathId);
    formdata.append("consultation_type_id", astroData?.consultationTypeId);
    formdata.append("payment_id", paymentId);
    formdata.append("pg_type", paymentOption);

    const postData = {
      url: endpoints.palmReadStor,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: formdata,
    };
    await axios(postData)
      .then((res) => {
        if (res.data.status === true) {
          swal("Successful!", "Submit Successfull!", "success");
          setSubmit(true);
          window.location.replace(res.data.url);
        } else {
          swal("Failed!", "Sorry :(", res.data.message);
          setSubmit(true);
        }
      })
      .catch((err) => {
        // console.log(err, "err");
      });
  };
  // **********************************************************
  const onSubmitHandler = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    // ****************
    if (palmistryData.name === "") {
      setSubmit(true);
      swal("Failed!", "Name is required !", "error");
    } else if (specialChars.test(palmistryData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters or number", "error");
    } else if (selectedDate === "") {
      setSubmit(true);
      swal("Failed!", "Date Of Birth is required !", "error");
    } else if (palmistryData.Gender === "Select") {
      setSubmit(true);
      swal("Failed!", "Gender is required !", "error");
    } else if (palmistryData.email === "" || !palmistryData.email.includes("@")) {
      setSubmit(true);
      swal("Failed!", "Please enter valid email !", "error");
    } else if (phone === "" || phone === undefined) {
      setSubmit(true);
      swal("Failed!", "Phone Number is required!", "error");
    } else if (palmistryData.Language === "Select") {
      setSubmit(true);
      swal("Failed!", "Language is required !", "error");
    } else if (image1 === "") {
      setSubmit(true);
      swal("Failed!", "Image is required !", "error");
    } else if (image2 === "") {
      setSubmit(true);
      swal("Failed!", "Image is required !", "error");
    } else if (image3 === "") {
      setSubmit(true);
      swal("Failed!", "Image is required !", "error");
    } else if (image4 === "") {
      setSubmit(true);
      swal("Failed!", "Image is required !", "error");
    } else if (slotDate === "Your slot date") {
      setSubmit(true);
      swal("Failed!", "Select any Slots Date", "error");
    } else if (slotTime === "Your slot time") {
      setSubmit(true);
      swal("Failed!", "Select any Slots Time", "error");
    } else if (paymentOption === "") {
      setPaymentModal(true);
    } else {
      if (user) {
        // **********************************RazorPay**************************************
        if (paymentOption === "razorpay") {
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            alert("You are offline ...Failed to load Razorpay SDK", {
              type: "warning",
            });
            return;
          } else {
            const options = {
              key: "rzp_test_gZte4p2li4zBnk",
              currency: "INR",
              amount: astroData?.planPrice * 100,
              name: "Adhigyanam",
              description: "Thanks for purchasing",
              image: Logo,
              prifill: {
                name: user?.name, //your customer's name
                email: user?.email,
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
              handler: function (response) {
                if (response) {
                  bookingHandler(response.razorpay_payment_id, paymentOption);
                } else {
                  swal("Failed!", "Payment Failed!", "error");
                }
              },
              // callback_url: "https://admin.adhigyanam.com/razorpay-response",
              // redirect: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        } else {
          bookingHandler("", paymentOption);
        }
      } else {
        swal("Failed!", "Login first and Proceed to Pay", "error");
        setSubmit(true);
        setLoginModal(true);
      }
    }
  };
  const onCancelHandler = () => {
    setPalmistryData({
      name: "",
      Language: languageList[0].name,
      Gender: genderList[0].name,
      email: "",
    });

    setSlotDate("Your slot date");
    setSlotTime("Your slot time");
    setPhone("");
  };
  const onChooseSlotHandler = () => {
    setModalIsVisible(true);
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
    setPaymentModal(false);
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };
  // **************************Loging************************************
  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };
  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
  };

  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onSubmitHandler(payOption);
  };

  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };

  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseMoadal}>
          <Modal onClose={onCloseMoadal} height="auto" overflowY="auto" width="50%">
            <div className={Styles.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={Styles.paymentOption__cros} onClick={onCloseMoadal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={Styles.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}
      {modalIsVisible && (
        <Modal onClose={onCloseMoadal}>
          <BookSlot display="none" width="100%" padding="0" onClick={onSelectedSlot} modalIsVisible={modalIsVisible} />
        </Modal>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div className={Styles.modalArea}>
        <div className={Styles.modalArea__tittleArea}>
          <CommanTitleWithLogo tittle={params.name} icon={tittleImg} height="60px" width2="60px" width="320px" />
        </div>
        <div className={Styles.modalArea__modal}>
          <div className={Styles.modalArea__modal__left}>
            <div className={Styles.modalArea__modal__left__tittle}>
              <h2>Please Fill The Form</h2>
            </div>
            <div className={Styles.modalArea__modal__left__formArea}>
              <div className={Styles.modalArea__modal__left__formArea__formInput}>
                {formObj.map((item, index) => {
                  if (item.id === "dob") {
                    return (
                      <div className={Styles.modalArea__modal__left__formArea__select}>
                        <p>Date of Birth</p>
                        <DatePicker
                          maxDate={new Date()}
                          className={Styles.datePicker}
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={onSelectDateHandler}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    );
                  } else if (item.id === "gender") {
                    return <CommonSelect value={palmistryData.Gender} label="Gender" list={genderList} onChange={onChangeHandler} />;
                  } else {
                    return (
                      <CommonInputField
                        onChange={(e) => onChangeHandler(e, item.id)}
                        key={index}
                        type={item.inputType}
                        placeholder={item.inputName}
                        display={item.display}
                        value={item.value}
                      />
                    );
                  }
                })}
                <div className={Styles.modalArea__modal__left__formArea__select}>
                  <p>Enter Phone</p>
                  <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                </div>

                <CommonSelect value={palmistryData.Language} label="Language" list={languageList} onChange={onChangeHandler} />
                <div>
                  <Button
                    onClick={onChooseSlotHandler}
                    btnName="Choose Slot"
                    width="100%"
                    marginBottom="0"
                    marginTop="25px"
                    textTransform="capitalize"
                    fontSize="18px"
                  />
                </div>
                <p className={Styles.modalArea__modal__left__formArea__timeSlot}>{slotTime}</p>
                <p className={Styles.modalArea__modal__left__formArea__timeSlot}>{slotDate}</p>
                {/* {paymentOption && (
                  <div className={Styles.showPayment}>
                    <p>
                      Payment Option : <span>{paymentOption}</span>{" "}
                    </p>
                    <span>
                      <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                    </span>
                  </div>
                )} */}
              </div>
            </div>
            <div className={Styles.modalArea__modal__left__buttonArea}>
              <div>
                <Button
                  btnName="Cancel"
                  backgroundColor="#d4d4d4"
                  color="#444"
                  fontSize="18px"
                  border="1px solid #d4d4d4"
                  width="200px"
                  textTransform="capitalize"
                  onClick={onCancelHandler}
                />
              </div>
              <div>
                <Button
                  onClick={() => onSubmitHandler("")}
                  btnName={submit ? "Submit" : "Processing.."}
                  fontSize="18px"
                  width="200px"
                  textTransform="capitalize"
                  backgroundColor="#1eb2a6"
                />
              </div>
            </div>
          </div>
          <div className={Styles.modalArea__modal__right}>
            <div className={Styles.modalArea__modal__right__dragAndDrop}>
              <div className={Styles.modalArea__modal__right__dragAndDrop__sample}>
                <h2>Sample Images</h2>
                <div className={Styles.modalArea__modal__right__dragAndDrop__sample__images}>
                  <img src={hand1} alt="" />
                  <img src={hand2} alt="" />
                  <img src={hand3} alt="" />
                  <img src={hand4} alt="" />
                </div>
              </div>

              <div className={Styles.modalArea__modal__right__dragAndDrop__dropField}>
                <div>
                  <DragAndDrop filesHandler={filesHandlerOne} image={document1} showFile={showDoc} />
                </div>
                <div>
                  <DragAndDrop filesHandler={filesHandlerTwo} image={document2} showFile={showDoc2} />
                </div>
                <div>
                  <DragAndDrop filesHandler={filesHandlerThree} image={document3} showFile={showDoc3} />
                </div>
                <div>
                  <DragAndDrop filesHandler={filesHandlerFour} image={document4} showFile={showDoc4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PalmReadingForm;
