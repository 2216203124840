import React, { useState } from "react";
import Styles from "./index.module.scss";
import bigImg from "../../assets/banside.webp";
import CommonTitle from "../../components/CommonTitle";
import Button from "../../widgets/Button";
import CommonInputField from "../../widgets/CommonInputField";
import CommanTitleWithLogo from "../../components/CommanTitleWithLogo";
import individual from "../../assets/individual.png";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import Login from "../../modal/Login";
import Modal from "../../modal";
import { useEffect } from "react";
import { useCallback } from "react";
import { useContext } from "react";
import { Context } from "../../utils/ContextStor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneNumber from "../../components/PhoneInput";
import SignUp from "../../modal/SignUp";
import CommonSelect from "../../components/CommonSelect";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { loadScript } from "../../helpers/razorpay";
import Logo from "../../assets/LOGO.webp";
import PaymentOption from "../../components/PaymentOption";
import phonPayimg from "../../assets/PhonePe_Logo.svg.png";
// eslint-disable-next-line
import razorPayimg from "../../assets/Razorpay_payments.png";
import ModalLayout from "../../components/ModalLayout";
import closeImg from "../../assets/close.svg";
const IndividualConsultationForm = () => {
  const genderList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Male" },
    { id: 3, name: "Female" },
    { id: 4, name: "Other" },
  ];
  const languageList = [
    { id: 1, name: "Select" },
    { id: 2, name: "Hindi" },
    { id: 3, name: "English" },
  ];
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [phone, setPhone] = useState("");
  const [signup, setSignup] = useState(false);

  let astroData = JSON.parse(localStorage.getItem("allConsultationData"));
  // eslint-disable-next-line
  const [token, setToken] = useContext(Context);
  const user = JSON.parse(localStorage.getItem("user"));
  // ********************************Payment Conde*****************************
  const [paymentModal, setPaymentModal] = useState(false);
  // *************************************************************************
  const [submit, setSubmit] = useState(true);
  const [loginModal, setLoginModal] = useState(false);
  const [individualData, setIndividualData] = useState({
    name: "",
    tob: "",
    pob: "",
    email: "",
    Gender: genderList[0].name,
    Language: languageList[0].name,
  });
  const navigate = useNavigate();
  const formObj = [
    {
      value: individualData.name,
      id: "name",
      type: "text",
      placeholder: "Name",
      name: "name",
      display: "none",
    },
    {
      value: individualData.tob,
      id: "dob",
      type: "dob",
      placeholder: "Date Of Birth",
      name: "dob",
      display: "block",
    },
    {
      value: individualData.tob,
      id: "tob",
      type: "time",
      placeholder: "Time Of Birth",
      name: "tob",
      display: "block",
    },
    {
      value: individualData.pob,
      id: "pob",
      type: "text",
      placeholder: "Place Of Birth",
      name: "pob",
      display: "none",
    },
    {
      value: individualData.pob,
      id: "gender",
      type: "text",
      placeholder: "gender",
      name: "gender",
      display: "none",
    },
    {
      value: individualData.email,
      id: "email",
      type: "text",
      placeholder: "Email Id",
      name: "email",
      display: "none",
    },
  ];

  // ************************* Form Data *************************
  const onChangeHandler = (e, id) => {
    setIndividualData({
      ...individualData,
      [id]: e.target.value,
    });
  };

  // **************************** Submit or Cancel  Data **************************
  // *************************Submit***********************
  const bookingHandler = async (paymentId, paymentOption) => {
    const formData = new FormData();
    formData.append("astrologer_id", astroData.asrtoDetail.astrologerID);
    formData.append("consultation_type", astroData.consultationType.replace("_", " "));
    formData.append("consultation_service_id[]", astroData.consultationList);
    formData.append("predection_type_id", astroData.asrtoDetail.serviceType);
    formData.append("predection_id", astroData.asrtoDetail.servicId);
    formData.append("name", individualData.name);
    formData.append("gender", individualData.Gender);
    formData.append("language", individualData.Language);
    formData.append("date_of_birth", selectedDate);
    formData.append("time_of_birth", individualData.tob);
    formData.append("place_of_birth", individualData.pob);
    formData.append("emailid", individualData.email);
    formData.append("contact_no", phone);
    formData.append("slot_time", astroData.slot_time);
    formData.append("slot_date", astroData.slot_date);
    formData.append("consultation_type_id", astroData.consultationTypeId);
    formData.append("payment_id", paymentId);
    formData.append("pg_type", paymentOption);

    const postData = {
      url: endpoints.individualConsultantStor,
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      data: formData,
    };

    axios(postData)
      .then((res) => {
        if (res.data.status === true) {
          window.location.replace(res.data.url);
          setSubmit(true);
          setIndividualData({
            name: "",
            tob: "",
            pob: "",
            email: "",
            Gender: genderList[0].name,
            Language: languageList[0].name,
          });
        } else {
          setSubmit(true);
          swal("Sorry :(", res.data.message);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };
  const onSubmitHandler = async (paymentOption) => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    setSubmit(false);

    // ********************* Post Data***************
    if (individualData.name === "") {
      setSubmit(true);
      swal("Failed!", "Name is required !", "error");
    } else if (specialChars.test(individualData.name)) {
      setSubmit(true);
      swal("Failed!", "Name should not contain special characters", "error");
    } else if (selectedDate === new Date()) {
      setSubmit(true);
      swal("Failed!", "Date Of Birth is required !", "error");
    } else if (individualData.tob === "") {
      setSubmit(true);
      swal("Failed!", "Time Of Birth is required !", "error");
    } else if (individualData.pob === "") {
      setSubmit(true);
      swal("Failed!", "Place Of Birth is required !", "error");
    } else if (individualData.Gender === "Select") {
      setSubmit(true);
      swal("Failed!", "Gender is required !", "error");
    } else if (individualData.email === "" || !individualData.email.includes("@") || !individualData.email.includes(".")) {
      setSubmit(true);
      swal("Failed!", "Enter valid Email !", "error");
    } else if (phone === "" || phone === undefined) {
      setSubmit(true);
      swal("Failed!", "Please enter phone no. !", "error");
    } else if (individualData.Language === "Select") {
      setSubmit(true);
      swal("Failed!", "Language is required !", "error");
    } else if (!user) {
      setSubmit(true);
      swal("Failed!", "Login First And Proceed to Pay!", "error");
      setLoginModal(true);
    } else if (paymentOption === "") {
      setSubmit(true);
      setPaymentModal(true);
    } else {
      // **********************************RazorPay**************************************

      if (paymentOption === "razorpay") {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (!res) {
          alert("You are offline ...Failed to load Razorpay SDK", {
            type: "warning",
          });
          return;
        } else {
          const options = {
            key: "rzp_test_gZte4p2li4zBnk",
            currency: "INR",
            amount: astroData?.planPrice * 100,
            name: "Adhigyanam",
            description: "Thanks for purchasing",
            image: Logo,
            prifill: {
              name: user?.name, //your customer's name
              email: user?.email,
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
            handler: function (response) {
              if (response) {
                bookingHandler(response.razorpay_payment_id, paymentOption);
              } else {
                swal("Failed!", "Payment Failed!", "error");
              }
            },
            // callback_url: "https://admin.adhigyanam.com/razorpay-response",
            // redirect: true,
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      } else {
        bookingHandler("", paymentOption);
      }
    }
  };

  const onCancelHandler = () => {
    setIndividualData({
      name: "",
      tob: "",
      pob: "",
      email: "",
      Gender: genderList[0].name,
      Language: languageList[0].name,
    });
    setPhone("");
  };

  const onCloseModal = () => {
    setLoginModal(false);
    setSignup(false);
    setPaymentModal(false);
  };

  const onLogin = useCallback(async (userDetails) => {
    setToken(userDetails.token);
    setLoginModal(false);
    const filteredData = {
      name: userDetails.name,
      email: userDetails.email,
      token: userDetails.token,
      profile_ic: userDetails.profile_ic,
      user_type: userDetails.user_type,
      speciality: userDetails.speciality,
      experience: userDetails.experience,
      id: userDetails.id,
      date_of_birth: userDetails.date_of_birth,
      place_of_birth: userDetails.place_of_birth,
      time_of_birth: userDetails.time_of_birth,
      gender: userDetails.Gender,
      contact_no: userDetails.contact_no,
      language: userDetails.Language,
    };
    localStorage.setItem("token", userDetails.token);
    localStorage.setItem("user", JSON.stringify(filteredData));
    if (userDetails.user_type === "Astrologer" || userDetails.user_type === 1) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let userDetails = JSON.parse(user);

    if (userDetails && userDetails.token) {
      onLogin(userDetails);
    }
  }, [onLogin]);

  // *******************************************************************
  const onSelectDateHandler = (date) => {
    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    setStartDate(date);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    // eslint-disable-next-line
  }, []);

  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };
  const onSignupHandler = () => {
    setLoginModal(false);
    setSignup(true);
  };
  const onCancelSignUp = () => {
    setLoginModal(true);
    setSignup(false);
  };

  const onClickPaymentOption = (payOption) => {
    setPaymentModal(false);
    onSubmitHandler(payOption);
  };

  // const onShowPaymentModal = () => {
  //   setPaymentModal(true);
  // };
  return (
    <>
      {paymentModal && (
        <ModalLayout onCloseHandler={onCloseModal}>
          <Modal onClose={onCloseModal} height="auto" overflowY="auto" width="50%">
            <div className={Styles.paymentOption}>
              <h1>Choose Payment Option</h1>
              <span className={Styles.paymentOption__cros} onClick={onCloseModal}>
                <img src={closeImg} alt="close" />
              </span>
              <div className={Styles.paymentOption__option}>
                <PaymentOption
                  name="pay"
                  img={phonPayimg}
                  id="phonepay"
                  value="phonepay"
                  onClick={() => onClickPaymentOption("phonepay")}
                />
                {/* <PaymentOption
                  name="pay"
                  img={razorPayimg}
                  id="razorpay"
                  value="razorpay"
                  onClick={() => onClickPaymentOption("razorpay")}
                /> */}
              </div>
            </div>
          </Modal>
        </ModalLayout>
      )}
      {loginModal && (
        <Modal onClose={onCloseModal}>
          <Login onSignupHandler={onSignupHandler} onCloseHandler={onCloseModal} onLoginHandler={onLogin} />
        </Modal>
      )}
      {signup && (
        <Modal onClose={onCloseModal}>
          <SignUp setSignup={setSignup} onCloseHandler={onCloseModal} onCancelSignUp={onCancelSignUp} />
        </Modal>
      )}
      <div className={Styles.modalArea}>
        <CommanTitleWithLogo tittle="Individual Consultation" icon={individual} />
        <div className={Styles.modalArea__modal}>
          <div className={Styles.modalArea__modal__tittle}>
            <CommonTitle tittle="Please Fill The Form" />
          </div>
          <div className={Styles.modalArea__modal__formArea}>
            <div className={Styles.modalArea__modal__formArea__input}>
              {formObj.map((item) => {
                if (item.id === "dob") {
                  return (
                    <div className={Styles.modalArea__modal__formArea__select}>
                      <p>Date of Birth</p>
                      <DatePicker
                        maxDate={new Date()}
                        className={Styles.datePicker}
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={onSelectDateHandler}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  );
                } else if (item.id === "gender") {
                  return <CommonSelect value={individualData.Gender} label="Gender" list={genderList} onChange={onChangeHandler} />;
                } else {
                  return (
                    <CommonInputField
                      onChange={(e) => onChangeHandler(e, item.id)}
                      key={item.id}
                      id={item.name}
                      value={item.value}
                      placeholder={item.placeholder}
                      display={item.display}
                      type={item.type}
                      name={item.name}
                      borderColor="#1eb2a6"
                    />
                  );
                }
              })}
              <div className={Styles.modalArea__modal__formArea__select}>
                <p>Enter Phone</p>
                <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
              </div>

              <CommonSelect value={individualData.Language} label="Language" list={languageList} onChange={onChangeHandler} />
              {/* {paymentOption && (
                <div className={Styles.showPayment}>
                  <p>
                    Payment Option : <span>{paymentOption}</span>{" "}
                  </p>
                  <span>
                    <i onClick={onShowPaymentModal} class="fa-solid fa-pen-to-square"></i>
                  </span>
                </div>
              )} */}
            </div>

            <div className={Styles.modalArea__modal__formArea__formImage}>
              <div className={Styles.modalArea__modal__formArea__formImage__image}>
                <img src={bigImg} alt="error" />
              </div>
            </div>
          </div>
          <div className={Styles.modalArea__modal__buttonArea}>
            <Button
              onClick={onCancelHandler}
              btnName="Cancel"
              fontSize="20px"
              backgroundColor="#c4c4c4"
              border="1px solid #c4c4c4"
              color="#333"
              textTransform="capitalize"
              width="70%"
            />
            <Button
              onClick={() => onSubmitHandler("")}
              btnName={submit ? "Submit" : "Processing.."}
              fontSize="20px"
              textTransform="capitalize"
              width="70%"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualConsultationForm;
