import React from "react";
import img from "../../assets/newassets/comingSoon.png";
import Styles from "./index.module.scss";
const Numrology = () => {
  return (
    <div className={Styles.container}>
      <div>
        {" "}
        <img src={img} alt="error" />
      </div>
    </div>
  );
};

export default Numrology;
