import React from "react";
import style from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Info = () => {
  return (
    <div className={style.contactinfo}>
      <div className={style.contactinfo__det}>
        <div className={style.contactinfo__det__add}>
          <FontAwesomeIcon color="$eight-color" icon={faLocationDot} size="lg" />
          <p>Sector 4, Vikas Nagar, Lucknow</p>
        </div>
        <a href="https://wa.me/8960708425">
          <div className={style.contactinfo__det__phone}>
            <i style={{ fontSize: "24px" }} className="fa-brands fa-square-whatsapp"></i>
            <p>(+91) 8960708425</p>
          </div>
        </a>
        <div className={style.contactinfo__det__email}>
          <FontAwesomeIcon color="$eight-color" icon={faEnvelope} size="lg" />
          <p>adhigyanam@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default Info;
