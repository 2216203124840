import React from "react";
import style from "./index.module.scss";
import Close from "../../widgets/Close";

const ModalLayout = (props) => {
  return (
    <div
      className={style.modalLayout}
      style={{ width: `${props.width}`, padding: `${props.padding}`, borderRadius: `${props.borderRadius}` }}>
      <Close onCloseHandler={props.onCloseHandler} />

      {props.children}
    </div>
  );
};

export default ModalLayout;
