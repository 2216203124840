import React from "react";
import Styles from "./index.module.scss";
const CommonTitle = ({ tittle, width, color }) => {
  return (
    <div style={{ width: `${width}`, color: `${color}` }} className={Styles.tittle}>
      {tittle}
    </div>
  );
};

export default CommonTitle;
