import React from "react";
import cloud from "../../assets/cloud.png";
import style from "./index.module.scss";

const DragAndDrop = (props) => {
  let { image, showFile } = props;

  const filesOnChange = (e, id) => {
    props.filesHandler(e, id);
  };
  return (
    <div className={style.dragAndDrop} style={{ width: `${props.width}`, height: `${props.pHeight}` }}>
      <div style={{ height: `${props.height}` }} className={style.dragAndDrop__drop}>
        {!showFile ? (
          <>
            <div className={style.dragAndDrop__drop__img}>
              <img src={cloud} alt="dragdrop" />
            </div>
            {props.screen === "regis" ? (
              <p>UPLOAD DOCUMENT</p>
            ) : (
              <p style={{ fontSize: `${props.fontSize}` }}>
                Drag & Drop <span>Files Here</span>
              </p>
            )}
          </>
        ) : (
          <div
            className={style.dragAndDrop__drop__files}
            style={{
              backgroundImage: `url(${image})`,
              height: "100%",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}></div>
        )}
        <input id={props.id} onChange={(e) => filesOnChange(e, props.id)} className={style.dragAndDrop__drop__file} accept={`image/jpeg, image/png,${props.pdf}`} type="file" />
      </div>
      <p className={style.dragAndDrop__para} style={{ fontSize: `${props.fontSize}`, marginTop: `${props.marginTop}` }}>
        {props.fileType}
        <span>{props.type}</span>
      </p>
    </div>
  );
};

export default DragAndDrop;
