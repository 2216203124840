import React from "react";
import styles from "./index.module.scss";

const PricingPolicy = () => {
  return (
    <div className={styles.pricingPolicy}>
      <h1>Pricing Policy</h1>
      <p>
        At adhigyanam.com, our pricing strategy is tailored to reflect the value of the services we provide. We ensure complete transparency
        by furnishing you with detailed pricing information based on factors such as the complexity of the task, the efficiency involved,
        and the resulting outcomes. The scope of financial transactions conducted through our Android and iOS applications generally spans
        from INR 351 to 22,001 per user per session.
      </p>
      <p>
        <b>Payment Schedule:</b> For certain services, we offer fixed-duration options. These specific time frames, ranging from 1 Day to 6
        Months, are clearly outlined within the service descriptions.
      </p>
      <p>
        <b>Price Match Guarantee:</b> Dedicated to your satisfaction, we are steadfast in presenting the most competitive prices at
        adhigyanam.com. If you come across a service identical in nature, professionalism, and features, offered by a comparable service
        provider at a lower price, we're more than willing to match that price. It's important to note that our pricing remains consistent
        irrespective of market fluctuations or competitor rates.
      </p>
      <p>
        <b>Adjustments for Sales:</b> Should you notice a reduction in the price of a service you've purchased within one week from your
        booking date, unfortunately, we're unable to retroactively modify the sale price for you. Kindly be aware that slot bookings are
        generally non-reschedulable; any attempt to do so may result in the cancellation of the corresponding booking or order. For further
        information, please review our cancellation policies.
      </p>
      <p>
        <b>Pricing Approach:</b> We exert diligent effort to maintain accurate pricing information. Nonetheless, occasional pricing errors
        might arise. If the indicated price for a service exceeds its actual cost, we will promptly void your booking and communicate the
        cancellation to you.
      </p>
      <p>
        <b>Service Sale Conditions:</b> Our services, offered by Saunteshthyam LLP, are intended for personal utilisation. We reserve the
        right to withhold sales from individuals suspected of misusing our services.
      </p>
      <p>
        <b>Contact Us:</b> For any inquiries or assistance, please don't hesitate to reach out to us at:
        <p>
          <b>support.saunteshthyam@gmail.com</b>
        </p>
      </p>
    </div>
  );
};

export default PricingPolicy;
