import React from "react";
import Styles from "./index.module.scss";

const AstroButton = (props) => {
  return (
    <div onClick={props.onClick} className={Styles.buttonArea}>
      Book Now
    </div>
  );
};

export default AstroButton;
