import React from "react";
import clock from "../../assets/clock.svg";
import Button from "../../widgets/Button";
import style from "./index.module.scss";

const TimeSlot = (props) => {
  return (
    <div className={style.timeSlot}>
      <div className={style.timeSlot__child}>
        <p>From</p>
        <input type="time" placeholder="From" value={props.value1} onChange={props.startTime} />
        <img src={clock} alt="clock" />
      </div>
      <div className={style.timeSlot__child}>
        <p>To</p>
        <input type="time" placeholder="To" value={props.value2} onChange={props.endTime} />
        <img src={clock} alt="clock" />
      </div>
      <div className={style.timeSlot__child}>
        <Button
          disabled={props.value1 === "" || props.value2 === ""}
          onClick={props.onClick}
          textTransform="Capitalize"
          fontSize="20px"
          marginTop="0"
          btnName="add"
          marginBottom="0"
        />
      </div>
    </div>
  );
};

export default TimeSlot;
