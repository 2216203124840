import React from "react";
import style from "./index.module.scss";
import noDataimg from "../../assets/noData.png";
const HistorySection = () => {
  return (
    <div className={style.historyArea}>
      <h1>History</h1>
      <div className={style.historyArea__imageArea}>
        <img src={noDataimg} alt="err" />
      </div>
    </div>
  );
};

export default HistorySection;
