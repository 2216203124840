import React, { useContext, useState } from "react";
import ModalLayout from "../../components/ModalLayout";
import ganesh from "../../assets/banside.webp";
import style from "./index.module.scss";
import InputFieldWithIcon from "../../widgets/InputFieldWithIcon";
import lock from "../../assets/lock.png";
import view from "../../assets/newassets/openEye.png";
import closeEye from "../../assets/newassets/closeEye.png";
import email from "../../assets/email.png";
import Button from "../../widgets/Button";
import { FaEnvelope } from "react-icons/fa";
import banner from "../../assets/banner.webp";
import axios from "axios";
import Loading from "../../components/Loading";
import nameIcon from "../../assets/nameIcon.jpg";
import OTPInput, { ResendOTP } from "otp-input-react";
import PhoneNumber from "../../components/PhoneInput";
import swal from "sweetalert";
import { Context } from "../../utils/ContextStor";

const Login = (props) => {
  // eslint-disable-next-line
  const [token, setToken, updatedUser, setUpdatedUser, isLog, setIsLog] = useContext(Context);
  const [phone, setPhone] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showSubmitOtp, setShowSubmitOtp] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [type, setType] = useState("password");
  const [eye, setEye] = useState(closeEye);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [otpData, setOtpData] = useState([]);
  const [isLoginForm, setIsLoginForm] = useState({
    email: "",
    password: "",
  });
  const [otpLoginDetails, setOtpLoginDetails] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [OTP, setOTP] = useState("");

  const loginDetails = (e) => {
    let fieldName = e.target.id;
    let fieldValue = e.target.value;

    let newData = { ...isLoginForm };

    newData[fieldName] = fieldValue;
    setIsLoginForm(newData);

  };

  const onLoginHandler = async () => {
    const url = "https://admin.adhigyanam.com/api/login";

    const formdata = new FormData();

    formdata.append("email", isLoginForm.email);
    formdata.append("password", isLoginForm.password);

    const getConfig = {
      url: url,
      method: "post",
      "Content-Type": "application/json",
      data: formdata,
    };

    setLoading(true);

    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          swal("Successful!", "Login Successfully!", "success");

          props.onLoginHandler(res.data.data);
          setIsLog(!isLog);
          setLoading(false);
        } else {
          swal(res.data.message, res.data.message, "error");
          setLoading(false);
          if (res.data.data === 1) {
            setIsLoginForm({
              email: isLoginForm.email,
              password: "",
            })
          } else {
            setIsLoginForm({
              email: "",
              password: "",
            })
          }
        }
      })
      .catch((err) => {
        swal("Failed!", "Login Failed!", "error");
        // console.log(err)
        setLoading(false);
      });
  };

  const onShowPasswordHandler = () => {
    if (type === "text") {
      setType("password");
      setEye(closeEye);
    } else {
      setType("text");
      setEye(view);
    }
  };
  // ********************** OTP Login ********************************
  const onLogingWithOtp = () => {
    setShowOtpField(true);
  };

  //  ********************** Send OTP  ********************************

  const onGetOtpHandler = () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("mobile_no", phone);
    const postOtp = {
      url: "https://admin.adhigyanam.com/api/mobile-otp",
      method: "POST",
      data: formData,
    };
    if (phone === "" || phone === undefined) {
      swal("Please enter Valid Number!", "Please enter Valid Number!", "error");
    } else {
      axios(postOtp)
        .then((res) => {
          setOtpData(res.data.data);
          // swal("Succesful!", "OTP Send Successfully!", "success");
          swal("Succesful!", res.data.message, "success");
          if (res.data.status === true) {
            setShowSubmitOtp(true);
          }
          setBtnLoading(false);
          // setShowSubmitOtp(true);
          setTimeout(() => {
            setDisable(false);
          }, 5 * 60000);
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  // **************** Submit OTP ************************
  const onSubmitOtp = () => {
    const formData = new FormData();
    formData.append("otp", OTP);
    formData.append("mobile_no", phone);

    const veryOtp = {
      url: "https://admin.adhigyanam.com/api/verify-otp",
      method: "POST",
      data: formData,
    };

    if (OTP.length <= 5) {
      swal("Please enter Valid Otp!", "Please enter Valid Otp!", "error");
    } else {
      axios(veryOtp)
        .then((res) => {
          if (res.data.status === false) {
            swal(res.data.message, "error");
            if (res.data.message === "No Data Found") {
              // setShowLogin(true);
              swal(res.data.message, res.data.message, "error");
            }
          } else {
            props.onLoginHandler(res.data.data);
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  // *************************** Submit User Data With OTP Login ************************

  const OnOtpLoginDetails = (e) => {
    let fieldName = e.target.id;
    let fieldValue = e.target.value;
    let newData = { ...otpLoginDetails };

    newData[fieldName] = fieldValue;
    setOtpLoginDetails(newData);
  };

  const onSubmitOtpLoginHandler = () => {
    // eslint-disable-next-line
    const specialChars = /[`1234567890!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const formData = new FormData();
    formData.append("name", otpLoginDetails.name);
    formData.append("contact_no", phone);
    formData.append("email", otpLoginDetails.email);
    formData.append("password", otpLoginDetails.password);

    if (otpLoginDetails.name === "") {
      swal("Please enter Name!", "Please enter Name!", "error");
      return;
    } else if (otpLoginDetails.name.length < 3) {
      swal("Name Length >= 3!", "Name Length >= 3!", "error");
      return;
    } else if (specialChars.test(otpLoginDetails.name)) {
      swal("Name should not contain special characters", "error");
      return;
    } else if (otpLoginDetails.email === "") {
      swal("Email is required!", "Email is required!", "error");

      return;
    } else if (!otpLoginDetails.email.includes("@") || !otpLoginDetails.email.includes(".")) {
      swal("Please enter Valid Email!", "Please enter Valid Email!", "error");

      return;
    } else if (otpLoginDetails.password.trim() === "") {
      swal("Password is requrid!", "Password is requrid!", "error");
      return;
    } else if (otpLoginDetails.password.length < 6) {
      swal("Password Length >= 6", "Password Length >= 6", "error");

      return;
    } else {
      const sendDetail = {
        url: "https://admin.adhigyanam.com/api/profile-store",
        method: "POST",
        data: formData,
      };
      axios(sendDetail)
        .then((res) => {
          if (res.data.status === true) {
            setShowLogin(false);
            setShowSubmitOtp(false);
            setShowOtpField(false);
          } else {
            swal("Something Went Wrong!", "Something Went Wrong!", "error");
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  };

  const handelResendClick = async () => {
    setDisable(true);
    const formData = new FormData();
    formData.append("mobile_no", phone);
    const postOtp = {
      url: "https://admin.adhigyanam.com/api/mobile-otp",
      method: "POST",
      data: formData,
    };
    if (phone === "" || phone === undefined) {
      swal("Please enter Valid Number!", "Please enter Valid Number!", "error");
    } else {
      await axios(postOtp)
        .then((res) => {
          setOtpData(res.data.data);
          swal("Success", "OTP Send Successfully!", "success");
        })
        .catch((err) => {
          // console.log(err)
        });
    }
    setTimeout(() => {
      setDisable(false);
    }, 5 * 60000);
  };
  const onPhoneHandler = (ph) => {
    setPhone(ph);
  };

  // **************************** Forgot Password *****************************
  const onForgotPasswordHandler = () => {
    window.location.replace("https://admin.adhigyanam.com/password-reset");
  };

  // **************************** Login via Gmail ****************************
  const onLoginViaGmailHandler = () => {
    // const getData = {
    //   url: "https://admin.adhigyanam.com/auth/redirect",
    //   method: "POST",
    // };
    // axios(getData)
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    //   .finally(() => {
    //     console.log("finally logged.");
    //   });

    window.location.replace("https://admin.adhigyanam.com/auth/redirect");
    setIsLog(!isLog);
  };

  return (
    <>
      <ModalLayout backgroundImage={banner} onCloseHandler={props.onCloseHandler}>
        <>
          {showLogin ? (
            <div className={style.login}>
              {loading ? (
                <div className={style.login__loder}>
                  <Loading width="100%" height="100px" />
                </div>
              ) : (
                <>
                  <div className={style.login__left}>
                    <h1>Adhigyanam</h1>
                    <p>
                      Welcome to Adhigyanam, your comprehensive platform for Consultation, E-Learning, Pooja Services, and Online Shopping.
                    </p>
                    <div className={style.login__left__ganesh}>
                      <img src={ganesh} alt="ganesh" />
                    </div>
                  </div>

                  <div className={style.login__right}>
                    <div className={style.login__right__centerTop}>
                      <div className={style.login__right__signup}>
                        <p>
                          Not a member?
                          {/* <span onClick={props.onSignupHandler}>Register for free</span> */}
                          &nbsp;&nbsp;
                          <Button
                            onClick={props.onSignupHandler}
                            btnName="Register for free"
                            marginTop="0"
                            marginBottom="0"
                            width="auto"
                            padding="5px"
                            height="auto"
                          />
                        </p>
                      </div>
                      {/* <div className={style.login__right__centerTop__or}>
                              <p className={style.login__right__centerTop__or__text}>or</p>
                            </div>
                            <div className={style.login__right__centerTop__social}>
                              <p>Already A Member Please Login.</p>
                            </div> */}
                    </div>
                    <div className={style.login__right__loginAs}>
                      <div className={style.login__right__loginAs__text}>
                        <p>Please Fill Details </p>
                      </div>
                    </div>

                    <InputFieldWithIcon
                      display2="none"
                      src1={nameIcon}
                      placeholder="Name"
                      id="name"
                      alt="name"
                      type="text"
                      label="Name"
                      onChange={OnOtpLoginDetails}
                    />
                    <InputFieldWithIcon
                      src1={email}
                      display2="none"
                      placeholder="Email"
                      id="email"
                      alt="email"
                      type="text"
                      label="Email"
                      onChange={OnOtpLoginDetails}
                    />
                    <InputFieldWithIcon
                      src1={lock}
                      src2={eye}
                      placeholder="Password"
                      id="password"
                      alt="password"
                      type={type}
                      label="Password"
                      onChange={OnOtpLoginDetails}
                      onIncrement={onShowPasswordHandler}
                      guide="Password not less than 6 digits"
                    />
                    <div className={style.login__right__forgot}>
                      <div className={style.login__right__forgot__keep}>
                        <input type="checkbox" />
                        <p>Keep me sign in on these device</p>
                      </div>
                      <p>Forgot password?</p>
                    </div>
                    <Button onClick={onSubmitOtpLoginHandler} btnName="Submit" />

                    <div className={style.login__right__bottom}>
                      {" "}
                      {/* <div className={style.login__right__signup}>
                        <p>
                          Not a member? <span onClick={props.onSignupHandler}>Create account free</span>
                        </p>
                      </div> */}
                      <div style={{ margin: "auto" }} className={style.login__right__bottom__social}>
                        <p>Login via Gmail</p>
                        <ul>
                          <li style={{ background: "#f44336" }}>
                            <FaEnvelope onClick={onLoginViaGmailHandler} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={style.login}>
              {loading ? (
                <div className={style.login__loder}>
                  <Loading width="100%" height="100px" />
                </div>
              ) : (
                <>
                  <div className={style.login__left}>
                    <h1>Adhigyanam</h1>
                    <p>
                      Welcome to Adhigyanam, your comprehensive platform for Consultation, E-Learning, Pooja Services, and Online Shopping.
                    </p>
                    <div className={style.login__left__ganesh}>
                      <img src={ganesh} alt="ganesh" />
                    </div>
                  </div>
                  {showOtpField ? (
                    <>
                      {showSubmitOtp ? (
                        <div className={style.login__right}>
                          <div className={style.login__right__centerTop}>
                            <div className={style.login__right__signup}>
                              <p>
                                Not a member?
                                {/* <span onClick={props.onSignupHandler}>Register for free</span> */}
                                &nbsp;&nbsp;
                                <Button
                                  onClick={props.onSignupHandler}
                                  btnName="Register for free"
                                  marginTop="0"
                                  marginBottom="0"
                                  width="auto"
                                  padding="5px"
                                  height="auto"
                                />
                              </p>
                            </div>
                            <div className={style.login__right__centerTop__or}>
                              <p className={style.login__right__centerTop__or__text}>or</p>
                            </div>
                            <div className={style.login__right__centerTop__social}>
                              <p>Already A Member Please Login.</p>
                            </div>
                          </div>
                          <div className={style.login__right__loginAs}>
                            <div style={{ width: "100%" }} className={style.login__right__loginAs__text}>
                              <p>Login With OTP </p>
                            </div>
                          </div>
                          <OTPInput
                            className={style.otpArea}
                            inputStyles={{
                              width: "45px",
                              height: "45px",
                              border: "2px solid #1eb2a6",
                              outlineColor: " #07c335",
                              marginRight: "10px",
                              fontSize: "20px",
                            }}
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                          />
                          <ResendOTP
                            className={disable ? style.disabled : style.resend}
                            style={{ marginTop: "30px", padding: "0 10px" }}
                            maxTime={300}
                            handelResendClick={handelResendClick}
                            onResendClick={handelResendClick}
                          />

                          <Button onClick={onSubmitOtp} btnName="Verify" />
                          <div className={style.login__right__bottom}>
                            {/* <div className={style.login__right__signup}>
                              <p>
                                Not a member? <span onClick={props.onSignupHandler}>Create account free</span>
                              </p>
                            </div> */}
                            <div style={{ margin: "auto" }} className={style.login__right__bottom__social}>
                              <p>Login via Gmail</p>
                              <ul>
                                <li style={{ background: "#f44336" }}>
                                  <FaEnvelope onClick={onLoginViaGmailHandler} />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={style.login__right}>
                          <div className={style.login__right__centerTop}>
                            <div className={style.login__right__signup}>
                              <p>
                                Not a member ?{/* <span onClick={props.onSignupHandler}>Register for free</span> */}
                                &nbsp;&nbsp;
                                <Button
                                  onClick={props.onSignupHandler}
                                  btnName="Register for free"
                                  marginTop="0"
                                  marginBottom="0"
                                  width="auto"
                                  padding="5px"
                                  height="auto"
                                />
                              </p>
                            </div>
                            <div className={style.login__right__centerTop__or}>
                              <p className={style.login__right__centerTop__or__text}>or</p>
                            </div>
                            <div className={style.login__right__centerTop__social}>
                              <p>Already A Member Please Login.</p>
                            </div>
                          </div>
                          <div className={style.login__right__loginAs}>
                            <div style={{ width: "100%" }} className={style.login__right__loginAs__text}>
                              <p>Login With OTP </p>
                            </div>
                          </div>

                          <div className={style.login__right__child}>
                            <p>Enter Phone</p>
                            <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneHandler} />
                          </div>
                          <Button onClick={onGetOtpHandler} btnName={btnLoading ? "Sending.." : "Get OTP"} />
                          <div className={style.login__right__bottom}>
                            {/* <div className={style.login__right__signup}>
                              <p>
                                Not a member? <span onClick={props.onSignupHandler}>Create account free</span>
                              </p>
                            </div> */}
                            <div style={{ margin: "auto" }} className={style.login__right__bottom__social}>
                              <p>Login via Gmail</p>
                              <ul>
                                <li style={{ background: "#f44336" }}>
                                  <FaEnvelope onClick={onLoginViaGmailHandler} />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className={style.login__right}>
                      <div className={style.login__right__centerTop}>
                        <div className={style.login__right__signup}>
                          <p>
                            Not a member ?{/* <span onClick={props.onSignupHandler}>Register for free</span> */}
                            &nbsp;&nbsp;
                            <Button
                              onClick={props.onSignupHandler}
                              btnName="Register for free"
                              marginTop="0"
                              marginBottom="0"
                              width="auto"
                              padding="5px"
                              height="auto"
                            />
                          </p>
                        </div>
                        <div className={style.login__right__centerTop__or}>
                          <p className={style.login__right__centerTop__or__text}>or</p>
                        </div>
                        <div className={style.login__right__centerTop__social}>
                          <p>Already A Member Please Login.</p>
                        </div>
                      </div>
                      <div className={style.login__right__loginAs}>
                        <div className={style.login__right__loginAs__text}>
                          <p>Login </p>
                        </div>
                      </div>
                      <InputFieldWithIcon
                        display2="none"
                        src1={email}
                        placeholder="Email"
                        id="email"
                        alt="name"
                        type="text"
                        label="Email"
                        onChange={loginDetails}
                        value={isLoginForm.email}
                      />
                      <InputFieldWithIcon
                        src1={lock}
                        src2={eye}
                        placeholder="Password"
                        id="password"
                        alt="password"
                        type={type}
                        label="Password"
                        onChange={loginDetails}
                        onIncrement={onShowPasswordHandler}
                        value={isLoginForm.password}
                      />
                      <div className={style.login__right__forgot}>
                        <div className={style.login__right__forgot__keep}>
                          <input type="checkbox" />
                          <p>Keep me sign in on these device</p>
                        </div>
                        <p onClick={onForgotPasswordHandler}>Forgot password?</p>
                      </div>
                      <Button onClick={onLoginHandler} btnName="Login" />
                      <div className={style.login__right__bottom}>
                        <button className={style.login__right__bottom__btn} onClick={onLogingWithOtp}>
                          Use OTP To Login
                        </button>
                        <div className={style.login__right__bottom__or}>
                          <p className={style.login__right__bottom__or__text}>or</p>
                        </div>
                        <div className={style.login__right__bottom__social}>
                          <p>Login via Gmail</p>
                          <ul>
                            <li style={{ background: "#f44336" }}>
                              <FaEnvelope onClick={onLoginViaGmailHandler} />
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <div className={style.login__right__signup}>
                        <p>
                          Not a member? <span onClick={props.onSignupHandler}>Create account free</span>
                        </p>
                      </div> */}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      </ModalLayout>
    </>
  );
};

export default Login;
