import React from "react";
import Styles from "./index.module.scss";
const KundaliCategory = ({ label, message, value, onChange, plan, name, onClick, select }) => {
  return (
    <div className={Styles.catagoryArea} onClick={onClick}>
      <div className={Styles.catagoryArea__radioButton}>
        <label htmlFor={name}>{label}</label>
        <input onChange={(e) => onChange(e, plan)} value={value} id={plan} name={name} type="radio" checked={select} />
      </div>
      <p>{message}</p>
    </div>
  );
};

export default KundaliCategory;
