import { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from "..";
import styles from "./index.module.scss";

const ReactCropper = ({ showModal, onModalClose, imgURL, setCroppedImgUrl, onSaveHandler, setCroppedImg }) => {
  const cropperRef = useRef(null);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImgUrl(cropper.getCroppedCanvas().toDataURL());

    cropper.getCroppedCanvas().toBlob((blob) => {
      setCroppedImg(blob);
    });
  };

  return (
    <Modal showModal={showModal} onSaveHandler={onSaveHandler} onModalClose={onModalClose}>
      <Cropper
        src={imgURL}
        style={{ height: "100%", width: "100%" }}
        initialAspectRatio={1 / 1}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
        viewMode={1}
        className={styles.Cropper}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        responsive={true}
        autoCropArea={1}
        aspectRatio={1 / 1}
        checkOrientation={false}
      />
    </Modal>
  );
};
export default ReactCropper;
