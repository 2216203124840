import React, { useEffect } from "react";
import Styles from "./index.module.scss";
import AstrologerCard from "../../components/AstroCard";
import Search from "../../widgets/Search";
import CommonTitle from "../../components/CommonTitle";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ChoosePredictionType from "../../modal/ChoosePredictionType";
import { useState } from "react";
import axios from "axios";
import Loading from "../../components/Loading";
import { ToastContainer } from "react-toastify";
import AstroBlogModal from "../../components/AstroBlogModal";
import Modal from "../../modal";
import noDataImg from "../../assets/noData.png";

const astroDetail = {
  astrologerID: "",
  serviceTypeID: "",
  servicId: "",
  serviceType: "",
};

const AstrologerList = () => {
  const [predictionModal, setPredictionModal] = useState(false);
  // const [predictionType, setPredictionType] = useState("");
  const [astrologerList, setAstrologerList] = useState([]);
  const [astroList, setAstroList] = useState([]);
  const [loading, setLoading] = useState(true);
  const param = useParams();
  const navigate = useNavigate();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [astrDetail, setAstrDetail] = useState([]);
  let pathID = param.id;
  let pathName = param.name;
  // let location = useLocation();
  localStorage.setItem("predictionId", JSON.stringify(pathID));
  // ***********Get Predictions ********************

  useEffect(() => {
    astroDetail.servicId = parseInt(pathID);
    // setPredictionType(location?.state?.predictionName);

    const formdata = new FormData();
    formdata.append("id", param.id);

    const getConfig = {
      url: "https://admin.adhigyanam.com/api/get-astrologers",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: formdata,
    };

    axios(getConfig)
      .then((res) => {
        if (res.data.status) {
          setAstrologerList(res.data.data);
          setAstroList(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err)
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [param.id, pathID, param]);

  const openDynamicRoutingHandler = (astroId) => {
    astroDetail.astrologerID = astroId;
    if (pathID === "1") {
      setPredictionModal(true);
    } else if (pathID === "2") {
      const path = generatePath(`/astrology/${pathID}/${pathName}/tarot-card-reading-form`);
      navigate(path, { state: { tittleName: pathName } });
    } else if (pathID === "3") {
      const path = generatePath(`/astrology/${pathID}/${pathName}/palmistry-details`);
      navigate(path, { state: { tittleName: pathName } });
    } else if (pathID === "4") {
      const path = generatePath(`/astrology/${pathID}/${pathName}/numerology-form`);
      navigate(path, { state: { tittleName: pathName } });
    } else if (pathID === "5") {
      const path = generatePath(`/astrology/${pathID}/${pathName}/vastu-analysis-form`);
      navigate(path, { state: { tittleName: pathName } });
    } else if (pathID === "6") {
      const path = generatePath(`/astrology/${pathID}/${pathName}/face-reading-form`);
      navigate(path, { state: { tittleName: pathName } });
    } else if (pathID === "7") {
      const path = generatePath(`/astrology/${pathID}/${pathName}/graphology-details`);
      navigate(path, { state: { tittleName: pathName } });
    } else {
      const path = generatePath(`/astrology/${pathID}/${pathName}/signature-analysis-form`);
      navigate(path, { state: { tittleName: pathName } });
    }
    localStorage.setItem("astroDet", JSON.stringify(astroDetail));
  };

  const hideThePredictionTypeModal = () => {
    setPredictionModal(false);
  };

  const onPageOpenHandler = (pageName, id) => {
    if (id === 5) {
      astroDetail.serviceType = id;
      const path = generatePath(`/astrology/${pathID}/${pathName}/${pageName}/type${id}`);
      navigate(path);
    } else {
      astroDetail.serviceType = id;
      const path = generatePath(`/astrology/${pathID}/${pathName}/${pageName}/type${id}`);
      navigate(path);
    }
    astroDetail.serviceTypeID = pageName;
    localStorage.setItem("astroDet", JSON.stringify(astroDetail));
  };

  const onSearch = (event) => {
    const val = event.target.value;
    val.toLowerCase();

    const filteredData = astroList.filter((item) => item.name.toLowerCase().startsWith(val));
    setAstrologerList(filteredData);
  };

  const onDetailHandler = (item) => {
    setAstrDetail(item);
    setModalIsVisible(true);
  };
  const onCloseMoadal = () => {
    setModalIsVisible(false);
  };
  return (
    <>
      {modalIsVisible && (
        <Modal overflowY="hidden" height="auto" onClose={onCloseMoadal}>
          <AstroBlogModal onClick={onCloseMoadal} detail={astrDetail} />
        </Modal>
      )}
      {predictionModal && <ChoosePredictionType onPageOpenHandler={onPageOpenHandler} onClose={hideThePredictionTypeModal} />}
      <div className={Styles.astroListArea}>
        <div className={Styles.astroListArea__tittleArea}>
          <div className={Styles.astroListArea__tittleArea__tittle}>
            <CommonTitle tittle={pathName} />
          </div>
          <div className={Styles.astroListArea__tittleArea__searchArea}>
            <Search onKeyUp={onSearch} />
          </div>
        </div>
        {loading ? (
          <div className={Styles.astroListArea__loader}>
            <Loading />
          </div>
        ) : (
          <>
            {astrologerList.length !== 0 ? (
              <div className={Styles.astroListArea__astrologerList}>
                {astrologerList?.map((item, index) => {
                  return (
                    <AstrologerCard
                      onDetailHandler={() => onDetailHandler(item)}
                      key={index}
                      ratting={item.ratting}
                      img={item.profile_pic}
                      name={item.name}
                      exprience={item.experience}
                      detail={item.speacility}
                      onClick={() => openDynamicRoutingHandler(item.id)}
                    />
                  );
                })}
              </div>
            ) : (
              <div className={Styles.emptyMsg}>
                <img src={noDataImg} alt="err" />
              </div>
            )}
          </>
        )}
      </div>
      <ToastContainer style={{ height: "200px" }} />
    </>
  );
};

export default AstrologerList;
