// eslint-disable-next-line
import React, { useContext, useEffect } from "react";
import styles from "./index.module.scss";
import { useState } from "react";
import DragAndDrop from "../DragAndDrop";
import axios from "axios";
import swal from "sweetalert";
import Details from "./Details";
import EditDet from "./EditDet";
import { Context } from "../../utils/ContextStor";
import "cropperjs/dist/cropper.css";
import DatePicker from "react-datepicker";
import PhoneNumber from "../PhoneInput";
import ReactCropper from "../../widgets/imgModal/cropper";
import { useNavigate } from "react-router-dom";
import dummyImg from "../../assets/dummyImg.jpg"

const UserProfile = ({ onClick, onOpenDashBord, onLogOut, show }) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [token, setToken, updatedUser, setUpdatedUser] = useContext(Context);
  const [phone, setPhone] = useState(updatedUser ? updatedUser.contact_no : "");
  const [onShowCroper, setOnShowCroper] = useState(false);
  const [croppedImg, setCroppedImg] = useState("");
  const [croppedImgUrl, setCroppedImgUrl] = useState("");
  const [startDate, setStartDate] = useState(new Date(updatedUser?.date_of_birth));
  const [selectedDate, setSelectedDate] = useState();
  const [document, setDocument] = useState(updatedUser?.profile_ic ? updatedUser?.profile_ic : dummyImg);
  const [showDoc, setShowDoc] = useState(true);
  const [imgae, setImage] = useState(updatedUser?.profile_ic ? updatedUser?.profile_ic : dummyImg);
  const [showForm, setShowForm] = useState(false);
  const [userData, setUserData] = useState({
    name: updatedUser?.name,
    speacility: updatedUser?.speacility,
    gender: updatedUser?.gender,
    time_of_birth: updatedUser?.time_of_birth ? updatedUser?.time_of_birth : "",
    place_of_birth: updatedUser?.place_of_birth,
    language: updatedUser?.language,
    expereience: updatedUser?.experience,
    externalLink: updatedUser?.external_link,
  });
  const [gLogin, setGLogin] = useState("none");
  const navigate = useNavigate();
  // eslint-disable-next-line

  // ****************Check Googl Login****************

  useEffect(() => {
    if (localStorage.getItem("jsonData")) {
      setGLogin("google");
    } else {
      setGLogin("none");
    }
  }, []);

  // **********************************************************************
  const filesHandlerOne = (e) => {
    let path = e.target.files[0];
    setImage(path);
    let image = URL.createObjectURL(path);
    if (image) {
      setDocument(image);
      setShowDoc(true);
      setOnShowCroper(true);
    }
  };
  const showFormHandler = () => {
    setShowForm(true);
  };
  const onBackHandler = () => {
    setShowForm(false);
    setShowDoc(true);
  };
  // *********************Form data******************************/

  const onChangeHandler = (e, id) => {
    setUserData({
      ...userData,
      [id]: e.target.value,
    });
  };

  // ************************* Post Data *******************************

  function isValidUrl(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const onSaveChangeHandler = () => {
    setLoading(true);
    if (userData.gender === "Select" || userData.gender === null) {
      swal("Failed!", "Please select a gender", "error");
      setLoading(false);
      return;
    } else if (userData.language === "Select" || userData.language === null) {
      swal("Failed!", "Please select a language", "error");
      setLoading(false);
      return;
    } else if (userData.speacility === 0 && userData.speacility === null && toString(userData.speacility).length > 2) {
      swal("Failed!", "Please select a Service", "error");
      setLoading(false);
      return;
    } else if (!user.user_type === "User" && !isValidUrl(userData.externalLink)) {
      swal("Failed!", "Please Enter A Valid URL", "error");
      setLoading(false);
      return;
    } else {
      const formData = new FormData();
      formData.append("profile_pic", imgae);
      formData.append("name", userData.name);
      formData.append("gender", userData.gender);
      formData.append("speacility", userData.speacility);
      formData.append("date_of_birth", selectedDate);
      formData.append("time_of_birth", userData.time_of_birth);
      formData.append("place_of_birth", userData.place_of_birth);
      formData.append("contact_no", phone);
      formData.append("language", userData.language);
      formData.append("expereience", userData.expereience);
      formData.append("external_link", userData.externalLink);
      // eslint-disable-next-line
      {
        gLogin === "google" && formData.append("login_type", gLogin);
      }

      const sendData = {
        url: `https://admin.adhigyanam.com/api/register-update/${user?.id}`,
        method: "POST",
        data: formData,
      };

      axios(sendData)
        .then((res) => {
          if (res.data.status === true) {
            swal("Update Successfully", "Update Successfully", "success");

            const filteredData = {
              name: res.data.data[0].name,
              email: res.data.data[0].email,
              profile_ic: res.data.data[0].profile_pic,
              user_type: res.data.data[0].user_type,
              speacility: res.data.data[0].speacility,
              experience: res.data.data[0].experience,
              id: res.data.data[0].id,
              date_of_birth: res.data.data[0].date_of_birth,
              place_of_birth: res.data.data[0].place_of_birth,
              time_of_birth: res.data.data[0].time_of_birth,
              gender: res.data.data[0].gender,
              contact_no: res.data.data[0].contact_no,
              language: res.data.data[0].language,
              external_link: res.data.data[0].external_link,
            };
            setUpdatedUser(filteredData);
            setShowForm(false);
            setLoading(false);
            localStorage.removeItem("jsonData");

            // const getUser = {
            //   url: `https://admin.adhigyanam.com/api/profile-get/${user?.id}`,
            //   method: "GET",
            //   headers: {
            //     Authorization: `Bearer ${user?.token}`,
            //   },
            // };
            // axios(getUser)
            //   .then((res) => {
            //     const filteredData = {
            //       name: res.data.data[0].name,
            //       email: res.data.data[0].email,
            //       profile_ic: res.data.data[0].profile_pic,
            //       user_type: res.data.data[0].user_type,
            //       speacility: res.data.data[0].speacility,
            //       experience: res.data.data[0].experience,
            //       id: res.data.data[0].id,
            //       date_of_birth: res.data.data[0].date_of_birth,
            //       place_of_birth: res.data.data[0].place_of_birth,
            //       time_of_birth: res.data.data[0].time_of_birth,
            //       gender: res.data.data[0].gender,
            //       contact_no: res.data.data[0].contact_no,
            //       language: res.data.data[0].language,
            //       external_link: res.data.data[0].external_link,
            //     };
            //     setUpdatedUser(filteredData);
            //     setShowForm(false);
            //     // localStorage.removeItem("jsonData");
            //   })
            //   .catch((err) => {
            //     console.log(err)
            //     setShowForm(false);
            //   });
          } else {
            swal("Failed!", "Somthing Went Wrong!", "error");
            setLoading(false);
          }
        })
        .catch((err) => {
          // console.log(err)
          setLoading(false);
        });
    }
  };

  // ********************************************************
  const onSelectDateHandler = (date) => {
    setStartDate(date);

    let userDob = new Date(date);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
  };
  useEffect(() => {
    let userDob = new Date(startDate);
    let year = userDob.getFullYear();
    let month = userDob.getMonth() + 1;
    let day = userDob.getDate();
    let smonth = month < 10 ? "0" + month : month;
    let sday = day < 10 ? "0" + day : day;
    setSelectedDate(`${year}-${smonth}-${sday}`);
    // eslint-disable-next-line
  }, []);

  const onDateOfBirthHandler = (date) => {
    setSelectedDate(date);
  };
  const onPhoneHandler = (phone) => {
    setPhone(phone);
  };

  const onPhoneChangeHandler = (ph) => {
    setPhone(ph);
  };

  let d = new Date(updatedUser?.date_of_birth).getDate();
  let m = new Date(updatedUser?.date_of_birth).getMonth() + 1;
  let y = new Date(updatedUser?.date_of_birth).getFullYear();
  let dob = d + "-" + m + "-" + y;

  const onSaveHandler = () => {
    setImage(croppedImg);
    setDocument(croppedImgUrl);
    setOnShowCroper(false);
  };

  const onModalClose = () => {
    setOnShowCroper(false);
  };

  const onMyCoursesHandler = () => {
    navigate("/my-courses");
    show(false);
  };

  return (
    <div>
      <>
        {onShowCroper && (
          <ReactCropper
            imgURL={document}
            setCroppedImg={setCroppedImg}
            onSaveHandler={onSaveHandler}
            onModalClose={onModalClose}
            setCroppedImgUrl={setCroppedImgUrl}
          />
        )}
        {user?.user_type === "Astrologer" ? (
          <>
            {!showForm ? (
              <Details user={updatedUser} onClick={onClick} showFormHandler={showFormHandler} onLogOut={onLogOut} />
            ) : (
              <EditDet
                onSaveChangeHandler={onSaveChangeHandler}
                onBackHandler={onBackHandler}
                onChangeHandler={onChangeHandler}
                userData={userData}
                showDoc={showDoc}
                filesHandlerOne={filesHandlerOne}
                profile={document}
                onDateOfBirthHandler={onDateOfBirthHandler}
                onPhoneHandler={onPhoneHandler}
                initPhone={updatedUser?.contact_no}
                userDate={startDate}
                loading={loading}
              />
            )}
          </>
        ) : (
          <>
            {!showForm ? (
              <div className={styles.profileArea}>
                <div className={styles.profileArea__bg}></div>
                <span className={styles.profileArea__close} onClick={onClick}>
                  <i className="fa-solid fa-arrow-left"></i>
                </span>
                <div className={styles.profileArea__imgArea}>
                  <div className={styles.profileArea__imgArea__emailrea}>
                    <h2> {updatedUser?.name}</h2>
                    <p className={styles.profileArea__imgArea__emailrea__email}>
                      <i className="fa-solid fa-envelope"></i> {user?.email}
                    </p>
                  </div>

                  <span className={styles.profileArea__imgArea__profilePic}>
                    <img src={updatedUser?.profile_ic ? updatedUser?.profile_ic : dummyImg} alt="profle" />
                    <span className={styles.profileArea__imgArea__profilePic__edit} onClick={showFormHandler}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                  </span>
                </div>

                <div className={styles.profileArea__detail}>
                  <p className={styles.profileArea__detail__experience}>
                    <span>
                      <i className="fa-solid fa-calendar-days"></i> Date of Birth :
                    </span>
                    {dob}
                  </p>
                  <p className={styles.profileArea__detail__experience}>
                    <span>
                      {" "}
                      <i className="fa-solid fa-clock"></i> Time of Birth :
                    </span>{" "}
                    {updatedUser?.time_of_birth === undefined ? "" : updatedUser?.time_of_birth}
                  </p>
                  <p className={styles.profileArea__detail__experience}>
                    <span>
                      {" "}
                      <i className="fa-solid fa-location-dot"></i> Place of Birth :
                    </span>{" "}
                    {updatedUser?.place_of_birth === undefined ? "" : updatedUser?.place_of_birth}
                  </p>
                  <p className={styles.profileArea__detail__experience}>
                    <span>
                      {" "}
                      <i className="fa-solid fa-phone"></i> Mobile No :
                    </span>{" "}
                    {updatedUser?.contact_no === undefined ? "" : updatedUser?.contact_no}
                  </p>
                  <p className={styles.profileArea__detail__experience}>
                    <span>
                      {" "}
                      <i className="fa-solid fa-user"></i> Gender :
                    </span>{" "}
                    {updatedUser?.gender === undefined ? "" : updatedUser?.gender}
                  </p>
                  <p className={styles.profileArea__detail__experience}>
                    <span>
                      {" "}
                      <i className="fa-solid fa-language"></i> Language :
                    </span>{" "}
                    {updatedUser?.language === undefined ? "" : updatedUser?.language}
                  </p>
                  <div className={styles.profileArea__detail__buttonArea}>
                    <button className={styles.profileArea__detail__buttonArea__courseBtn} onClick={onMyCoursesHandler}>
                      <span>
                        <i className="fa-solid fa-book"></i>
                      </span>{" "}
                      My Courses
                    </button>
                    <button className={styles.profileArea__detail__buttonArea__logout} onClick={onLogOut}>
                      <span>
                        <i className="fa-solid fa-right-from-bracket"></i>
                      </span>{" "}
                      LogOut
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.profileArea}>
                <h3 className={styles.profileArea__title}>Edit Profile</h3>
                <div className={styles.profileArea__etid}>
                  <span className={styles.profileArea__etid__back} onClick={onBackHandler}>
                    <i className="fa-solid fa-arrow-left"></i>
                  </span>

                  <span className={styles.profileArea__etid__profilePic}>
                    <span className={styles.profileArea__etid__camera}>
                      <i className="fa-solid fa-camera"></i>
                    </span>
                    <div>
                      <DragAndDrop height="120px" filesHandler={filesHandlerOne} image={document} showFile={showDoc} />
                    </div>
                  </span>
                  <label htmlFor="name">Name</label>
                  <input
                    className={styles.profileArea__etid__input}
                    type="text"
                    name="name"
                    id="name"
                    value={userData?.name}
                    onChange={(e) => onChangeHandler(e, "name")}
                  />
                  <label htmlFor="phone">Phone</label>

                  <PhoneNumber border="1px solid #03b2cb" value={phone} onChange={onPhoneChangeHandler} />

                  <div className={styles.profileArea__etid__selectArea}>
                    <div>
                      <label htmlFor="gender">Gender</label>
                      <select
                        className={styles.profileArea__etid__selectArea__select}
                        name="gender"
                        id="gender"
                        value={userData?.gender}
                        onChange={(e) => onChangeHandler(e, "gender")}>
                        <option value="Select">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="language">Language</label>
                      <select
                        className={styles.profileArea__etid__selectArea__select}
                        name="language"
                        id="language"
                        value={userData?.language}
                        onChange={(e) => onChangeHandler(e, "language")}>
                        <option value="Select">Select</option>
                        <option value="Hindi">Hindi</option>
                        <option value="English">English</option>
                      </select>
                    </div>
                  </div>
                  <label htmlFor="pob">Place Of Birth</label>
                  <input
                    className={styles.profileArea__etid__input}
                    type="text"
                    name="pob"
                    id="place_of_birth"
                    value={userData?.place_of_birth}
                    onChange={(e) => onChangeHandler(e, "place_of_birth")}
                  />
                  <label htmlFor="tob">Time Of Birth</label>
                  <input
                    className={styles.profileArea__etid__input}
                    type="time"
                    name="tob"
                    id="time_of_birth"
                    value={userData?.time_of_birth}
                    onChange={(e) => onChangeHandler(e, "time_of_birth")}
                  />
                  <label htmlFor="dob">Date Of Birth</label>

                  <DatePicker
                    maxDate={new Date()}
                    className={styles.datePicker}
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={onSelectDateHandler}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <button className={styles.profileArea__etid__btn} onClick={onSaveChangeHandler}>
                    {loading ? "Saving..." : "Save Change"}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default UserProfile;
