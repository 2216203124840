import React from "react";
import Styles from "./index.module.scss";
const CommanTitleWithLogo = ({ icon, tittle, width, height, width2 }) => {
  return (
    <div style={{ width: `${width}` }} className={Styles.tittle}>
      <img style={{ height: `${height}`, width: `${width2}` }} src={icon} alt="error" />
      <h3>{tittle}</h3>
    </div>
  );
};

export default CommanTitleWithLogo;
