import React from "react";
import calendar from "../../assets/calendar.png";
import Styles from "./index.module.scss";
const CommonInputField = ({ type, placeholder, value, onChange, display, margin, name, borderColor }) => {
  return (
    <div className={Styles.fieldArea} style={{ margin: `${margin}` }}>
      <span className={Styles.fieldArea__placeholder}>{placeholder}</span>
      <input
        style={{ borderColor: `${borderColor}` }}
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div style={{ display: `${display}` }} className={Styles.fieldArea__img}>
        <img src={calendar} alt="error" />
      </div>
    </div>
  );
};

export default CommonInputField;
