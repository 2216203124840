import React from "react";
import Styles from "./index.module.scss";
const PlanList = ({ planNo, plan, fontSize, marginBottom, height, selected, onClick, cursor, planWidth }) => {
  return (
    <div style={{ marginBottom: `${marginBottom}`, cursor: `${cursor}` }} className={Styles.planeArea} onClick={onClick}>
      <div style={{ fontSize: `${fontSize}`, width: `${planWidth}` }} className={Styles.planeArea__planNumber}>
        {planNo}
      </div>
      <div style={{ height: `${height}` }} className={selected ? `${Styles.planeArea__icon}` : `${Styles.planeArea__plan}`}>
        {plan}
      </div>
    </div>
  );
};

export default PlanList;
