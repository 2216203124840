import React from "react";
import style from "./index.module.scss";

const Banner = () => {
  return (
    <div className={style.banner}>
      <div className={style.banner__left}>
        <h2>WHAT OUR CLIENTS SAYS</h2>
        {/* <p>These Are Some Testimonials From Professionals Who Love Styleseat.</p> */}
        <p>
          These feedback are more than words; these are stories of Hope, journey of years of Hard Work for Client Satisfaction, and these
          are small steps that have led ADHIGYANAM, into the hearts of thousands of customers.
        </p>
      </div>
    </div>
  );
};

export default Banner;
