import React, { Suspense, useState } from "react";
import { useRef } from "react";
import Loading from "../../components/Loading";

const Testimonial = React.lazy(() => import("./Testimonial"));
const RaiseEnquiry = React.lazy(() => import("./RaiseEnquiry"));
const Horoscope = React.lazy(() => import("./Horoscope"));
const PredictionServices = React.lazy(() => import("./PredictionServices"));
const MainBanner = React.lazy(() => import("./MainBanner"));

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const preRef = useRef(null);
  const onScrollDown = () => {
    preRef.current?.scrollIntoView({ behavior: "smooth" });
    // const userDetails = JSON.parse(localStorage.getItem("user"));
  };

  return (
    <div style={{ position: "relative" }}>
      <Suspense fallback={<Loading />}>
        {loading && loading1 && loading2 && loading3 && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: 0,
              height: "100vh",
              width: "100%",
              background: "white",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Loading />
          </div>
        )}
        <MainBanner setLoading={setLoading} onClick={onScrollDown} />
        <PredictionServices setLoading={setLoading1} innerRef={preRef} />
        <Testimonial setLoading={setLoading2} />
        <Horoscope setLoading={setLoading3} />
        <RaiseEnquiry />
      </Suspense>
    </div>
  );
};

export default Home;
