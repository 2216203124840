import React from "react";
import Card from "./Card";

import style from "./index.module.scss";
import Banner from "./Banner";
import { useState } from "react";
import { useEffect } from "react";
import { endpoints } from "../../utils/Endpoints";
import axios from "axios";
import { useParams } from "react-router-dom";
import Modal from "../../modal";
import ModalLayout from "../../components/ModalLayout";
import Loading from "../../components/Loading";
import noDataFoundImg from "../../assets/noData.png";

const TestimonialPage = () => {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [tname, setTname] = useState("");
  const [testiData, setTestiData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  let display = "none";
  // ******************************* GET DATA **********************************
  const params = useParams();
  useEffect(() => {
    const listData = {
      url: endpoints.testimonial,
      method: "GET",
    };

    axios(listData)
      .then((res) => {
        setTestiData(res.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, [params.id]);

  const onReadMoreHandler = (msg, name) => {
    setDescription(msg);
    setTname(name);
    setShow(true);
  };
  const onCloseMoadal = () => {
    setShow(false);
  };
  return (
    <>
      {show && (
        <Modal height="auto" overflowY="auto" onClose={onCloseMoadal}>
          <ModalLayout onCloseHandler={onCloseMoadal}>
            <div className={style.description}>
              <h2>{tname}</h2>
              <p>{description}</p>
            </div>
          </ModalLayout>
        </Modal>
      )}

      <>
        <Banner />
        {showLoader ? (
          <Loading />
        ) : (
          <>
            {testiData.length <= 0 ? (
              <span className={style.emptyMsg}>
                <img src={noDataFoundImg} alt="err" />
              </span>
            ) : (
              <div className={style.container}>
                {testiData.map((item) => (
                  <Card
                    display={item.description.length < 180 ? display : ""}
                    height="320px"
                    key={item.id}
                    title={item.name.slice(0, 20) + ".."}
                    testimonial={item.description.slice(0, 180)}
                    designation={item.gender}
                    user={item.drname}
                    src={item.icon}
                    ratting={item.rating}
                    onClick={() =>
                      onReadMoreHandler(item.description, item.drname)
                    }
                  />
                ))}
              </div>
            )}
          </>
        )}
      </>
    </>
  );
};

export default TestimonialPage;
