import React from "react";
import Styles from "./index.module.scss";

const ConsultationList = ({ id, lable, onClick, checked }) => {
  return (
    <div className={Styles.itemArea}>
      <input type="checkbox" id={id} value={lable} onChange={(e) => onClick(id)} checked={checked} />
      <label htmlFor={id}>{lable}</label>
    </div>
  );
};

export default ConsultationList;
